import React, { useState, useEffect } from 'react';
import './LoginSignup.css'
import Header from '../../Header/Header2';
import Footer from '../../Footer/Footer';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { IoCameraSharp } from "react-icons/io5";
import { GrFormNextLink, GrFormPreviousLink } from "react-icons/gr";
import LOGO from '../../../../Assets/images/loyalista_logo(original).png';
import { Steps, message } from 'antd';
import login_bg from '../../../../Assets/images/background/login-bg.jpg';
import signup_up from '../../../../Assets/images/background/signup-bg.jpg'
import { regions, provinces, cities, barangays } from 'select-philippines-address';
import { auth, db, storage } from '../../../../firebase/config';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';
import { equalTo, get, orderByChild, query, ref, set } from 'firebase/database';
import { ref as sRef, uploadBytes } from 'firebase/storage';
import { useAuth } from '../../../../contexts/authContext';
import { compareAsc } from 'date-fns';

const { Step } = Steps;

const LoginSignup = () => {
  const { userLoggedIn } = useAuth()
  const [isLoading, setIsLoading] = useState(false);
  const [username, setUserName] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPass, setConfirmPass] = useState('');
  const [inputRegion, setInputRegion] = useState('');
  const [inputProvince, setInputProvince] = useState('');
  const [inputCity, setInputCity] = useState('');
  const [inputBrgy, setInputBrgy] = useState('');
  const [regionData, setRegionData] = useState([]);
  const [provinceData, setProvinceData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [barangayData, setBarangayData] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isIOSMember, setIsIOSMember] = useState(true);
  const [imageFile, setImageFile] = useState(null);
  const [isIdPictureUploaded, setIsIdPictureUploaded] = useState(false);
  const [showLogin, setShowLogin] = useState(true);
  const [showpass, setShowPass] = useState(false);
  const [fileName, setFileName] = useState(''); 
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate(); 
  const user = JSON.parse(localStorage.getItem('user'))
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  useEffect(() => {
    fetchRegions();
  }, []);
  const toggleSection = () => {
    setErrorMessage('')
    setShowLogin(prevState => !prevState);
  };
  const toggleShowPass = () => {
    setShowPass(prevState => !prevState);
  };

  const [signupForm, setsignupForm] = useState({
    firstname: '',
    middlename: '',
    lastname: '',
    contact: '',
    username: '',
    email: '',
    membership: '',
    accountID: '',
    region: '',
    province: '',
    city: '',
    referral:'',
    brgy: '',
    street: '',
    postal: '',
    landmark: '',
    status:'Pending'
  });

  const checkUserName = async (username) => {
    try {
      const snapshot = await get(query(ref(db, 'users'), orderByChild('username'), equalTo(username)));
      
      if (snapshot.exists()) {
        return true;
      } else {
        return false; 
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const checkEmail = async (email) => {
    try {
      const snapshot = await get(query(ref(db, 'users'), orderByChild('email'), equalTo(email)));
      
      if (snapshot.exists()) {
        return true;
      } else {
        return false; 
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  };
  const handleNext = async() => {
    signupForm.username = username
    signupForm.email = newEmail
    const usernameExist = await checkUserName(username);
    const EmailExist = await checkEmail(newEmail)

    if (currentStep === 0 ) {
      if (!signupForm.firstname || !signupForm.lastname || !signupForm.contact) {
        setErrorMessage('Please fill out all required fields');
        setShowError(true);
        return;
      }
      if (signupForm.contact.length !== 11) {
        setErrorMessage('Phone number must have 11 digits');
        setShowError(true);
        return;
      }
    }

    if (currentStep === 1 ) {
      if (!username || !newEmail || !password || !confirmPass) {
        setErrorMessage('Please fill out all required fields');
        setShowError(true);
        return;
      }
      if (password !== confirmPass) {
        setErrorMessage('Password and confirm password not matched!');
        setShowError(true);
        return;
      }
      if (usernameExist) {
        setErrorMessage("Username is already used. Please choose another one!");
        setShowError(true);
        return;
      }
      if (EmailExist) {
        setErrorMessage(`email address ${newEmail} already in use.`);
        setShowError(true);
        return;
      }
    }
    if (currentStep === 2 ) {
      if (isIOSMember && !signupForm.accountID) {
        setErrorMessage('Please fill out all required fields');
        setShowError(true);
        return;
      }
      if (!isIdPictureUploaded) {
        setErrorMessage("Please upload your Government ID picture before proceeding to the next step.");
        setShowError(true);
        return;
      }
    }
    if (currentStep === 3 ) {
      if (!inputRegion || !inputProvince || !inputCity || !inputBrgy) {
        setErrorMessage('Please fill out all required fields');
        setShowError(true);
        return;
      }
    }
    setErrorMessage('');
    setCurrentStep(currentStep + 1);
  };

  const handlePrev = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImageFile(file)
      const fileName = file.name;
      setFileName(fileName); 
      setIsIdPictureUploaded(true);
      setErrorMessage(false);
    }
  };
  const truncateString = (str, maxLength) => {
    if (str.length > maxLength) {
      return str.substring(0, maxLength) + '...';
    }
    return str;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(signupForm)
    setIsLoading(true);
  
    // Additional validation for street and postal fields
    if (currentStep === 4) {
      if (!signupForm.street || !signupForm.postal) {
        setErrorMessage('Please fill out all required fields');
        setShowError(true);
        setIsLoading(false);
        return;
      }
    }
    const usernameExist = await checkUserName(username);
    if (!usernameExist) {
    try {
        createUserWithEmailAndPassword(auth, newEmail, confirmPass).then(userCredential => {   
          const user = userCredential.user;
          signupForm.userID = user.uid;
          const userData = signupForm
          const creds = signupForm
          
          set(ref(db, `users/${user.uid}`), 
          signupForm
       ).then( async()=>{
        uploadImage(user.uid)
            const subject = `Your account has been created successfully`
            const email = user.email
            const response = await fetch('https://loyalista-mailer-aa5b8a82053e.herokuapp.com/registration', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ email, userData , subject }),
            });
            if (response.ok) {
              
              // console.log('Email sent successfully');
              const subject = `New Member has been registered.`
              const email = 'docvincesahagun@gmail.com'
              const bossresponse = await fetch('https://loyalista-mailer-aa5b8a82053e.herokuapp.com/newMember', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, creds , subject }),
              });
              if (bossresponse.ok) {
                console.log('Email sent successfully');
              } else {
                console.error('Failed to send email');
              }
            }
       })

           ('Successfully Registered');
        }).catch(error => {
          switch (error.code) {
             case 'auth/email-already-in-use':
              alert(`email address ${newEmail} already in use.`);
               break;
             case 'auth/invalid-email':
              alert(`email address ${newEmail} is invalid.`);
               break;
             case 'auth/operation-not-allowed':
              alert(`error during sign up.`);
               break;
             case 'auth/weak-password':
              alert('password is not strong enough. add additional characters including special characters and numbers.');
               break;
             default:
              console.log(error.message);
               break;
           }
       });
     } catch (error) {
        alert('Error occurred during registration');
     }
    // Simulate some asynchronous operation
    setTimeout(() => {
      setIsLoading(false);
      setTimeout(() => { 
        message.success("Account Created Successfully!");
        setsignupForm({
          firstname: '',
          middlename: '',
          lastname: '',
          contact: '',
          username: '',
          email: '',
          membership: '',
          accountID: '',
          region: '',
          province: '',
          city: '',
          brgy: '',
          street: '',
          postal: '',
          landmark: '',
          status:'Pending'
        });
        setNewEmail('')
        setPassword('')
        setConfirmPass('')
        setUserName('')
        setInputRegion('')
        setInputProvince('')
        setInputCity('')
        setInputBrgy('')
        setImageFile(null)
        setFileName('')
        toggleSection()
        setCurrentStep(0);
      }, 800);
    }, 2000);
}
  };

  const  uploadImage = (userid) =>{
    const storageRef = sRef(storage, `users/${userid}/id/${fileName}`);
    uploadBytes(storageRef, imageFile).then((snapshot) => {
      message.success('Uploaded a blob or file!');
    });
  }


  const fetchRegions = () => {
    regions()
      .then(response => {
        setRegionData(response);
        setsignupForm(prevData => ({ ...prevData, region: response[null]?.region_code || '' }));
      })
      .catch(error => console.error('Error fetching regions:', error));
  };
  const fetchProvinces = region_code => {
    provinces(region_code).then(response => {
      setProvinceData(response);
    });
  };
  const fetchCities = provinceCode => {
    cities(provinceCode).then(response => {
      setCityData(response);
    });
  };
  const fetchBarangays = cityCode => {
    barangays(cityCode).then(response => {
      setBarangayData(response);
    });
  };
  
  const onChangeRegion = (e) => {
    const selectedRegionCode = e.target.value;
    const selectedRegion = regionData.find((item) => item.region_code === selectedRegionCode);

    setInputRegion(selectedRegionCode);
    setInputProvince('');
    setInputCity('');
    setInputBrgy('');

    setsignupForm({
      ...signupForm,
      region: selectedRegion ? selectedRegion.region_name : '',
    });

    fetchProvinces(selectedRegionCode);
    setProvinceData([]);
    setCityData([]);
    setBarangayData([]);
  };

  const onChangeProvince = (e) => {
    const selectedProvinceCode = e.target.value;
    const selectedProvince = provinceData.find((item) => item.province_code === selectedProvinceCode);

    setInputProvince(selectedProvinceCode);
    setInputCity('');
    setInputBrgy('');

    setsignupForm({
      ...signupForm,
      province: selectedProvince ? selectedProvince.province_name : '',
    });

    fetchCities(selectedProvinceCode);
    setCityData([]); 
    setBarangayData([]);
  };

  const onChangeCity = (e) => {
    const selectedCityCode = e.target.value;
    const selectedCity = cityData.find((item) => item.city_code === selectedCityCode);

    setInputCity(selectedCityCode);
    setInputBrgy('');

    setsignupForm({
      ...signupForm,
      city: selectedCity ? selectedCity.city_name : '',
    });

    fetchBarangays(selectedCityCode);
    setBarangayData([]); 
  };

  const onChangeBarangay = (e) => {
    const selectedBarangayCode = e.target.value;
    const selectedBarangay = barangayData.find((item) => item.brgy_code === selectedBarangayCode);

    setInputBrgy(selectedBarangayCode);

    setsignupForm({
      ...signupForm,
      brgy: selectedBarangay ? selectedBarangay.brgy_name : '',
    });
  };

  const handleRememberMeChange = () => {
    setRememberMe(prevState => !prevState); 
  };

  const checkStatus = async (email) => {
    try {
      const snapshot = await get(query(ref(db, 'users'), orderByChild('email'), equalTo(email)));
      
      if (snapshot.exists()) {
        const userData = snapshot.val();
        const flattenedUserData = Object.entries(userData).reduce((acc, [key, value]) => {
          if (typeof value === 'object' && value !== null) {
            Object.entries(value).forEach(([nestedKey, nestedValue]) => {
              acc[`${nestedKey}`] = nestedValue;
            });
          } else {
            acc[key] = value;
          }
          return acc;
        }, {});
        return flattenedUserData.status;
      } else {
        setErrorMessage('Email/Password not Found');
        setShowError(true);
        return false;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  };
  const checkExpiration = async (email) => {
    try {
      const snapshot = await get(query(ref(db, 'users'), orderByChild('email'), equalTo(email)));
      
      if (snapshot.exists()) {
        const userData = snapshot.val();
        const flattenedUserData = Object.entries(userData).reduce((acc, [key, value]) => {
          if (typeof value === 'object' && value !== null) {
            Object.entries(value).forEach(([nestedKey, nestedValue]) => {
              acc[`${nestedKey}`] = nestedValue;
            });
          } else {
            acc[key] = value;
          }
          return acc;
        }, {});
        return flattenedUserData.subscription_Expiration;
      } else {
        setErrorMessage('Email/Password not Found');
        setShowError(true);
        return false;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const isSubscriptionExpired = (expirationDate) => {

    if (expirationDate) {
      try {
        const currentDateTime = new Date();
        const subscriptionExpirationDate = new Date(Date.parse(expirationDate));
  
        const comparisonResult = compareAsc(currentDateTime, subscriptionExpirationDate);
        return comparisonResult === 1; 
      } catch (error) {
        console.error('Error parsing date:', error);
        return false;
      }
    }
  
    return false;
  };
  const handleLogin = async(e) => {
    const enteredUsername = e.target.elements.email.value;
    const enteredPassword = e.target.elements.password.value;
    e.preventDefault();
    if (rememberMe) {
      localStorage.setItem('rememberedUser', JSON.stringify({ email: enteredUsername, password: enteredPassword }));
    } else {
      localStorage.removeItem('rememberedUser');
    }

    if(await checkStatus(enteredUsername) === "Approved"){
    try {
      const userCredential = await signInWithEmailAndPassword(auth, enteredUsername, enteredPassword);
      if (userCredential.user) {
          if (isSubscriptionExpired(await checkExpiration(enteredUsername))) {
            setErrorMessage('Your subscription has expired. Please renew to continue.');
            setShowError(true);
            return; 
          }
        localStorage.setItem('token', userCredential.user.accessToken);
        localStorage.setItem('user', JSON.stringify(userCredential.user));
        navigate('/MyPage');
      } 
    } catch (error) {
      let errorCode = error.code
      switch (errorCode) {
        case 'auth/too-many-requests':
         setErrorMessage(`Too many Attemps, Reset your Password.`);
          break;
        case 'auth/invalid-email':
          setErrorMessage(`Email address ${enteredUsername} is invalid. Please check and try again.`);
          break;
        case 'auth/user-not-found':
          setErrorMessage(`Invalid email. No account found for the provided email address. 
                Please check your email or sign up for a new account.`);
          break;
        case 'auth/wrong-password':
          setErrorMessage('Incorrect password. Please try again.');
          break;
        case 'auth/user-disabled':
          setErrorMessage('Account has been disabled by an administrator.');
          break;
        case 'auth/invalid-credential':
            setErrorMessage('Incorrect password. Please try again.');
            break;
        default:
          setErrorMessage(error.message);
          break;
      }
      setShowError(true);
  
    }
    // localStorage.setItem('agreed', 'true');
  }else if (await checkStatus(enteredUsername) === "Pending"){
    setErrorMessage(`Please wait for Admin to verify your account`)
    setShowError(true);
  }
  else if (await checkStatus(enteredUsername) === "Blocked"){
    setErrorMessage(`Your account is unable to login.`)
    setShowError(true);
  }
  }

  // Check if the user had previously checked "Remember me" and populate the login form fields
  useEffect(() => {
    const rememberedUser = JSON.parse(localStorage.getItem('rememberedUser'));
    if (rememberedUser) {
      // Populate the login form fields with the saved username and password
      document.getElementById('email').value = rememberedUser.email;
      document.getElementById('password').value = rememberedUser.password;
      
    }
  }, []);



  return (
    <>
    {user && (<Navigate to={'/MyPage'} replace={true} />)}
    <Header/>
    <div className="main-banner" id='login'>
      <div className="container">
      
        <div className="row" id='card'>
          <div className="col-12 col-sm-6 p-0">
            <div className={`${showLogin ? 'login-bg slide-left' : 'signup-bg slide-right'}`}>
              {/* <img src={`${showLogin ? login_bg : signup_up}`} alt="" /> */}
              <div className='bg-forms'></div>
              <div className="text d-flex flex-column justify-content-center align-items-center">
              {showLogin ? (
                <>
                <h1>Sign up here</h1>
                {/* <button className='btn btn-link' id='create-btn' onClick={toggleSection}>Create an account</button> */}
                <a href='https://loyalistashop.ph/Referral/rMX60ERHa6MKxmDXLt2QQPRHJeh1' target='_blank' className='btn btn-link' id='create-btn'>Create an account</a>
                </>
              ) : (
                <>
                <h1>Log in here</h1>
                <button className='btn btn-link' id='create-btn' onClick={toggleSection}>Have an Account?</button>
                </>
              )}
              
            </div>
            </div>
           
          </div>
          <div className={`col-12 col-sm-6 ${showLogin ? 'login-page slide-right' : 'signup-page slide-left'}`}>
            {showLogin ? (
              /*Log in form */
              <>
              {/* <div className="header">
                  <div className="logo-holder">
                    <img src={LOGO} alt="" />
                  </div>
              </div> */}
              {showError && (
                <div className="text-danger d-flex align-items-center justify-content-center m-2" style={{textAlign: 'center', fontWeight: '600'}}>
                  {errorMessage}
                </div>
              )}
              <div className="center-form">
                <form action="" onSubmit={handleLogin}>
                  <div className="form-floating mb-3">
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      id="email"
                      placeholder=""
                      required
                    />
                    <label htmlFor="email">Email</label>
                  </div>
                  <div className="form-floating mb-3">
                    <input
                      type={showpass ? 'text' : 'password'}
                      className="form-control"
                      name="password"
                      id="password"
                      placeholder=""
                      required
                      
                    />
                    <label htmlFor="password">Password</label>
                    <div className="showpass" >
                      {showpass ? (
                        <FaEyeSlash onClick={toggleShowPass}/>
                      ) : (
                        <FaEye onClick={toggleShowPass}/>
                      )}
                      
                    </div>
                  </div>
                
                  <div className="other">
                    <div className="form-check">
                      <input className="form-check-input" 
                             type="checkbox" 
                             value="" 
                             id="remember" 
                             name='remember' 
                             onClick={handleRememberMeChange}/>
                      <label className="form-check-label" htmlFor="remember"> Remember me. </label>
                    </div>
                    <Link to='/Forgot Password'>Forgot Password?</Link>
                  </div>
                  
                  
                  <button type='submit' className='btn btn-success login-btn'>Log in</button>
                  
                </form>
                
              </div>
              </>
            ) : (
              /*Sign Up form */
              <>
              {/* <div className="header">
                <h1>Welcome to <br/>Loyalista Online Shop</h1>
                <p>We are happy to work with you. Please if you dont have account yet, sign up the following forms below.</p>
              </div> */}

              <div className="center-form">
              <Steps current={currentStep} size="small" style={{ marginBottom: '20px' }} >
                <Step title="Personal" />
                <Step title="Account" />
                <Step title="Member"/>
                {/* <Step title="Address" />
                <Step title="Others" /> */}
              </Steps>
                <form action="" onSubmit={handleSubmit}>
                {showError && (
                <div className="text-danger m-auto" style={{textAlign: 'center', fontWeight: '600', textWrap: 'wrap', width: '85%'}}>
                  {errorMessage}
                </div>
               )}
                {currentStep === 0 && (
                <>
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      className="form-control"
                      name="firstname"
                      id="firstname"
                      placeholder=''
                      value={signupForm.firstname}
                      onChange={(e) => setsignupForm({ ...signupForm, firstname: e.target.value })}
                      required
                    />
                    <label htmlFor="firstname">First Name</label>
                  </div>
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      className="form-control"
                      name="middlename"
                      id="middlename"
                      placeholder=''
                      value={signupForm.middlename}
                      onChange={(e) => setsignupForm({ ...signupForm, middlename: e.target.value })}
                    />
                    <label htmlFor="middlename">Middle Name</label>
                  </div>
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      className="form-control"
                      name="lastname"
                      id="lastname"
                      placeholder=''
                      value={signupForm.lastname}
                      onChange={(e) => setsignupForm({ ...signupForm, lastname: e.target.value })}
                      required
                    />
                    <label htmlFor="lastname">Last Name</label>
                  </div>
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      className="form-control"
                      name="contact"
                      id="contact"
                      placeholder=''
                      value={signupForm.contact}
                      onChange={(e) => {
                        const input = e.target.value;
                        if (input.length <= 11) {
                          setsignupForm({ ...signupForm, contact: input });
                        }
                      }}
                      required
                    />
                    <label htmlFor="contact">Contact Number</label>
                  </div>
                </>
                )}
                {currentStep === 1 && (
                <>
                <div className="form-floating mb-3">
                    <input
                      type="text"
                      className="form-control"
                      name="username"
                      id="username"
                      placeholder=""
                      value={username}
                      onChange={(e) => setUserName(e.target.value)}
                      required
                    />
                    <label htmlFor="username">Username</label>
                </div>
                  <div className="form-floating mb-3">
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      id="email"
                      placeholder=""
                      value={newEmail}
                      onChange={(e) => {
                        setNewEmail(e.target.value)
                      }}
                      required
                    />
                    <label htmlFor="email">Email</label>
                </div>
                <div className="form-floating mb-3">
                  <input
                    type="password"
                    className="form-control"
                    name="password"
                    id="password"
                    placeholder=""
                    required
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                      setErrorMessage('');
                    }}
                  />
                  <label htmlFor="password">Password</label>
                </div>
                <div className="form-floating mb-3">
                  <input
                    type="password"
                    className="form-control"
                    name="confirmPass"
                    id="confirmPass"
                    placeholder=""
                    required
                    value={confirmPass}
                    onChange={(e) => {
                      setConfirmPass(e.target.value);
                      setErrorMessage('');
                    }}
                  />
                  <label htmlFor="confirmPass">Confirm Password</label>
                </div>
                </>
                )}
                {currentStep === 2 && (
                  <>
                    <div className="membership mb-3">
                      <span>Are You a Member of Infinite Online Shop?</span>
                      <div class="form-check">
                        <input 
                          class="form-check-input" 
                          type="radio" 
                          name="membership" 
                          id="IOSMember" 
                          value="IOS Member"
                          checked={isIOSMember}
                          onChange={() => {
                            setIsIOSMember(true);
                            setsignupForm({ ...signupForm, membership: 'IOS Member' }); // Update signupForm with membership status
                          }}/>
                        <label class="form-check-label" for="IOSMember">
                          Yes
                        </label>
                      </div>
                    
                      <div class="form-check">
                        <input 
                          class="form-check-input"
                          type="radio" 
                          name="membership" 
                          id="LOSMember" 
                          value="LOS Member" 
                          checked={!isIOSMember}
                          onChange={() => {
                            setIsIOSMember(false);
                            setsignupForm({ ...signupForm, membership: 'LOS Member' }); // Update signupForm with membership status
                          }}/>
                        <label class="form-check-label" for="LOSMember">
                          No
                        </label>
                      </div>
                    </div>
                    {isIOSMember && (
                    <div className="form-floating mb-3">
                      <input 
                        type="text" 
                        className="form-control" 
                        name='accountID'
                        id='accountID'
                        placeholder=''
                        value={signupForm.accountID}
                        onChange={(e) => {
                          const input = e.target.value;
                          setsignupForm({ ...signupForm, accountID: input });
                        }}
                        required
                      />
                      <label htmlFor="memberID">Account ID</label>
                    </div>
                    )}
                    {/* <div class="upload-id mb-3">
                      <span className='label'>Government ID</span>
                      <input className='upload inputfile'
                        type="file"
                        capture="camera"
                        accept="image/*"
                        name="IdPicture"
                        value={signupForm.IdPicture}
                        onChange={handleImageUpload}
                        id="IdPicture"/>
                        <label htmlFor="IdPicture" className='col-6 IDpic'>
                          <span>
                            <IoCameraSharp/> {truncateString(fileName, 20) || 'Choose a file...'}
                          </span>
                        </label>
                    </div> */}
                    
                  </>
                )}
                {currentStep === 3 && (
                <>
                  <div className="form-floating mb-3">
                    <select className="form-select" 
                        name="region"
                        id="region"
                        value={inputRegion}
                        onChange={onChangeRegion}
                        required>
                      <option value='' disabled selected>
                        Select Region
                      </option>
                      {regionData.map(item => (
                        <option
                          key={item.region_code}
                          value={item.region_code}
                        >
                          {item.region_name}
                        </option>
                      ))}
                    </select>
                    <label htmlFor="region">Region</label>
                  </div>
                  <div className="form-floating mb-3">
                    <select className="form-select" 
                        name="province"
                        id="province"
                        value={inputProvince}
                        onChange={onChangeProvince}
                        required>
                      <option value='' disabled selected>
                        Select Province
                      </option>
                      {provinceData.map(item => (
                        <option
                          key={item.province_code}
                          value={item.province_code}
                        >
                          {item.province_name}
                        </option>
                      ))}
                    </select>
                    <label htmlFor="province">Province</label>
                  </div>
                  <div className="form-floating mb-3">
                    <select className="form-select" 
                        name="city"
                        id="city"
                        value={inputCity}
                        onChange={onChangeCity}
                        required>
                      <option value='' disabled selected>
                        Select City / Municipality
                      </option>
                      {cityData.map(item => (
                        <option 
                          key={item.city_code} 
                          value={item.city_code}
                        >
                          {item.city_name}
                        </option>
                      ))}
                    </select>
                    <label htmlFor="city">City / Municipal</label>
                  </div>
                  <div className="form-floating mb-3">
                    <select className="form-select" 
                        name='brgy'
                        id="brgy"
                        value={inputBrgy}
                        onChange={onChangeBarangay}
                        required>
                      <option value='' disabled selected>
                        Select Barangay
                      </option>
                      {barangayData.map(item => (
                        <option 
                          key={item.brgy_code} 
                          value={item.brgy_code}
                        >
                          {item.brgy_name}
                        </option>
                      ))}
                    </select>
                    <label htmlFor="brgy">Barangay</label>
                  </div>
                </>
                )}
                {currentStep === 4 && (
                <>
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      className="form-control"
                      name="street"
                      id="street"
                      placeholder=""
                      value={signupForm.street}
                      onChange={(e) => setsignupForm({ ...signupForm, street: e.target.value })}
                      required
                    />
                    <label htmlFor="street">Street</label>
                  </div>
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      className="form-control"
                      name="postal"
                      id="postal"
                      placeholder=""
                      value={signupForm.postal}
                      onChange={(e) => setsignupForm({ ...signupForm, postal: e.target.value })}
                      required
                    />
                    <label htmlFor="postal">Postal Code</label>
                  </div>
                  <div className="form-floating mb-3">
                    <textarea
                      style={{height: '132px'}}
                      className="form-control"
                      name="landmark"
                      id="landmark"
                      value={signupForm.landmark}
                      onChange={(e) => setsignupForm({ ...signupForm, landmark: e.target.value })}
                      placeholder=""                      
                    />
                    <label htmlFor="landmark">Landmark (Optional)</label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="termsandpolicy" required/>
                    <label class="form-check-label" for="termsandpolicy">
                      I have read and agree to the terms and conditions of Loyalista Online Shop and privacy policy.
                    </label>
                  </div>
                </>
                )}
                <div className="d-flex justify-content-between align--items-center">
                  {currentStep > 0 && (
                    <button type='button' className='btn btn-primary' style={{ minWidth: '110px' }} 
                      onClick={handlePrev}>
                        <GrFormPreviousLink/> Previous
                    </button>
                  )}
                  {currentStep < 4 && (
                    <button type='button' className='btn btn-primary' style={{ minWidth: '110px' }} 
                      onClick={handleNext}>
                        Next <GrFormNextLink/>
                    </button>
                  )}
                  {currentStep === 4 && (
                    <button type="submit" className='btn btn-success' style={{ minWidth: '110px' }} 
                      disabled={isLoading}>
                    {isLoading && (
                      <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                    )}
                    {isLoading ? 'Creating...' : 'Submit Form'}
                  </button>
                  )}
                </div>
                
                </form>
              </div>
              </>
            )}
            
          </div>

        </div>
      </div>
      
    </div>
    <Footer/>
    </>
    
  )
}

export default LoginSignup;