import React, { useState, useEffect } from 'react'
import './MyStore.css'
import no_data from '../../../Assets/images/no-data.png'
import { FaPlus, FaMinus, FaCartShopping } from "react-icons/fa6";
import { IoSearch } from "react-icons/io5";
import { Badge, Drawer, message, Pagination  } from 'antd';
import { CartContent, CartFooter } from '../../../HookComponents/CartContainer/StoreCartContent';
import { useCartStore, useCheckType } from '../../../DataTest/cart-store';
import { useAuth } from '../../../contexts/authContext';
import {  Navigate } from 'react-router-dom';
import { db,storage } from '../../../firebase/config';
import { get, child, ref, set,serverTimestamp, onValue } from 'firebase/database';
import { getDownloadURL, ref as storageRef ,listAll} from 'firebase/storage';

const MyStore = () => {
  const {userLoggedIn} = useAuth() 
  const [cartContainer, setCartContainer] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [productData, setProductData] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [currentPage, setCurrentPage] = useState(1); // State for current page
  const pageSize = 5; // Number of items per page
  const user = JSON.parse(localStorage.getItem('user'))
  const [dependencyKey, setDependencyKey] = useState(0);
  const [barleyCommission,setBarleyCommission] = useState(0)

  const { setUserInformation} = useCheckType((state) => ({
    setUserInformation: state.setUserInformation,
}))

  const { cart, setCart } = useCartStore((state) => ({
    cart: state.cart,
    setCart: state.setCart,
}));


useEffect(() => {
  const getUserInfo = async () => {
    try {
      const userInfoRef = ref(db, `users/${user.uid}`);
      const snapshot = await get(userInfoRef);

      if (snapshot.exists()) {
        const userData = snapshot.val();
        setUserInformation({...userData,
          Fullname:userData.firstname+ " " + userData.middlename + " " + userData.lastname,
          Address: userData.street + userData.brgy + ", " + userData.city+ ", " + userData.province ,
          username: userData.username
        });
        setDependencyKey(dependencyKey + 1)
      }
    } catch (error) {
      console.error(error);
    }
  };
  getUserInfo();
    
}, []);

useEffect(() => {
  const fetchOrderItems = async () => {
      try {
          const ordersRef = ref(db, 'orders');
          const snapshot = await get(ordersRef);
          const userInfoRef = ref(db, `users/${user.uid}`);
          const snapshotUser = await get(userInfoRef);
    
          if (snapshot.exists()) {
              const orderItemsArray = Object.entries(snapshot.val())
                  .filter(([orderId, orderData]) => (orderData.user === user.uid) && (orderData.status === 'Completed'))
                  .map(([orderId, orderData]) => ({
                      orderId: orderId,
                      ...orderData,
                  }));

              let highestBarleyCommission = 0;
              const userInfo = snapshotUser.val()
              if(userInfo.membership === 'IOS Member' && snapshotUser.exists()) {
                highestBarleyCommission=400;
              }else{
              orderItemsArray.forEach(order => {
                  order.items.forEach((item) => {
                      if (item.category === 'Package' && item.barleyCommission !== undefined) {
                          const itemBarleyCommission = item.barleyCommission;

                          // Check if this item's barleyCommission is higher than the current highest
                          if (itemBarleyCommission > highestBarleyCommission) {
                              highestBarleyCommission = itemBarleyCommission;
                          }
                      }
                  });
              });
            }
              setBarleyCommission(highestBarleyCommission); // Set the highest barleyCommission
          } else {
              console.log('No data available');
          }
      } catch (error) {
          console.error(error);
      }
  };

  fetchOrderItems();
}, []); // Add user.uid as a dependency to re-fetch when user.uid changes

useEffect(() => {
  const cartRef = ref(db, `cart/${user.uid}`);
  
  // Set up a real-time listener
  const unsubscribe = onValue(cartRef, (snapshot) => {
      if (snapshot.exists()) {
          const cartItemsArray = Object.entries(snapshot.val())
              .map(([productId, productData]) => ({
                  id: productId,
                  ...productData,
              }))
              .sort((a, b) => b.timestamp - a.timestamp);

          setCart(cartItemsArray);
      } else {
          setCart([]);
      }
  });

  // Clean up the listener when the component unmounts
  return () => unsubscribe();
}, [dependencyKey]);

useEffect(() => {
  const fetchProducts = async () => {
    try {
      const snapshot = await get(child(ref(db), 'products'));
      if (snapshot.exists()) {
        const productsData = snapshot.val();
        const productsArray = Object.values(productsData);

        const productsWithImages = await Promise.all(
          productsArray.map(async (product) => {
            const imagesListRef = storageRef(storage, `products/${product.id}/`);
            try {
              const images = await listAll(imagesListRef);
              const imageUrls = await Promise.all(
                images.items.map(async (imageRef) => {
                  try {
                    const imageUrl = await getDownloadURL(imageRef);
                    return { url: imageUrl, name: imageRef.name };
                  } catch (error) {
                    console.error('Error fetching image:', error);
                    return null;
                  }
                })
              );

              const filteredImageUrls = imageUrls.filter((item) => item !== null);
              return { ...product, images: filteredImageUrls };
            } catch (error) {
              console.error('Error listing images:', error);
              return { ...product, images: [] };
            }
          })
        );

        const sortedProductsWithImages = productsWithImages.map((product) => {
          if (product.category === 'Package' && product.commissions) {
            const sortedCommissions = {};
            Object.keys(product.commissions)
              .sort((a, b) => parseInt(a.replace('level', '')) - parseInt(b.replace('level', '')))
              .forEach((key) => {
                sortedCommissions[key] = product.commissions[key];
              });
            return { ...product, commissions: sortedCommissions };
          }
          return product;
        });

        setProductData(sortedProductsWithImages);
        setFilteredProducts(sortedProductsWithImages);
        if (sortedProductsWithImages.length > 0) {
          setSelectedProduct(sortedProductsWithImages[0]);
        }
      } else {
        console.log('No data available');
      }
    } catch (error) {
      console.error(error);
    }
  };

  fetchProducts();
}, []);


  const openCart = () => {
    setCartContainer(true);
  };

  const closeCart = () => {
    setCartContainer(false);
  };


  const handleAddToCart = async (event, item, itemQuantity) => {
    event.preventDefault();
    const cartItemRef = ref(db, `cart/${user.uid}/${item.id}`);
    const cartItemSnapshot = await get(cartItemRef);
    const timestamp = serverTimestamp();

    // Adjust the price if the item category is 'Single Product'
    const adjustedPrice = item.category === 'Single Product' ? item.price - barleyCommission : item.price ;

    if (cartItemSnapshot.exists()) {
        const existingQuantity = cartItemSnapshot.val().quantity || 0;
        const newQuantity = existingQuantity + itemQuantity;
        await set(ref(db, `cart/${user.uid}/${item.id}`), {
            id: item.id,
            name: item.name,
            quantity: newQuantity,
            price: adjustedPrice, // Use the adjusted price
            timestamp: timestamp,
            image: item.images[0].url,
            commissions: item.commissions || null,
            barleyCommission: item.barleyCommission,
            category: item.category
        });
    } else {
        await set(cartItemRef, {
            id: item.id,
            quantity: itemQuantity,
            price: adjustedPrice, // Use the adjusted price
            name: item.name,
            timestamp: timestamp,
            image: item.images[0].url,
            commissions: item.commissions || null,
            barleyCommission: item.barleyCommission,
            category: item.category
        });
    }
    message.success('Item Added to your cart.');
    setDependencyKey((prevKey) => prevKey + 1);
};


const handleChangeQuantity = (e) => {
    setQuantity(parseInt(e.target.value))
}

const handleDecreaseQuantity = () => {
    if (quantity > 1) {
        setQuantity(quantity - 1);
    }
};

const handleIncreaseQuantity = () => {
    setQuantity(quantity + 1);
};


  // Filter the products whenever the search query changes
  useEffect(() => {
    if (productData) {
      const filtered = productData.filter(product =>
        product.name?.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredProducts(filtered);
      setCurrentPage(1);
    } else {
      setFilteredProducts([]);
    }
  }, [searchQuery, productData]);
  

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  
  // Calculate the start and end index of the products to display based on current page and page size
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const displayedProducts = filteredProducts.slice(startIndex, endIndex);

  const handleProductSelect = (product) => {
    setSelectedProduct(product);
  };
  
  return (
<main id='mystore'>
  {!userLoggedIn && (<Navigate to={'/login'} replace={true} />)}
  <div className="head-title">
    <div className="left">
      <h1>My Store</h1>
    </div>
    <button className='btn cart-btn' onClick={openCart}>
      <Badge count={cart.length} overflowCount={99}>
        <span style={{ fontSize: '18px', fontWeight: '600' }}>My Cart</span> <FaCartShopping style={{ fontSize: '24px' }} />
      </Badge>
    </button>
    <Drawer
      title={<h4 style={{ fontSize: '23px', margin: '0' }}>MY CART</h4>}
      placement='right'
      width={500}
      onClose={closeCart}
      open={cartContainer}
      footer={<CartFooter closeCart={closeCart} />}
    >
      <CartContent closeCart={closeCart} />
    </Drawer>
  </div>
  <div className="item-list">
    {selectedProduct && selectedProduct.images && selectedProduct.images.length > 0 && (
      <div className="select-prod">
        <div className="row w-100">
          <div className="col-12 col-lg-6 product-image">
            <img src={selectedProduct.images[0].url} alt={selectedProduct.name} />
          </div>
          <div className="col-12 col-lg-6 product-info">
            <h1>{selectedProduct.name}</h1>
            {selectedProduct.name === 'Infin8 Pure Organic Barley' ? (
              barleyCommission === 0 ? (
                <h6>
                  ₱ {parseFloat(selectedProduct.price).toFixed(2)}
                </h6>
              ) : (
                <div className='discounts'>
                  <span className='originalPrice'>₱ {parseFloat(selectedProduct.price).toFixed(2)}</span>
                  <h6 className='text-danger'>
                    ₱ {(parseFloat(selectedProduct.price) - parseFloat(barleyCommission)).toFixed(2)}
                  </h6>
                </div>
              )
            ) : (
              <h6 className='text-muted'>₱ {parseFloat(selectedProduct.price).toFixed(2)} </h6>
            )}
            <pre className='description'>
              {selectedProduct.description}
            </pre>
            <div className="quantity">
              <h5>Quantity</h5>
              <div className="quantity-btn">
                <input type="number" className='form-control'
                  min={1}
                  max={50}
                  value={quantity}
                  onChange={handleChangeQuantity} />
                <FaPlus className='plus-btn' onClick={handleIncreaseQuantity} />
                <FaMinus className='minus-btn' onClick={handleDecreaseQuantity} />
              </div>
            </div>
            <div className="addtoCart-btn">
              <button className="btn btn-success addtocart" onClick={(e) => handleAddToCart(e, selectedProduct, quantity)}>
                Add to Cart
              </button>
            </div>
          </div>
        </div>
      </div>
    )}
    <div className="product-lists">
      <div className="header">
        <div className="row justify-content-between align-items-center">
          <div className="col-md-6"><h1>Product Lists</h1></div>
          <div className="col-md-6">
            <div className="search-bar">
              <IoSearch className='search-icon' />
              <input type="search"
                className='form-control'
                placeholder='Search here...'
                onChange={handleSearchChange} />
            </div>
          </div>
        </div>
      </div>

      <div className="table-data">
        <div className="order">
          {filteredProducts.length === 0 ? (
            <div className="empty-table">
              <img src={no_data} alt="" />
              <h5>No data found!</h5>
            </div>
          ) : (
            <>
              <table>
                <thead>
                  <tr>
                    <th>Product ID</th>
                    <th>Product Name</th>
                    <th>Price</th>
                  </tr>
                </thead>
                <tbody>
                  {displayedProducts.map(product => (
                    <tr key={product.id} onClick={() => handleProductSelect(product)}>
                      <td>
                        <img src={product.images && product.images.length > 0 ? product.images[0].url : ''} alt={`Product ${product.id}`} />
                        <p>{product.id}</p>
                      </td>
                      <td>{product.name}</td>
                      <td>₱ {parseFloat(product.price).toFixed(2)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="pagination-container">
                <Pagination
                  current={currentPage}
                  total={filteredProducts.length}
                  pageSize={pageSize}
                  onChange={handlePageChange}
                  showSizeChanger={false}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>

  </div>
</main>

  )
}

export default MyStore;
