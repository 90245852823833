import React from 'react'
import './Error404.css'
import LOGO from '../../../../Assets/images/loyalista_logo(original).png';
import Icon_404 from '../../../../Assets/images/icon_error404.png'
import { Link } from 'react-router-dom'
import { GrFormPreviousLink } from "react-icons/gr";
const Error404 = () => {
  return (
    <div id='error404'>
        <div className="header">
            <div className="logo-container container">
                <img src={LOGO} alt="" />
            </div>
        </div>
        <section className="center container">
            <div className="card">
                <div className="card_header p-3 d-flex align-items-center justify-content-start">
                    <Link to='/'><GrFormPreviousLink style={{fontSize: '17px'}}/> Back to Home Page</Link>
                </div>
                <div className="card-body">
                    <div className="icon-error404">
                        <img src={Icon_404} alt="" />
                    </div>
                    <span>Page Not Found!</span>
                    <p className='text-muted'>Oops! It appears that the page you are trying to access cannot be found. 
                    <br/>Please double-check the URL or navigate back to the homepage.</p>
                </div>
            </div>
        </section>
    </div>
  )
}

export default Error404;
