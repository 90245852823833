import React, { useState, useEffect } from 'react';
import './SalesReport.css';
import { Select } from 'antd';
import SampleProduct from '../../../DataTest/SampleProducts';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Brush,
  AreaChart,
  Area,
  ResponsiveContainer,
} from 'recharts';
import { get, ref } from 'firebase/database';
import { db } from '../../../firebase/config';
import moment from 'moment'
const { Option } = Select;

const SalesReport = () => {

    const [sales, setSales] = useState([]);
    const [totalSales, setTotalSales] = useState(0);
    const [totalSalesAmount, setTotalSalesAmount] = useState(0);
    const [selectedYear, setSelectedYear] = useState(null);
    const [dateRange, setDateRange] = useState('');
    const [totalSalesForYear, setTotalSalesForYear] = useState(null);
    const [totalSalesAmountForYear, setTotalSalesAmountForYear] = useState(null);

    useEffect(() => {
      const fetchOrderItems = async () => {
        try {
          const ordersRef = ref(db, 'orders');
          const snapshot = await get(ordersRef);
    
          if (snapshot.exists()) {
            const ordersData = snapshot.val();
          let overallTotalSales = 0;
          let companySales = 0;

          const formattedDataArray = Object.entries(ordersData)
            .filter(([requestID, requestData]) => requestData.status === 'Completed')
            .map(([date, order]) => {
                // Calculate overallTotalSales

                // Calculate companySales
                const productSales = order.items.reduce((total, product) => {
                    // Deduct cashbond for Package products
                    const productPrice = product.category === 'Package' ? (product.price - (500 * product.quantity)) : product.price;
                    overallTotalSales += parseInt(product.category === 'Package' ? (product.price - (500* product.quantity)) : product.price * product.quantity)
                    // Add product price to companySales
                    companySales -= productPrice * product.quantity;

                    return total + productPrice * product.quantity;
                }, 0);

                return {
                    seller: order.seller,
                    dateOrder: moment(order.timestamp).format('MMMM DD YYYY'),
                    TotalSales: productSales,
                    TotalClientSales: order.sales || 0,
                    companySales: productSales,
                    Product: order.items.map(product => ({
                        productID: product?.productId,
                        name: product.name,
                        price: product.category === 'Package' ? product.price - 500 : product.price,
                        quantity: product.quantity,
                        totalPrice: productSales * product.quantity,
                        TotalProductPrice: productSales * product.quantity
                    })),
                };
            });
            formattedDataArray.sort((a, b) => new Date(a.dateOrder) - new Date(b.dateOrder));
            setSales(formattedDataArray)
            setTotalSalesAmount(overallTotalSales);
          } else {
            setSales([])
          }
        } catch (error) {
          console.error(error);
        }
      };
    
      fetchOrderItems();
    }, []);
    
    useEffect(() => {
      if (!sales || sales.length === 0) {
        // Handle case where sales is undefined or empty
        setTotalSales(0);
        return;
      }
    
      // Flatten the product quantities across all sales entries
      const flattenedProducts = sales
        .flatMap((sale) => sale.Product || [])
        .filter((product) => product.quantity !== undefined && product.totalPrice !== undefined);
    
      if (flattenedProducts.length > 0) {
        // Calculate total sales count and total amount
        const { totalSalesCount, totalAmount } = flattenedProducts.reduce(
          (accumulator, product) => {
            accumulator.totalSalesCount += product.quantity;
            accumulator.totalAmount += product.totalPrice;
            accumulator.clientSales += product.TotalClientSales;
            return accumulator;
          },
          { totalSalesCount: 0, totalAmount: 0,}
        );
        setTotalSales(totalSalesCount);
      } else {
        // Handle case where flattenedProducts is empty
        setTotalSales(0);
      }
    }, [sales]);


    const formatDate = (date) => {
        const options = { year: 'numeric', month: 'short' };
        return date.toLocaleDateString('en-US', options);
    };
    
    const formatCurrency = (amount) => {
      return '₱ ' + amount.toLocaleString('en-US', { maximumFractionDigits: 2 });
    }

    const handleChangeYear = (value) => {
      if (value === 'All Years') {
          setSelectedYear(null);
          updateTotalSalesAndAmount(null);
      } else {
          const selectedYear = parseInt(value);
          setSelectedYear(selectedYear);
          updateTotalSalesAndAmount(selectedYear);
      }
  };

  const updateTotalSalesAndAmount = (year) => {
    let totalSalesCount = 0;
    let totalAmount = 0;
    sales.forEach((sale) => {
        const saleDate = new Date(sale.dateOrder);
        const saleYear = saleDate.getFullYear();

        if (year === null || saleYear === year) {
            sale.Product.forEach((product) => {
                totalSalesCount += product.quantity;
                totalAmount += product.quantity * product.price;
            });
        }
    });

    if (year === null) {
        setTotalSales(totalSalesCount);
        setTotalSalesAmount(totalAmount);
    } else {
        setTotalSalesForYear(totalSalesCount);
        setTotalSalesAmountForYear(totalAmount);
    }
};

    const getUniqueYears = () => {
        const uniqueYears = new Set();
        sales.forEach((sale) => {
        const date = new Date(sale.dateOrder);
        uniqueYears.add(date.getFullYear());
        });
        return Array.from(uniqueYears);
    };

    // const updateYearData = (year) => {
    //     setSelectedYear(year);
    //     const filteredSales = year !== null ? sales.filter((sale) => new Date(sale.Date).getFullYear() === year) : sales;
    //     const startDate = filteredSales.length > 0 ? new Date(filteredSales[0].Date) : null;
    //     const endDate = filteredSales.length > 0 ? new Date(filteredSales[filteredSales.length - 1].Date) : null;
    //     const totalSalesCount = filteredSales.reduce((acc, cur) => {
    //         return acc + cur.Product.reduce((total, product) => total + product.quantity, 0);
    //     }, 0);
    //     const totalAmount = filteredSales.reduce((acc, cur) => {
    //         return acc + cur.Product.reduce((total, product) => total + product.quantity * product.price, 0);
    //     }, 0);
    //     setDateRange(startDate && endDate ? `${formatDate(startDate)} - ${formatDate(endDate)}` : 'No sales data available');
    //     setTotalSales(totalSalesCount);
    //     setTotalSalesAmount(totalAmount);
    // };


    const filteredSales = selectedYear ? sales.filter((sale) => new Date(sale.dateOrder).getFullYear() === selectedYear) : sales;

    useEffect(() => {
        let startDate = null;
        let endDate = null;
        let totalSalesCount = 0;
        let totalAmount = 0;
    
        sales.forEach((sale) => {
            const saleDate = new Date(sale.dateOrder);
            const saleYear = saleDate.getFullYear();
    
            if (!selectedYear || saleYear === selectedYear) {
                  sale.Product.forEach((product) => {
                    totalSalesCount += product.quantity;
                    totalAmount += product.quantity * product.price;
                });
    

                if (!startDate || saleDate < startDate) {
                    startDate = saleDate;
                }
    
                if (!endDate || saleDate > endDate) {
                    endDate = saleDate;
                }
            }
        });
        setDateRange(startDate && endDate ? `${formatDate(startDate)} - ${formatDate(endDate)}` : 'No sales data available');
        setTotalSalesForYear(totalSalesCount);
        setTotalSalesAmountForYear(totalAmount);
    }, [sales, selectedYear]);

        
    const mergeProducts = () => {
        const productMap = {};

        filteredSales.forEach((sale) => {
        sale.Product.forEach((product) => {
            const { productID, name, quantity, price } = product;
            const totalPrice = quantity * price;

            if (productMap[productID]) {
            productMap[productID].quantity += quantity;
            productMap[productID].totalPrice += totalPrice;
            productMap[productID].totalAccount++;
            productMap[productID].totalAmount += totalPrice;
            } else {
            productMap[productID] = {
                productID,
                name,
                quantity,
                totalPrice,
                price,
                totalAccount: 1,
                totalAmount: totalPrice,
            };
            }
        });
        });

        return Object.values(productMap);
    };

    const productData = mergeProducts();

    const generateChartData = () => {
        const chartData = {};

        filteredSales.forEach((sale) => {
        const date = new Date(sale.dateOrder);
        const year = date.getFullYear();
        const month = date.getMonth() + 1; // Months are zero-indexed
        const key = `${year}-${month}`;

        if (chartData[key]) {
            chartData[key].totalSales += sale.Product.reduce((total, product) => total + product.quantity, 0);
        } else {
            chartData[key] = {
            date: formatDate(date),
            totalSales: sale.Product.reduce((total, product) => total + product.quantity, 0),
            };
        }
        });

        return Object.values(chartData);
    };

    const chartData = generateChartData();

  return (
    <main id="salesReport">
      <div className="head-title">
        <div className="left">
          <h1>Sales Report</h1>
        </div>
      </div>

      <div className="table-data">
        <div className="product-lists">
          <table>
            <thead>
              <tr>
                <th>Product ID</th>
                <th>Product Name</th>
                <th>Total No. of Sales</th>
                <th>Total Amount</th>
              </tr>
            </thead>
            <tbody>
              {productData.map((product) => (
                <tr key={product.productID}>
                  <td>{product.productID}</td>
                  <td>{product.name}</td>
                  <td>{product.quantity}</td>
                  <td>{formatCurrency(product.totalAmount)}.00</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="range-selector">
          <div className="head">
            <span>Select Date Range</span>
            <Select defaultValue="All Years" style={{ width: 120 }} onChange={handleChangeYear}>
              <Option value="All Years">All Years</Option>
              {getUniqueYears().map((year) => (
                <Option key={year} value={year}>
                  {year}
                </Option>
              ))}
            </Select>
          </div>

          <div className="range-info">
            <span>
              Date Range:
              <p>{dateRange}</p>
            </span>
            <span>
              Total Sales:
              <p>{selectedYear ? totalSalesForYear || 0 : totalSales}</p>
            </span>
            <span>
              Total Sales Amount:
              <p>{formatCurrency(selectedYear ? totalSalesAmountForYear || 0 : totalSalesAmount)}.00</p>
            </span>
          </div>
        </div>
      </div>

      <div className="table-graph">
        <div className="head">
          <h1>Sales Breakdown</h1>
        </div>
        <ResponsiveContainer width="100%" height={290}>
          <AreaChart
            width={500}
            height={200}
            data={chartData}
            syncId="anyId"
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Area type="monotone" dataKey="totalSales" stroke="#8884d8" fill="#8884d8" />
            <Brush />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </main>
  );
};

export default SalesReport;
