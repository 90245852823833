import React, { useState, useEffect } from 'react'
import { message } from 'antd';
import { regions, provinces, cities, barangays } from 'select-philippines-address';
import { useCartStore, useCheckType } from '../../DataTest/cart-store';
import { child, get, ref, remove, serverTimestamp, set } from 'firebase/database';
import { db } from '../../firebase/config';


const MyStorePurchaseForm   = ({onClose}) => {
  const {cart, clearCart} = useCartStore((state) => ({
    cart: state.cart,
    clearCart:state.clearCart,
}))
const { userInformation} = useCheckType((state) => ({
  userInformation: state.userInformation,
}))
  const [shippingForm, setShippingForm] = useState({
    fullname: '',
    email: '',
    contact: '',
    payment: '',
    region: '',
    province: '',
    city: '',
    brgy: '',
    street: '',
    landmark: '',
    shippingFee: 0.0,
  });
  
  const [isFormFilled, setIsFormFilled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [regionData, setRegionData] = useState([]);
  const [provinceData, setProvinceData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [barangayData, setBarangayData] = useState([]);
  const [barleryCommissions, setBarleyCommission] = useState(0)
  const [shippingFee, setShippingFee] = useState(0);
  const [InvoiceID, setInvoiceID] = useState('');

  useEffect(() => {
    const isNCR = userInformation.region === 'National Capital Region (NCR)';
    if (isNCR) {
      setShippingFee(100);
    } else  {
      setShippingFee(150);
    }
  },[])

  useEffect(() => {
    const fetchOrderItems = async () => {
        try {
            const ordersRef = ref(db, 'orders');
            const snapshot = await get(ordersRef);
  
            if (snapshot.exists()) {
                const orderItemsArray = Object.entries(snapshot.val())
                    .filter(([orderId, orderData]) => (orderData.user === userInformation.userID) && (orderData.status === 'Completed'))
                    .map(([orderId, orderData]) => ({
                        orderId: orderId,
                        ...orderData,
                    }));
  
                let highestBarleyCommission = 0;
  
                // Process each order to find the highest barleyCommission
                orderItemsArray.forEach(order => {
                    order.items.forEach((item) => {
                        if (item.category === 'Package' && item.barleyCommission !== undefined) {
                            const itemBarleyCommission = item.barleyCommission;
  
                            // Check if this item's barleyCommission is higher than the current highest
                            if (itemBarleyCommission > highestBarleyCommission) {
                                highestBarleyCommission = itemBarleyCommission;
                            }
                        }
                    });
                });
                setBarleyCommission(highestBarleyCommission); // Set the highest barleyCommission
            } else {
                console.log('No data available');
            }
        } catch (error) {
            console.error(error);
        }
    };
  
    fetchOrderItems();
  }, []); // Add user.uid as a dependency to re-fetch when user.uid changes
  
  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-PH', { style: 'currency', currency: 'PHP' }).format(amount);
  };

   // Function to handle form change
   const handleFormChange = (e) => {
    const { name, value } = e.target;
    setShippingForm(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  useEffect(() => {
    const checkFormFilled = () => {
      const requiredFields = ['fullname', 'email', 'contact', 'payment', 'region', 'province', 'city', 'brgy'];
      return requiredFields.every(field => !!shippingForm[field]);
    };
    setIsFormFilled(checkFormFilled());
  }, [shippingForm]);

  const getCountFromFirebase = async (node) => {
    try {
        if (!node || typeof node !== 'string') {
            throw new Error('Invalid node parameter');
        }

        const nodeRef = ref(db, node);
        const snapshot = await get(nodeRef);

        if (snapshot.exists()) {
            const data = snapshot.val();
            
            if (Array.isArray(data)) {
                return data.length;
            } else if (typeof data === 'object' && data !== null) {
                // Assuming data is an object, count its keys
                return Object.keys(data).length;
            } else {
                throw new Error('Snapshot data is not in a countable format');
            }
        } else {
            return 0; // Return 0 if node doesn't exist
        }
    } catch (error) {
        console.error('Error retrieving count from Firebase:', error);
        // Handle error accordingly
        return null;
    }
};

const createIDGenerator = async (prefix) => {
  try {
      let letter = 'A';
      let count = await getCountFromFirebase('orders');

      if (count === null) {
          count = 0;
      }
      if (count === 999999) {
        count = 1;
        letter = String.fromCharCode(letter.charCodeAt(0) + 1);
      } else {
          count++;
      }
      const numericPart = count.toString().padStart(6, '0');
      setInvoiceID(`INVC-${numericPart}${letter}`)
      const id = `${prefix}-${numericPart}${letter}`;
      return id;
  } catch (error) {
      console.error('Error generating ID:', error);
      // Handle the error accordingly
      return null;
  }
};

  // Function to handle purchase
  const handlePurchase = async (e) => {
    // e.preventDefault()
    setIsLoading(true);
    try {
      await new Promise(resolve => setTimeout(resolve, 1500));
      const orderID = await createIDGenerator('LYLT');
      const timestamp = serverTimestamp(); 
      const orderData = {        
        invoice: {
        From: {
          Name: 'LOYALISTA ONLINE SHOP CORP.',
          Address: 'No. 39, Commercial Unit 2nd Floor Left Wing, 39 Sct. Ybardolaza, Quezon City, Metro Manila',
          Phone: '02-8534-7369',
          Email: 'loyalistaonlineshopcorp@gmail.com',
        },
        To: {
          Name: userInformation.Fullname,
          Address:userInformation.Address,
          Phone: userInformation.contact,
          Email: userInformation.email ,
        },
        InvoiceNumber: InvoiceID,
        OrderID: orderID,
        PaymentMethod: shippingForm.payment,
        items: cart.map((item) => ({
          name: item.name,
          quantity: item.quantity,
          price: item.price,
          productId: item.id,
          barleyCommission:item.barleyCommission,
          commissions: item.commissions || null,
          image:item.image,
        })),
        AmountDue: {
          Subtotal: calculateTotalPrice(),
          Shipping:shippingFee,
          Total: calculateTotalPrice() + shippingFee,
        },
      },
      address: {
        fullAddress:userInformation.Address,
        street: userInformation.street,
        landmark: userInformation.landmark,
      },
        OrderID: orderID,
        user: userInformation.userID,
        seller: userInformation.Fullname,
        username: userInformation.username,
        status: "Pending",
        shopType:'ClientStore',
        total: calculateTotalPrice(),
        companySales:calculateTotalPrice(),
        timestamp: timestamp,
        MemberID: userInformation.accountID ? userInformation.accountID: 'N/A',
        barley: 0,
        customer:{
          Email:userInformation.email,
          Fullname: userInformation.Fullname,
          ContactNum:userInformation.contact,
          Region: userInformation.region
        },
        items: cart.map((item) => ({
          productId: item.id,
          name: item.name,
          quantity: item.quantity,
          price: item.price,
          TotalPrice: item.price * item.quantity,
          barleyCommission:item.barleyCommission,
          commissions: item.commissions || null,
          image:item.image,
          category:item.category
          
        })),

      };
      await set(ref(db, `orders/${orderID}`), orderData).then(async()=>{
        const subject = `Order ${orderID} is Successful wait for Admin Approval`
        const email = userInformation.email
        const response = await fetch('https://loyalista-mailer-aa5b8a82053e.herokuapp.com/placeOrder', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email, orderData , subject }),
        });
        if (response.ok) {
          
          const subject = `We have new order received!`
          const email = 'vincesahagun@yahoo.com'
          const bossresponse = await fetch('https://loyalista-mailer-aa5b8a82053e.herokuapp.com/NewplaceOrder', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email, orderData , subject }),
          });
          if (bossresponse.ok) {
            console.log('Email sent successfully');
          } else {
            console.error('Failed to send email');
          }

        } else {
          setIsLoading(true)
          console.error('Failed to send email');
        }
      })
    setTimeout(() => {
      setIsLoading(false);
      clearCart()
      onClose();
      setTimeout(() => { 
        setShippingForm({
          fullname: '',
          email: '',
          contact: '',
          payment: '',
          region: '',
          province: '',
          city: '',
          brgy: '',
          street: '',
          landmark: '',
          shippingFee: 0.0,
        });
      }, 800);
    }, 2000); // Delay for 2 seconds
      message.success('Ordered Successfully');
      await remove(ref(db, `cart/${userInformation.userID}`));
      setIsLoading(false); 
    } catch (error) {
      console.error('Error placing order:', error);
      setIsLoading(false);
    }
  };
  const calculateTotalPrice = () => {
    let totalPrice = 0;
    cart.forEach(product => {
        totalPrice += product.price * product.quantity;
    });
    return totalPrice;
};

  return (
    <div className='container-fluid'>
      <section className="center-form">
        <form action="">
          <div className="row">
            {/* list of order */}
            <div className="col-12 col-lg-4">
              <div className='table-responsive'>
                <table className="table table-striped">
                  <caption>List of orders</caption>
                  <thead>
                    <tr>
                      <th></th>
                      <th>Items</th>
                      <th>Quantity</th>
                      <th>Price</th>
                      <th>SubTotal</th>
                    </tr>
                  </thead>
                  <tbody>
                    { cart.map((product, index) => (
                      <tr>
                      <td style={{maxWidth: '80px'}}>
                        <div className="img-holder" style={{width: '50px', height: '50px'}}>
                          <img src={product.image} alt="" style={{width: '100%', height: 'auto'}}/>
                        </div>
                      </td>
                      <td>{product.name}</td>
                      <td>{product.quantity}</td>
                      <td>₱{parseFloat(product.price).toFixed(2).toLocaleString()}</td>
                      <td>₱{(product.price * product.quantity).toFixed(2).toLocaleString()}</td>
                    </tr>
                    ))}
                  </tbody>
                </table>
                <div className="subtotal">
                  <p className="lead">Amount Due</p>
                  <table className='table'>
                    <tr>
                      <th scope="col">Subtotal:</th>
                      <td>₱{calculateTotalPrice().toLocaleString()}</td>
                    </tr>
                    <tr>
                      <th>Shipping fee:</th>
                      <td>{formatCurrency(shippingFee)}</td>
                    </tr>
                    <tr>
                      <th>Total:</th>
                      <td>₱{(shippingFee+calculateTotalPrice()).toLocaleString()}</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>

            {/* Form Field */}
            <div className="col-12 col-lg-8">
              <div className="card mb-2">
                <div className="card-header border-0">
                  <h5>Contact Info</h5>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-12 col-sm-6">
                      <div className="form-floating mb-3">
                        <input
                          type="text"
                          className="form-control"
                          name="fullname"
                          id="fullname"
                          placeholder=""
                          value={userInformation.Fullname}
                          onChange={handleFormChange}
                          required
                          disabled
                        />
                        <label htmlFor="fullname">Recipient Name <small className='text-danger'>(Full Name)</small></label>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="form-floating mb-3">
                        <input
                          type="email"
                          className="form-control"
                          name="email"
                          id="email"
                          placeholder=""
                          value={userInformation.email}
                          onChange={handleFormChange}
                          disabled
                          required
                        />
                        <label htmlFor="email">Email Address</label>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="form-floating mb-3">
                        <input
                          type="text"
                          className="form-control"
                          name="contact"
                          id="contact"
                          placeholder=""
                          value={userInformation.contact}
                          onChange={handleFormChange}
                          disabled
                          required
                        />
                        <label htmlFor="contact">Contact Number</label>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="form-floating mb-3">
                        <select className="form-select"
                           name="payment"
                           id="payment" 
                           value={shippingForm.payment}
                           onChange={handleFormChange}
                           required>
                          <option value='' disabled>
                            Select Payment Method
                          </option>
                          <option value="COD">Cash on delivery</option>
                          <option value="Bank">Bank Transfer</option>
                        </select>
                        <label htmlFor="payment">Payment Method</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card mb-2">
                <div className="card-header border-0">
                  <h5>Shipping Address</h5>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12 col-sm-6">
                        <div className="form-floating mb-3">
                          <select className="form-select" 
                             name="region"
                             id="region"
                             value={userInformation.region}
                             disabled
                             required>
                            <option value=''>
                            {userInformation.region}
                            </option>
                          </select>
                          <label htmlFor="region">Region</label>
                        </div>
                      </div>
                      <div className="col-12 col-sm-6">
                        <div className="form-floating mb-3">
                          <select className="form-select" 
                             name="province"
                             id="province"
                             value={userInformation.province}
                             disabled
                             required>
                           <option value=''>
                            {userInformation.province}
                            </option>
              
                          </select>
                          <label htmlFor="province">Province</label>
                        </div>
                      </div>
                      <div className="col-12 col-sm-6">
                        <div className="form-floating mb-3">
                          <select className="form-select" 
                             name="city"
                             id="city"
                             value={userInformation.city}
                             disabled
                             required>
                           <option value=''>
                            {userInformation.city}
                            </option>
                          </select>
                          <label htmlFor="city">City / Municipal</label>
                        </div>
                      </div>
                      <div className="col-12 col-sm-6">
                        <div className="form-floating mb-3">
                          <select className="form-select" 
                             name='brgy'
                             id="brgy"
                             value={userInformation.brgy}
                             disabled
                             required>
                           <option value=''>
                            {userInformation.brgy}
                            </option>

                          </select>
                          <label htmlFor="brgy">Barangay</label>
                        </div>
                      </div>
                      <div className="col-12 col-sm-6">
                        <div className="form-floating mb-3">
                          <input
                            type="text"
                            className="form-control"
                            name="street"
                            id="street"
                            placeholder=""
                            value={userInformation.street}
                            disabled
                          />
                          <label htmlFor="street">Street</label>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-floating mb-3">
                          <textarea 
                            class="form-control" 
                            placeholder="Leave a comment here" 
                            id="landmark" 
                            name="landmark"
                            value={shippingForm.landmark}
                            onChange={handleFormChange}
                            style={{height: '100px'}}>
                          </textarea>
                          <label for="landmark">Landmark</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Submit Button */}
            <div className="col-12 d-flex justify-content-between modal-footer">
              <div className="total-cost">
                <h3>Total: ₱{(shippingFee+calculateTotalPrice()).toLocaleString()} </h3>
              </div>
              <div className="checkout-btn">
                {/* Remove type='submit' from button */}
                 <button className='btn btn-success' 
                    onClick={handlePurchase} 
                    disabled={!(shippingForm.payment) || isLoading}>
                  {isLoading && (
                    <span className="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true"></span>
                  )}
                  {isLoading ? 'Processing...' : 'Proceed Purchase'}
                </button>
              </div>
            </div>

          </div>
        </form>
      </section>
    </div>
  )
}

export default MyStorePurchaseForm  ;