import React, { useState, useEffect } from 'react';
import './AdminLogin.css';
import { FaEye, FaEyeSlash } from "react-icons/fa";
import LOGO from '../Assets/images/loyalista_logo(original).png'
import { Link, useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase/config';

const AdminLogin = () => {
    const [showpass, setShowPass] = useState(false);
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [rememberMe, setRememberMe] = useState(false);

    const navigate = useNavigate(); 
    const toggleShowPass = () => {
        setShowPass(prevState => !prevState);
    };

    const handleRememberMeChange = () => {
      setRememberMe(prevState => !prevState); 
    };

    const handleLogin = async(e) => {
      e.preventDefault();

      const enteredEmail = e.target.elements.email.value;
      const enteredPassword = e.target.elements.password.value;
      
      setTimeout(async () => {
        try {
          const userCredential = await signInWithEmailAndPassword(auth, enteredEmail, enteredPassword);
          
          if (userCredential.user.uid === 'CRfihczh2ENSjHlknMqjInNe6Wl2') {
            // Successfully logged in
            localStorage.setItem('token', userCredential.user.accessToken);
            localStorage.setItem('admin', JSON.stringify(userCredential.user));
            navigate('/MyAdmin');
          } else {
            setShowError(true);
          }
        } catch (error) {
          // Handle authentication error
          setShowError(true);
  
        }
      }, localStorage.getItem('token') ? 3000 : 2000); // 8 seconds for correct input, 2 seconds for incorrect input

    }
  return (
    <div className='wrapper' id='admin-login' >
      <div className="center-form">
        <form action="" className="form" onSubmit={handleLogin}>
          <div className="form-card -p-2">
            <div className="logo">
              <img src={LOGO} alt="" />
            </div>
            <div className="form-card-header">
              Log in as Administrator
              {showError && (
              <div className="alert alert-danger text-center mt-3">
                <h5 className='m-0'>{errorMessage}</h5>
              </div>
              )}
            </div>
            
            <div className="form-card-body">
              <div className="form-floating mb-3">
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  id="email"
                  placeholder=""
                  required
                />
                <label htmlFor="email">Email</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type={showpass ? 'text' : 'password'}
                  className="form-control"
                  name="password"
                  id="password"
                  placeholder=""
                  required
                />
                <label htmlFor="password">Password</label>
                <div className="showpass" >
                  {showpass ? (
                    <FaEyeSlash onClick={toggleShowPass}/>
                  ) : (
                    <FaEye onClick={toggleShowPass}/>
                  )}
                  
                </div>
              </div>
              <div className='d-flex justify-content-between'>
                <div className="form-check">
                  <input className="form-check-input" 
                         type="checkbox" 
                         value="" 
                         id="remember" 
                         name='remember' 
                         onClick={handleRememberMeChange}/>
                  <label className="form-check-label" htmlFor="remember"> Remember me. </label>
                </div>
                
                <Link to='/Admin/ForgotPass' className='text-white'>Forgot Password?</Link>
              </div>
              <div className="submit-btn">
                <button type='submit' className="btn btn-success">
                  Log in
                </button>
              </div>
              {/* <div className='text-center mt-3'>
                <Link to='/Admin/ChangePass' className='text-white'>Change Password</Link>
              </div> */}
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default AdminLogin