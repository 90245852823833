import React, { useState, useEffect } from 'react';
import './Product.css';
import Header2 from '../../Header/Header2';
import Footer from '../../Footer/Footer';
import { IoCartOutline } from "react-icons/io5";
import { FaRegEye } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { Pagination, message, Skeleton } from 'antd';
import { db, storage } from '../../../../firebase/config';
import { get, child, ref } from 'firebase/database';
import { getDownloadURL, listAll, ref as storageRef } from 'firebase/storage';
import { useCartStore } from '../../../../DataTest/cart-store';

const Products = () => {
    const [products, setProducts] = useState([]); // All products
    const [filteredProducts, setFilteredProducts] = useState([]); // Filtered products
    const [currentPage, setCurrentPage] = useState(1); // Pagination state
    const [itemsPerPage, setItemsPerPage] = useState(3); // Number of items per page
    const [selectedCategory, setSelectedCategory] = useState(""); // Selected category state
    const [uniqueCategories, setUniqueCategories] = useState([]); // Unique categories state
    const { cart, addToCart, setCart } = useCartStore((state) => ({
        cart: state.cart,
        addToCart: state.addToCart,
        setCart: state.setCart,
    }));

    useEffect(() => {
        const handleResize = () => {
            // Update itemsPerPage based on screen width
            if (window.innerWidth <= 768) {
                setItemsPerPage(1); // Set itemsPerPage to 1 for small devices
            } else {
                setItemsPerPage(3); // Set itemsPerPage to 3 for larger devices
            }
        };

        // Call handleResize when the window is resized
        window.addEventListener('resize', handleResize);

        // Initial call to handleResize
        handleResize();

        // Cleanup function to remove the event listener
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []); // Empty dependency array to run this effect only once during component mount


    // Calculate indexes for current page
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredProducts.slice(indexOfFirstItem, indexOfLastItem);

    const handleAddToCart = (selectedProduct) => {
        const existingOrderIndex = cart.findIndex((product) => product.id === selectedProduct.id);

        if (existingOrderIndex !== -1) {
            const updatedCart = cart.map((product, index) => {
                if (index === existingOrderIndex) {
                    return { ...product, quantity: product.quantity + 1 };
                } else {
                    return product;
                }
            });
            setCart(updatedCart);
        } else {
            const dataFormat = { ...selectedProduct, quantity: 1 };
            addToCart(dataFormat);
        }
        message.success(`${selectedProduct.name} is added to your cart.`);
    };

    // Change page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const snapshot = await get(child(ref(db), 'products'));
                if (snapshot.exists()) {
                  const productsData = snapshot.val();
                  const productsArray = Object.values(productsData);
            
                  const productsWithImages = await Promise.all(
                    productsArray.map(async (product) => {
                      const imagesListRef = storageRef(storage, `products/${product.id}/`);
                      try {
                        const images = await listAll(imagesListRef);
                        const imageUrls = await Promise.all(
                          images.items.map(async (imageRef) => {
                            try {
                              const imageUrl = await getDownloadURL(imageRef);
                              return { url: imageUrl, name: imageRef.name };
                            } catch (error) {
                              console.error('Error fetching image:', error);
                              return null;
                            }
                          })
                        );
            
                        const filteredImageUrls = imageUrls.filter((item) => item !== null);
                        return { ...product,image: filteredImageUrls[0].url , images: filteredImageUrls };
                      } catch (error) {
                        console.error('Error listing images:', error);
                        return { ...product, images: [] };
                      }
                    })
                  );
            
                  const sortedProductsWithImages = productsWithImages.map((product) => {
                    if (product.category === 'Package' && product.commissions) {
                      const sortedCommissions = {};
                      Object.keys(product.commissions)
                        .sort((a, b) => parseInt(a.replace('level', '')) - parseInt(b.replace('level', '')))
                        .forEach((key) => {
                          sortedCommissions[key] = product.commissions[key];
                        });
                      return { ...product, commissions: sortedCommissions };
                    }
                    return product;
                  });
    
                    setProducts(sortedProductsWithImages);
                    setFilteredProducts(sortedProductsWithImages);
                    const categories = [...new Set(sortedProductsWithImages.map(product => product.category))];
                    setUniqueCategories(categories);
                } else {
                    alert('No data available');
                }
            } catch (error) {
                console.error(error);
            }
        };
    
        fetchProducts();
    }, []);
    

    // Filter products by category when selected category changes
    useEffect(() => {
        if (selectedCategory === "") {
            setFilteredProducts(products);
        } else {
            const filtered = products.filter(product => product.category === selectedCategory);
            setFilteredProducts(filtered);
        }
        // Reset current page to 1 when category selection changes
        setCurrentPage(1);
    }, [selectedCategory, products]);

    // Handle category selection change
    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value);
    };

    return (
        <>
        <Header2/>
        <section className="section" id="product">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 header">
                        <div className="section-heading">
                            <h2>Our Products</h2>
                            <span>Check out all of our products.</span>
                        </div>
                        <div className="section-selection form-floating">
                            <select className="form-select" onChange={handleCategoryChange} value={selectedCategory}>
                                <option value="">All Categories</option>
                                {uniqueCategories.map((category, index) => (
                                    <option key={index} value={category}>{category}</option>
                                ))}
                            </select>
                            <label htmlFor="floatingSelect">Select Category</label>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row" id='product-rows'>
                    {products.length === 0 ? (
                        Array.from({ length: itemsPerPage }).map((_, index) => (
                            <div className="col-lg-4 mb-3" key={index}>
                                <div className="d-flex flex-column gap-3 "  >
                                    <Skeleton.Image active style={{width: '250px', height: '250px'}} />
                                    <Skeleton active paragraph={{ rows: 1 }} style={{width: '250px'}}/>
                                </div>
                            </div>
                        ))
                    ) : (
                        currentItems.map((product) => (
                            <div className="col-lg-4" key={product.id} id='products'>
                                <div className="item">
                                    <div className="thumb">
                                        <div className="hover-content">
                                            <ul>
                                                <li>
                                                    <button className='btn btn-link'>
                                                        <Link to={`/Products/${product.id}`}>
                                                            <FaRegEye style={{ fontSize: '20px' }} />
                                                        </Link>
                                                    </button>
                                                </li>
                                                <li>
                                                    <button className='btn btn-link' onClick={() => handleAddToCart(product)}>
                                                        <IoCartOutline style={{ fontSize: '20px' }} />
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                        <img src={product.images[0].url} alt={product.name} />
                                    </div>
                                    <div className="down-content">
                                        <h4>{product.name}</h4>
                                        <span>₱ {product.price}.00</span>
                                    </div>
                                </div>
                            </div>
                        ))
                    )}
                    <div className="col-lg-12" style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                        <Pagination
                            current={currentPage}
                            total={filteredProducts.length}
                            pageSize={itemsPerPage}
                            onChange={paginate}
                            center
                        />
                    </div>
                </div>
            </div>
        </section>
        <Footer/>
        </>
    );
}

export default Products;
