import React, { useState } from 'react'
import LOGO from '../../../../Assets/images/loyalista_logo(original).png';
import Icon_forgot from '../../../../Assets/images/icon-forgotpass.png';
import { Link } from 'react-router-dom'
import { GrFormPreviousLink } from "react-icons/gr";
import { IoIosSend } from "react-icons/io";
import { message } from 'antd';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../../../../firebase/config';

const ForgotPassword = () => {
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [email, setEmail] = useState('');
    const handleSend = () => {
        sendPasswordResetEmail(auth, email)
        message.success("Send Successfully. We've sent an email to your email.")
    }  

 return (
    <>
        <div id='forgotpass'>
            <div className="header">
                <div className="logo-container container">
                    <img src={LOGO} alt="" />
                </div>
            </div>
            <section className="center container">
                <div className="card">
                    <div className="card_header p-3 d-flex align-items-center justify-content-start">
                        <Link to='/'><GrFormPreviousLink style={{fontSize: '17px'}}/> Back to Home Page</Link>
                    </div>
                    <div className="card-body">
                        <div className="icon-forgotpass">
                            <img src={Icon_forgot} alt="" />
                        </div>
                        <span>Forgot Password</span>
                        <p className='text-muted'>Enter your email to reset your password.</p>
                         {/* {showError && ( */}
                         <div className="text-danger mb-4" style={{fontWeight: '600', textAlign: 'center'}}>
                                {errorMessage} Email not found! <br/> Please make you enter the registered email and try again.
                            </div>
                        {/* )} */}
                        <form action="">
                            <div className="form-floating mb-3">
                                <input
                                type="email"
                                className="form-control"
                                name="email"
                                id="email"
                                placeholder=""
                                onChange={(e)=>setEmail(e.target.value)}
                                />
                                <label htmlFor="email">Email</label>
                            </div>
                        </form>
                    </div>
                    <div className="card_footer d-flex justify-content-center align-items-center mb-5">
                        <button className="btn btn-success" onClick={handleSend}><IoIosSend/> Send</button>
                    </div>
                </div>
            </section>
        </div>
    </>
  )
}

export default ForgotPassword;
