import React, { useState, useEffect, useRef } from 'react';
import './OrderHistory.css';
import { FaDownload } from "react-icons/fa6";
import { IoSearch } from "react-icons/io5";
import { message, Pagination } from 'antd';
import { CSVLink } from 'react-csv';
import NoDataFound from '../../../Assets/no-data/NoDataFount';
import { useAuth } from '../../../contexts/authContext';
import { Navigate } from 'react-router-dom';
import moment from 'moment';
import { db } from '../../../firebase/config';
import { get, ref } from 'firebase/database';

const OrderHistory = () => {
  const { userLoggedIn } = useAuth();
  const [csvData, setCsvData] = useState([]);
  const csvHeaders = ['Order ID', 'Date Order', 'Customer', 'Items', 'Total Amount', 'Status'];
  const csvLinkRef = useRef(null);
  const user = JSON.parse(localStorage.getItem('user'));
  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-PH', { style: 'currency', currency: 'PHP' }).format(value);
  };
  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 5;

  const calculateTotalAmount = (order) => {
    return order.items ? order.items.reduce((total, item) => total + item.quantity * item.price, 0) : 0;
  };

  useEffect(() => {
    const fetchOrderItems = async () => {
      try {
        const ordersRef = ref(db, 'orders');
        const snapshot = await get(ordersRef);

        if (snapshot.exists()) {
          const orderItemsArray = Object.entries(snapshot.val())
            .filter(([orderId, orderData]) => orderData.user === user.uid)
            .map(([orderId, orderData]) => ({
              orderId: orderId,
              ...orderData,
              items: orderData.items || [], // Ensure items is always an array
            }))
            .sort((b, a) => b.timestamp - a.timestamp);

          const formattedData = orderItemsArray.map((order) => ({
            orderID: order.orderId,
            dateOrder: moment(order.timestamp).format('MMM. DD, YYYY'),
            customer: order.customer?.Fullname,
            items: order.items.map((product) => ({
              product: product.name,
              quantity: product.quantity
              
            })),
            total: formatCurrency(parseInt(order.total).toFixed(2)),
            status: order.status,
          }));
          setTableData(formattedData);
          setFilteredProducts(formattedData);
        } else {
          setTableData([]);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchOrderItems();
  }, []);

  const handleDownload = () => {
    const formattedCsvData = [
      csvHeaders,
      ...tableData.map(order => [
        order.orderID,
        order.dateOrder,
        order.customer,
        order.items.map(item => `${item.product} (${item.quantity}x)`).join(', '),
        `₱ ${calculateTotalAmount(order)}.00`,
        order.status
      ])
    ];
    setCsvData(formattedCsvData);
  };

  const productColors = {
    'Infin8 Pure Organic Barley': 'var(--product-one)',
    'Package 1': 'var(--product-two)',
    'Package 2': 'var(--product-three)',
    'Package 3': 'var(--product-four)',
    'Product Five': 'var(--product-five)',
    // Add more colors for other products if needed
  };

  const [searchQuery, setSearchQuery] = useState('');
  const [filteredProducts, setFilteredProducts] = useState([]);

  useEffect(() => {
    const filtered = tableData.filter(order =>
      order.orderID?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      order.customer?.toLowerCase().includes(searchQuery.toLowerCase())
    );

    setFilteredProducts(filtered);
    setCurrentPage(1);
  }, [searchQuery, tableData]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const displayedProducts = filteredProducts.slice(startIndex, endIndex);

  if (!userLoggedIn) {
    return <Navigate to={'/login'} replace={true} />;
  }

  return (
    <main id='orderHistory'>
      <div className="head-title">
        <div className="left">
          <h1>Order History</h1>
        </div>
        <div className="right">
          <CSVLink
            onClick={handleDownload}
            ref={csvLinkRef}
            data={csvData}
            filename={'order_lists.csv'}
            className="btn btn-primary"
          >
            <FaDownload /> Download Table
          </CSVLink>
        </div>
      </div>
      <div className="order-lists">
        <div className="header">
          <div className="row justify-content-between align-items-center">
            <div className="col-12 col-lg-9 col-md-7"><h4>Orders List</h4></div>
            <div className="col-12 col-lg-3 col-md-5">
              <div className="search-bar">
                <IoSearch className='search-icon' />
                <input
                  type="search"
                  className='form-control'
                  placeholder='Search here...'
                  onChange={handleSearchChange}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="table-data">
          {filteredProducts.length === 0 ? (
            <NoDataFound />
          ) : (
            <div className='table-container'>
              <table>
                <thead>
                  <tr>
                    <th>Order ID</th>
                    <th>Date Order</th>
                    <th>Customer</th>
                    <th>Items</th>
                    <th>Total Amount</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {displayedProducts.map((order) => (
                    <tr key={order.orderID}>
                      <td style={{ width: '13%' }} data-cell='Order ID'>{order.orderID}</td>
                      <td style={{ width: '12%' }} data-cell='Date Order'>{order.dateOrder}</td>
                      <td style={{ width: '23%' }} data-cell='Customer'>{order.customer}</td>
                      <td id='items-holder' colSpan={2} data-cell='Items'>
                        {order.items.map((item, index) => (
                          <span key={index} className='items-tag' style={{ backgroundColor: productColors[item.product] }}>
                            {item.product} ({item.quantity}x)
                          </span>
                        ))}
                      </td>
                      <td style={{ width: '13%' }} data-cell='Total Amount'>{order.total}</td>
                      <td style={{ width: '8%' }} data-cell='Status'><span className={`status ${order.status}`}>{order.status}</span></td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="pagination-container">
                <Pagination
                  current={currentPage}
                  total={filteredProducts.length}
                  onChange={handlePageChange}
                  pageSize={pageSize}
                  showSizeChanger={false}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </main>
  );
};

export default OrderHistory;
