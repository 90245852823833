import React, { useState, useEffect, useRef } from 'react'
import './OrdersList.css'
import { IoSearch } from "react-icons/io5";
import { Pagination, message, Popconfirm } from 'antd';
import SampleProduct from '../../../DataTest/SampleProducts';
import NoDataFount from '../../../Assets/no-data/NoDataFount';
import { get, ref, update } from 'firebase/database';
import {db} from '../../../firebase/config'
import moment from 'moment'
const OrdersList = () => {
  const [loading, setLoading] = useState(false); 
  const [openApproved, setOpenAproved] = useState(false);
  const [openCancel, setOpenCancel] = useState(false);
  const [OrderData, setOrderData] = useState([])
  const [dependencyKey, setDependencyKey] = useState(0)
  const formatCurrency = (value) => {
      return new Intl.NumberFormat('en-PH', { style: 'currency', currency: 'PHP' }).format(value);
    };
  const [currentPage, setCurrentPage] = useState(1); // State for current page
  const pageSize = 10; // Number of items per page

  // search functionality
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null); // State for selected order

  // Filter the products whenever the search query changes


  useEffect(() => {
    const fetchOrderItems = async () => {
      try {
        const ordersRef = ref(db, 'orders');
        const snapshot = await get(ordersRef);

        if (snapshot.exists()) {
          const orderItemsArray = Object.entries(snapshot.val())
          .filter(([orderId, orderData]) => orderData.status === 'Pending')
            .map(([orderId, orderData]) => ({
              orderId: orderId,
              ...orderData,
              items: orderData.items || [], // Ensure items is always an array
            }))
            .sort((b, a) => b.timestamp - a.timestamp);

          const formattedData = orderItemsArray.map((order) => ({
            orderID: order.orderId,
            dateOrder: moment(order.timestamp).format('MMM. DD, YYYY'),
            customer: order.customer?.Fullname,
            email: order.customer?.Email,
            contact: order.customer?.ContactNum,
            address:order.address?.fullAddress,
            seller: order.seller,
            shippingFee: order.invoice.AmountDue.Shipping,
            PaymentMethod:order.invoice.PaymentMethod,
            items: order.items.map((product) => ({
              productId: product.productId,
              name: product.name,
              quantity: product.quantity,
              price: product.price,
              TotalPrice: product.price * product.quantity,
              barleyCommission:product.barleyCommission,
              commissions: product.commissions || null,
              image:product.image,
              category:product.category
              
            })),
            total: formatCurrency(parseInt(order.total).toFixed(2)),
            status: order.status,
            totalcost:order.total + order.invoice.AmountDue.Shipping
          }))
          setOrderData(formattedData);
          setFilteredProducts(formattedData);
        } else {
            setOrderData([]);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchOrderItems();
  }, [loading, selectedOrder]);

  useEffect(() => {
    const filtered = OrderData.filter(
        (order) =>
            order.orderID?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            order.customer?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            order.seller?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            order.PaymentMethod?.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredProducts(filtered);
    // Reset current page to 1 when search query changes
    setCurrentPage(1);
  }, [searchQuery]);

  const handleSearchChange = (e) => {
      setSearchQuery(e.target.value);
  };

  const handlePageChange = (page) => {
      setCurrentPage(page);
  };

  // Function to handle selecting an order
  const handleOrderSelect = (order) => {
      setSelectedOrder(order);
  };

  // Calculate the start and end index of the products to display based on current page and page size
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const displayedProducts = filteredProducts.slice(startIndex, endIndex);

  // Function to calculate subtotal for each item
  const totalPrice = (item) => {
    return parseInt(item.quantity) * parseInt(item.price);
  };
  // Function to calculate total amount for all purchased items
  const subTotal = () => {
    if (!selectedOrder) return 0;
    return selectedOrder.items.reduce((total, item) => total + totalPrice(item), 0);
  };

  // Function to calculate total amount including shipping fee
  const totalAmount = () => {
    if (!selectedOrder) return 0;
    return subTotal() + selectedOrder.shippingFee;
  };

  const handleCancel = () => {
    setOpenAproved(false);
    setOpenCancel(false);
  }
  const showApprove = () => {
    setOpenAproved(true);
  };

  const showCancel = () => {
    setOpenCancel(true);
  }

  const handleApproveOrder = async(orderID) => {
    setLoading(true);
    const updates = {};
    const action = 'Approved'
    updates['/orders/' + orderID + '/status'] = action;
    setTimeout(() => {
      message.success(`Order No. ${orderID} has been approved!`);
      setLoading(false);
      setOpenAproved(false);
    }, 2000);
    const orderData = selectedOrder
    return update(ref(db), updates).then(async()=>{
      const subject = `Your place order ID ${orderID} has been approved.`
            const email = selectedOrder.email
            const response = await fetch('https://loyalista-mailer-aa5b8a82053e.herokuapp.com/OrderApproved', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ email, orderData , subject }),
            });
            if (response.ok) {   
              setSelectedOrder(null)
            } else {
              console.error('Failed to send email');
            }
    })
  };

  const handleCancelOrder = async(orderID) => {
    setLoading(true);
    const updates = {};
    const action = 'Declined'
    updates['/orders/' + orderID + '/status'] = action;
    setTimeout(() => {
      message.warning(`Order No. ${orderID} has been successfully cancelled!`);

      // Set loading state back to false after action is completed
      setLoading(false);
      setOpenCancel(false);
    }, 2000);
    const orderData = selectedOrder
    return update(ref(db), updates).then(async()=>{
      const subject = `Your place order ID ${orderID} has been approved.`
            const email = selectedOrder.email
            const response = await fetch('https://loyalista-mailer-aa5b8a82053e.herokuapp.com/OrderDeclined', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ email, orderData , subject }),
            });
            if (response.ok) {   
              setSelectedOrder(null)
              message.warning(`Order No. ${orderID} has been successfully cancelled!`);
            } else {
              alert('Failed to send email');
            }
    })
  };

  return (
    <main id='ordersList'>
      <div className="head-title">
        <div className="left">
            <h1>Orders List</h1>
        </div>
      </div>
      
      <div className="table-data">

        <div className="orders">
          <div className='head'>
            <h5>Orders</h5>
            <div className="search-bar">
              <IoSearch className='bx'/>
              <input type="search" className='form-control'
                    placeholder='Search here...'
                    style={{width: '320px', paddingLeft: '35px'}}
                    onChange={handleSearchChange}
              />
            </div>
            
          </div>
          {filteredProducts.length === 0 ? (
            <NoDataFount/>
          ) : (
          <div className='order-table'>
            <table>
              <thead>
                <tr>
                  <th>Seller Name</th>
                  <th>Order No.</th>
                  <th>Date Ordered</th>
                  <th>Buyer Name</th>
                </tr>
              </thead>
              <tbody>
              {displayedProducts.map((order) => (
                <tr key={order.orderID} 
                    onClick={() => handleOrderSelect(order)}
                    className={selectedOrder && selectedOrder.orderID === order.orderID ? 'active' : ''}
                >
                    <td>{order.seller}</td>
                    <td>{order.orderID}</td>
                    <td>{order.dateOrder}</td>
                    <td>{order.customer}</td>
                </tr>
              ))}
              </tbody>
            </table>
            <div className="pagination-container">
              <Pagination
                  current={currentPage}
                  total={filteredProducts.length}
                  onChange={handlePageChange}
                  pageSize={pageSize}
                  showSizeChanger={false}
              />
            </div>
          </div>
          )}
          
        </div>
        {selectedOrder && (
        <div className="order-info">
          
          <div className='head'>
            <h5>Order No.: <b>{selectedOrder.orderID}</b></h5>
          </div>
        
          <div className="item-table">
            <table>
              <caption>Purchased Products</caption>
              <thead>
                <tr>
                  <th>Product ID</th>
                  <th>Product Name</th>
                  <th>Price</th>
                  <th>Quantity</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {selectedOrder.items.map((item) => (
                <tr key={item.productId}>
                  <td>{item.productId}</td>
                  <td>{item.name}</td>
                  <td>₱ {parseInt(item.price).toFixed(2)}</td>
                  <td>{item.quantity}</td>
                  <td>₱ {totalPrice(item).toFixed(2)}</td>
                </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="shipping-info">
            <div className="head mt-3">
              <h5>Shipping Info</h5>
            </div>
            <div className="shipping-table">
              <table className='table'>
                <tr>
                  <th>Buyer Name:</th>
                  <td>{selectedOrder.customer}</td>
                </tr>
                <tr>
                  <th>Email Address:</th>
                  <td>{selectedOrder.email}</td>
                </tr>
                <tr>
                  <th>Contact Number:</th>
                  <td>{selectedOrder.contact}</td>
                </tr>
                <tr>
                  <th colSpan={1}>Shipping Address:</th>
                  <td colSpan={2}>{selectedOrder.address}</td>
                </tr>
                <tr>
                  <th style={{paddingTop: '15px'}}>Payment Method:</th>
                  <td style={{paddingTop: '15px'}}>Via {selectedOrder.PaymentMethod}</td>
                </tr>
                <tr>
                  <th>Subtotal:</th>
                  <td>₱ {subTotal().toFixed(2)}</td>
                </tr>
                <tr>
                  <th>Shipping Fee:</th>
                  <td>₱ {parseInt(selectedOrder.shippingFee).toFixed(2)}</td>
                </tr>
                <tr>
                  <th>Total Amount:</th>
                  <td>₱ {totalAmount().toFixed(2)}</td>
                </tr>
              </table>
            </div>
            <div className='action-btn'>
              
                <div className="right">
                <Popconfirm
                    title="Are you sure to approve this order?"
                    onConfirm={() => handleApproveOrder(selectedOrder.orderID)}
                    open={openApproved}
                    onCancel={handleCancel}
                    okButtonProps={{ loading: loading }}
                >
                  <button className='btn btn-primary' onClick={showApprove}>
                    Approve
                  </button>
                </Popconfirm>
                <Popconfirm
                    title="Are you sure to cancel this order?"
                    onConfirm={() => handleCancelOrder(selectedOrder.orderID)}
                    open={openCancel}
                    onCancel={handleCancel}
                    okButtonProps={{ loading: loading }}
                >
                  <button className="btn btn-danger" onClick={showCancel}>
                    Decline
                  </button>
                </Popconfirm>
                </div>
              </div>
          </div>
 
          
        </div>
        )}
      </div>
    </main>
  )
}

export default OrdersList;
