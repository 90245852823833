import React from 'react';
import './TermsandCondition.css';
import Header2 from '../../Header/Header2';
import Footer from '../../Footer/Footer';

const TermsandConditions = () => {
  return (
    <>
      <Header2 />
      <section id='terms' className='terms-container'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-4 left-section'>
              <div className='header'>
                <h2>Terms and Conditions</h2>
                <div className='footer'>
                  <span>
                  I hereby certify that the information are true and correct to the best of my knowledge. In case of any violation of the terms and ons herein stipulated, I hereby agree and authorize LOYALISTA ONLINE SHOP to revoke. deactivate, and/or suspend my privileges as an indent Distributor without prejudice to any charges, criminal and/or civil, that LOYALISTA ONLINE SHOP may charge against me, without limitation to any false information I have provided herein.
                  </span>
                  <span>
                  I fully understand and further confirm this authority that I am conferring LOYALISTA ONLINE SHOP to do so is with my own free will and violation. without any force, intimidation or undue influence employed upon me. 
                  </span>
                </div>
              </div>
              
            </div>
            <div className='col-lg-8 right-section'>
              <div className='content'>
                <h4>As a Member of Loyalista Online Shop</h4>
                <p>
                  Please carefully review the following terms and conditions
                  that govern your membership with Loyalista Online Shop. By
                  becoming a member, you agree to be bound by these terms.
                </p>
                <div className="text-content">
                  <ul>
                    <li>I am of legal age upon entering into this Distributorship Agreement, otherwise. I will submit a parental consent waiver together with this agreement.</li>
                    <li>This application shall constitute a binding agreement between me and LOYALISTA ONLINE SHOP upon receipt and due approval of the Company.</li>
                    <li>I fully understand that this Distributorship Agreement is personal in nature and is not transferrable nor assignable except in the event of my death, where law on intestate succession applies, however. subject to existing Company's policy on qualification as Independent Distributor:</li>
                    <li>I attest that I personally participated and was personally presented with the LOYALISTA ONLINE SHOP Business Presentation and Product Orientation prior to selling the LOYALISTA ONLINE SHOP products:</li>
                    <li>I hereby agree that I am entitled to all rebates commissions and other incentives provided that I maintain the monthly personal purchase required by the company.</li>
                    <li>I am aware that refunds will not be provided for any packages.</li>
                    <li>I shall be eligible for sales bonuses. discounts, and privileges that may be granted by the company relative to my sales performance or successful delivery of products. Relevant to my sales activity. I further understand that sales bonuses, rebates and/or commissions are in accordance with the compensation scheme established in the LOYALISTA ONLINE SHOP Marketing Plan. provided, that I have achieved such sales performance in good faith, and that I have not violated any of the provisions of the Distributorship Agreement, Code of Ethics, and Company Policies:</li>
                    <li>I am aware that all commissions are subject to 10% withholding tax and PhP100 processing fee.</li>
                    <li>This Distributorship Agreement limits a no employee - employer relationship between me and LOYALISTA ONLINE SHOP; neither that I may claim to be a legal representative of LOYALISTA ONLINE SHOP nor bind LOYALISTA ONLINE SHOP in any agreements other than those stipulated herein: </li>
                    <li>As Independent Distributor. I am bound to abide by the Company's rules and regulations and faithfully uphold the Code of Conduct and Ethical Standard. Should I commit any form of violation and be subjected to the necessary sanctions and penalties as provided in the Code of Conduct and Ethical Standard. I voluntarily release LOYALISTA ONLINE SHOP from any liabilities these sanctions and penalties may cause consequently. </li>
                    <li>I understand that I should secure an express written approval from the management of LOYALISTA ONLINE SHOP or any of its authorized representatives prior to making any form of advertisement in mainstream media, social networking sites. internet, and online media, including. but not limited to audio, visual, and printed materials, other than the Company's existing advertisement and marketing materials and postings on its official website and official social media pages: </li>
                    <li>I am aware that making any misrepresentations, revisions, modifications. or alterations of the Company's trademark, brand, logos, marketing plan, products, advertisement, marketing materials, and other company provided marketing tools. is strictly prohibited. Otherwise. it shall be deemed violation of the Company's rules, regulations, and policies: </li>
                    <li>As a general rule, should my spouse join LOYALISTA ONLINE SHOP as an Independent Distributor, he/she shall automatically be under my sales team. Otherwise, this shall constitute violation of the Cross-lining provision as defined on the Code of and Ethical Standard. </li>
                    <li>I affirm to exclusively join the sponsor I personally indicated in this Application Form or adhere to the "six-month-rule” with no activities, no sales in the event I decided to sign-up to another sponsor line. </li>
                    <li>I shall diligently settle to the designated government agency/local government unit all due taxes from the sales bonuses I have earned from LOYALISTA ONLINE SHOP. </li>
                    <li>Fully aware that the LOYALISTA ONLINE SHOP reserves the right to modify, revise, and update its existing policies and business plan for the interest of the company and its Distributors with 30 days prior notice:</li>
                    <li>I conform to conduct all my sales activities in accordance with the existing laws of the Republic of the Philippines and release LOYALISTA ONLINE SHOP from any liability arising from my own personal actions: </li>
                    <li>Upload, post, transmit or otherwise make available any Content that is unlawful, harmful, threatening, abusive, harassing, alarming, distressing, tortuous, defamatory, vulgar, obscene, libelous, invasive of another's privacy, hateful, or racially, ethnically or otherwise objectionable against the company at any social media platform will subject to penalty amounting 100,000 pesos (first offense) 500,000 pesos (second offense), 1million third offense) deductible on distributor’s commission 50% every withdrawal.</li>
                    <li>Cross lining may subject for penalty amounting to 100,000 pesos. </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-12 d-flex justify-content-center">
            
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default TermsandConditions;

