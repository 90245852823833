import React, { useEffect, useState } from 'react';
import './Genealogy.css';
import { child, get, ref } from 'firebase/database';
import { db } from '../../../firebase/config';
import { useAuth } from '../../../contexts/authContext';
import { Navigate } from 'react-router-dom';
import Tree from 'react-d3-tree'; // Import react-d3-tree

const Genealogy = () => {
    const [chartData, setChartData] = useState(null);
    const credentials = JSON.parse(localStorage.getItem('user'));
    const { userLoggedIn } = useAuth();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const snapshot = await get(child(ref(db), 'users'));
                if (snapshot.exists()) {
                    const userData = snapshot.val();
                    const userArray = Object.values(userData);

                    const buildTree = (username, level) => {
                        const userNode = userArray.find(user => user.username === username);
                        if (!userNode) return null;

                        const children = userArray
                            .filter(user => user.referral === username)
                            .map(user => buildTree(user.username, level + 1));

                        return {
                            name: userNode.username,
                            attributes: {
                                status: userNode.status,
                                level: level // Ensure level is correctly assigned
                            },
                            children: children.length ? children : undefined,
                        };
                    };

                    const rootUser = userArray.find(user => user.email === credentials.email);
                    const chartDataFromFirebase = buildTree(rootUser.username, 0);
                    setChartData(chartDataFromFirebase);
                } else {
                    alert('No data available');
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [credentials.email]);

    if (!userLoggedIn) return <Navigate to={'/login'} replace={true} />;

    return (
        <main id="genealogy">
            <div className="head-title">
                <div className="left">
                    <h1>Genealogy Chart</h1>
                </div>
            </div>

            <div className="chart-data">
            
                <div className="genealogy-tree">
                    {chartData && (
                        <Tree
                            data={chartData}
                            width={800}
                            height={600}
                            nodeRadius={null}
                            svgProps={{
                                className: 'custom',
                                transform: 'rotate(90)',
                            }}
                            renderCustomNode={(nodeData) => (
                                <foreignObject width={100} height={50}>
                                    <div>
                                        <div>{nodeData.name}</div>
                                        <div>Level: {Number(nodeData.attributes.level)}</div> {/* Ensure this is correct */}
                                    </div>
                                </foreignObject>
                            )}
                            pathFunc="step"
                            separation={{ siblings: 2, nonSiblings: 2 }}
                            orientation="vertical"
                            translate={{ x: 900, y: 100 }}
                            allowForeignObjects={true}
                            initialDepth={0.02}
                        />
                    )}
                </div>

            </div>
        </main>
    );
};

export default Genealogy;
