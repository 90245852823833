import React, { useEffect, useState } from 'react';
import './Resetpass.css';
import LOGO from '../../../../Assets/images/loyalista_logo(original).png';
import Icon_forgot from '../../../../Assets/images/icon-forgotpass.png';
import { Link, useSearchParams } from 'react-router-dom';
import { GrFormPreviousLink } from "react-icons/gr";
import Swal from 'sweetalert2';
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import { confirmPasswordReset } from 'firebase/auth';
import { message } from 'antd';
import { auth } from '../../../../firebase/config';

const ResetPass = () => {
    const [showNewpass, setShowNewPass] = useState(false);
    const [showConfirmpass, setConfirmShowPass] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();
    const oobCode = searchParams.get('oobCode');
    useEffect(()=>{
       if(!oobCode) {
            navigate('/Link-Not-Found');
       } 
    },[])
    const toggleShowPass1 = () => {
        setShowNewPass(prevState => !prevState);
    };

    const toggleShowPass2 = () => {
        setConfirmShowPass(prevState => !prevState);
    };

    const handleSend = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const password = e.target.elements.password.value;
        const confirmPassword = e.target.elements.confirmpassword.value;
    
        if (password !== confirmPassword) {
            message.error('Password not Match!');
            setIsLoading(false);
            return;
        }
    
        try {
            if (oobCode) {
                await confirmPasswordReset(auth ,oobCode, confirmPassword);
                setTimeout(() => {
                    setIsLoading(false);
                    Swal.fire({
                        title: 'Reset Password',
                        text: 'Your new Password has been saved!',
                        icon: 'success',
                        confirmButtonText: 'Login'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            navigate('/Login');
                        }
                    });
                }, 2000);
            } else {
                message.error('Something is wrong; try again later!');
                navigate('/Link-Not-Found');
            }
        } catch (error) {
            if (error.code === 'auth/invalid-action-code') {
                alert('This Link is Expired');
                navigate('/');
                setIsLoading(false);
            }
            console.log(error.message);
        }
    };

    return (
        <div id='resetPass'>
            <div className="header">
                <div className="logo-container container">
                    <img src={LOGO} alt="" />
                </div>
            </div>
            <section className="center container">
                <div className="card">
                    <div className="card_header p-3 d-flex align-items-center justify-content-start">
                        <Link to='/'><GrFormPreviousLink style={{fontSize: '17px'}} /> Back to Home Page</Link>
                    </div>
                    <div className="card-body">
                        <div className="icon-forgotpass">
                            <img src={Icon_forgot} alt="" />
                        </div>
                        <span>Reset Password</span>
                        <p className='text-muted'>Enter your new password.</p>

                        {showError && (
                            <p className="errorText text-danger">
                                {errorMessage}
                            </p>
                        )}
                        <form onSubmit={handleSend}>
                            <div className="form-floating mb-3">
                                <input
                                    type={showNewpass ? 'text' : 'password'}
                                    className="form-control"
                                    name="password"
                                    id="password"
                                    placeholder=""
                                />
                                <label htmlFor="password">New Password</label>
                                <div className="showNewpass">
                                    {showNewpass ? (
                                        <FaEyeSlash onClick={toggleShowPass1} />
                                    ) : (
                                        <FaEye onClick={toggleShowPass1} />
                                    )}
                                </div>
                            </div>
                            
                            <div className="form-floating mb-3">
                                <input
                                    type={showConfirmpass ? 'text' : 'password'}
                                    className="form-control"
                                    name="confirmpassword"
                                    id="confirmpassword"
                                    placeholder=""
                                />
                                <label htmlFor="confirmpassword">Confirm Password</label>
                                <div className="showConfirmpass">
                                {showConfirmpass ? (
                                    <FaEyeSlash onClick={toggleShowPass2} />
                                ) : (
                                    <FaEye onClick={toggleShowPass2} />
                                )}
                            </div>
                            </div>
                            
                            <div className="card_footer d-flex justify-content-center align-items-center mb-5">
                                <button className="btn btn-success" type="submit" disabled={isLoading}>
                                    {isLoading && (
                                        <span className="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true"></span>
                                    )}
                                    {isLoading ? ' Changing Password...' : 'Change Password'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default ResetPass;
