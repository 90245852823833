import React, { useEffect, useState } from 'react';
import './LayoutStyle.css';
import { Modal, Upload, message } from 'antd';
import ImgCrop from 'antd-img-crop';
import logo from '../Assets/images/loyalista_logo(original).png';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { FiMenu } from "react-icons/fi";
import { AiFillDashboard } from "react-icons/ai";
import { FaStore, FaExternalLinkAlt, FaHistory, FaWallet } from "react-icons/fa";
import { BsPersonFillAdd } from "react-icons/bs";
import { IoMdLogOut } from "react-icons/io";
import { GiOrganigram } from "react-icons/gi";
import defaultProfile from '../Assets/images/instagram-01.jpg';
import { EmailAuthProvider, getAuth, reauthenticateWithCredential, signOut, updatePassword } from 'firebase/auth';
import { useCartStore } from '../DataTest/cart-store';
import { deleteObject, getDownloadURL, listAll, ref as storageRef, uploadBytes } from 'firebase/storage';
import { auth, db, storage } from '../firebase/config';
import { get, ref, update } from 'firebase/database';
import MyProfile from './Pages/Profile Setting/MyProfile';

function ClientApp() {
    const [showProfile, setShowProfile] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [profileImage, setProfileImage] = useState(defaultProfile);
    const [isLoading, setIsLoading] = useState(false);
    const [hideSidebar, setHideSidebar] = useState(false);
    const [activeMenu, setActiveMenu] = useState(0);

    const navigate = useNavigate();
    const user = JSON.parse(localStorage.getItem('user'));

    const { setCart } = useCartStore((state) => ({
        setCart: state.setCart,
    }));

    useEffect(() => {
        const fetchProfile = async () => {
            const imageExtensions = ['png', 'jpg', 'jpeg'];

            for (const extension of imageExtensions) {
                const imageRef = storageRef(storage, `users/${user.uid}/profile/profile.${extension}`);
                try {
                    setFileList([{
                        uid: user.uid,
                        name: `profile.${extension}`,
                        status: 'done',
                        url: await getDownloadURL(imageRef),
                    }]);
                    setProfileImage(await getDownloadURL(imageRef));
                    break;
                } catch (error) {
                    console.error("Error fetching profile image: ", error);
                }
            }
        };
        fetchProfile();
    }, [user.uid]);

   

    const handleShowProfile = () => {
        setShowProfile(true);
    };

    const handleCloseProfile = () => {
        setShowProfile(false);
    };

    const handleMenu = () => {
        setHideSidebar((prevState) => !prevState);
    };

    const handleModeChange = () => {
        const switchMode = document.getElementById('switch-mode');
        if (switchMode.checked) {
            document.body.classList.add('dark');
        } else {
            document.body.classList.remove('dark');
        }
    };

    const handleActiveMenu = (index) => {
        setActiveMenu(index);
        localStorage.setItem('active', index);
    };

    useEffect(() => {
        const storedActiveMenu = localStorage.getItem('active');
        if (storedActiveMenu !== null) {
            setActiveMenu(parseInt(storedActiveMenu));
        }
    }, []);

    const handleLogout = () => {
        localStorage.removeItem('active');
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        setActiveMenu(null);
        setCart([]);
        const auth = getAuth();

        signOut(auth).then(() => {
            navigate('/');
        }).catch((error) => {
            console.log(error);
        });
    };

    return (
        <div className="wrapper" id="client-app">
            <section id="sidebar" className={hideSidebar ? 'hide' : ''}>
                <Link to="/MyPage" className="brand" onClick={() => handleActiveMenu(0)}>
                    <img src={logo} alt="Company Logo" />
                </Link>
                <ul className="side-menu top">
                    <li className={activeMenu === 0 ? 'active' : ''}>
                        <Link to="" onClick={() => handleActiveMenu(0)}>
                            <AiFillDashboard className='bx' style={{ fontSize: '21px' }} />
                            <span className="text">Dashboard</span>
                        </Link>
                    </li>
                    <li className={activeMenu === 1 ? 'active' : ''}>
                        <Link to="/MyPage/MyStore" onClick={() => handleActiveMenu(1)}>
                            <FaStore className='bx' />
                            <span className="text">Store</span>
                        </Link>
                    </li>
                    <li className={activeMenu === 2 ? 'active' : ''}>
                        <a href="/MyPage/ProductLink" onClick={() => handleActiveMenu(2)}>
                            <FaExternalLinkAlt className='bx' />
                            <span className="text">Product Link</span>
                        </a>
                    </li>
                    <li className={activeMenu === 3 ? 'active' : ''}>
                        <a href="/MyPage/OrderHistory" onClick={() => handleActiveMenu(3)}>
                            <FaHistory className='bx' />
                            <span className="text">Order history</span>
                        </a>
                    </li>
                    <li className={activeMenu === 4 ? 'active' : ''}>
                        <a href="/MyPage/MyWallet" onClick={() => handleActiveMenu(4)}>
                            <FaWallet className='bx' />
                            <span className="text">My Wallet</span>
                        </a>
                    </li>
                    <li className={activeMenu === 5 ? 'active' : ''}>
                        <a href="/MyPage/AddInvitees" onClick={() => handleActiveMenu(5)}>
                            <BsPersonFillAdd className='bx' />
                            <span className="text">Add Invitees</span>
                        </a>
                    </li>
                    <li className={activeMenu === 6 ? 'active' : ''}>
                        <a href="/MyPage/MyGenealogy" onClick={() => handleActiveMenu(6)}>
                            <GiOrganigram className='bx' style={{ fontSize: '25px' }} />
                            <span className="text">Network</span>
                        </a>
                    </li>
                </ul>
                <ul className="side-menu logout">
                    <li onClick={handleLogout}>
                        <Link to="/" className="logout">
                            <IoMdLogOut className='bx' style={{ fontSize: '19px' }} />
                            <span className="text">Logout</span>
                        </Link>
                    </li>
                </ul>
            </section>
            <section id="content">
                <nav>
                    <FiMenu className='bx bx-menu' onClick={handleMenu} />
                    <div className='float-right'>
                        <input type="checkbox" id="switch-mode" hidden onClick={handleModeChange} />
                        <label htmlFor="switch-mode" className="switch-mode"></label>
                        <span className="profile" onClick={handleShowProfile}>
                            <img src={profileImage} alt="Profile" />
                        </span>
                    </div>
                    <Modal 
                        title='Profile Settings'
                        okText='Save'
                        open={showProfile}
                        onCancel={handleCloseProfile}
                        confirmLoading={isLoading}
                        classNames='Modal-Profile'
                        width={'900px'}
                        footer={null}
                    >
                        <MyProfile onClose={handleCloseProfile}/>
                    </Modal>
                </nav>
                <Outlet />
                <div className='main-footer'>
                    <div className='text-footer'>
                        <strong>© Loyalista Online Shop Corp. 2024</strong> | All rights reserved.
                    </div>
                    <div className="float-right d-none d-sm-inline-block">
                        <b>Version</b> 1.0.0
                    </div>
                </div>
            </section>
        </div>
    );
}

export default ClientApp;
