import React, { useState, useEffect } from 'react';
import './CashoutRequest.css';
import { IoSearch } from "react-icons/io5";
import { message, Pagination, Popconfirm } from 'antd';
import NoDataFound from '../../../Assets/no-data/NoDataFount';
// import SampleProduct from '../../../DataTest/SampleProducts';
import { equalTo, get, orderByChild, query, ref, update } from 'firebase/database';
import { db } from '../../../firebase/config';
import moment from 'moment'
const CashoutRequest = () => {
    const [currentPage, setCurrentPage] = useState(1); // State for current page
    const pageSize = 10; 
    const [loading, setLoading] = useState(false); 
    const [openCompleted, setOpenCompleted] = useState(false);
    const [openCancelled, setOpenCancelled] = useState(false);
    const [data, setData] = useState([]);
    const [cashout, setCashout] = useState([]);
    const [DataCount, setDataCount] = useState()
    useEffect(() => {
        const fetchOrderItems = async () => {
          try {
            const requestRef = ref(db, 'request');
            const snapshot = await get(requestRef);
            if (snapshot.exists()) {
              const requestItemsArray = Object.entries(snapshot.val())
                // .filter(([requestID, RequestData]) => RequestData.status === 'pending')
                .map(([requestID, RequestData]) => ({
                  ...RequestData,
                }))
                const requestDone = Object.entries(snapshot.val())
                // .filter(([requestID, RequestData]) => RequestData.status !== 'pending')
                .map(([requestID, RequestData]) => ({
                  ...RequestData,
                }))
                setDataCount(requestDone.length)
                const DataFormat = requestItemsArray.map(requestData => ({
                  user:requestData.userID,
                  key: requestData.RequestID.toString(),
                  ReqID: requestData.RequestID,
                  dateReq:moment(requestData.timestamp).format('MMMM DD YYYY'),
                  senderName: requestData.Recipient,
                  memberID: requestData.accountID||'N/A',
                  paymethod: requestData.Method,
                  contactNo: requestData.PhoneNumber,
                  BankAcc: requestData.BankAccountNumber,
                  amount: requestData.amount, 
                  status:requestData.status
                }));
                DataFormat.sort((a, b) => new Date(a.dateReq) - new Date(b.dateReq));
                setData(DataFormat);
                setFilteredRequests(DataFormat)
            } else {
            }
          } catch (error) {
            console.error(error);
          }
        };
    
        fetchOrderItems();
      }, [loading]);
    useEffect(() => {
        // Fetch orders or set sample data
        setCashout(data.cashoutReq || []);
    }, []);

    // search functionality
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredRequests, setFilteredRequests] = useState([]);

    // Filter the members whenever the search query changes
    useEffect(() => {
        const filtered = data.filter(
            (request) =>
            request.ReqID?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            request.senderName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            request.paymethod?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            request.BankAcc?.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredRequests(filtered);
        // Reset current page to 1 when search query changes
        setCurrentPage(1);
    }, [searchQuery]);

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const displayedRequests = filteredRequests.slice(startIndex, endIndex);



    const showPopconfirmCompleted = (ReqID) => {
        setOpenCompleted(true);
    };
    const showPopconfirmCancelled = (ReqID) => {
        setOpenCancelled(true);
    };

    const handleCancel = () => {
        setOpenCompleted(false);
        setOpenCancelled(false);
    };
    
    const handleCompleted = (cashoutID) => {
        setLoading(true);

        const updates = {};
        const action = 'Approved'
        updates['/request/' + cashoutID + '/status'] = action;
        setTimeout(() => {
            message.success(`Request ID ${cashoutID} has been marked as Completed`);
    
            // Set loading state back to false after action is completed
            setLoading(false);
            setOpenCompleted(false);
        }, 2000);
        return update(ref(db), updates);
    };

    const handleCancelled = (cashoutID) => {
        setLoading(true);

        const updates = {};
        const action = 'Cancelled'
        updates['/request/' + cashoutID + '/status'] = action;
        setTimeout(() => {
           
            message.warning(`Request ID ${cashoutID} has been marked as Cancelled`);
    
            // Set loading state back to false after action is completed
            setLoading(false);
            setOpenCancelled(false);
        }, 2000);
        return update(ref(db), updates);
    };

    return (
        <main id='cashoutReq'>
            <div className="head-title">
                <div className="left">
                    <h1>Cashout Requests</h1>
                </div>
            </div>

            <div className="table-data">
                <div className="requests-table">
                    <div className="head">
                        <h4>Requests List</h4>
                        <div className="search-bar">
                            <IoSearch className='bx'/>
                            <input type="search"
                                placeholder='Search here...'
                                className='form-control'
                                onChange={handleSearchChange}/>
                        </div>
                    </div>
                    {filteredRequests.length === 0 ? (
                        <NoDataFound/>
                    ) : (
                        <div className="requests-list">
                            <table>
                                <caption>Total Approved Cash-Out Requests: ₱ 800.00</caption>
                                <thead>
                                    <tr>
                                        <th>Request ID</th>
                                        <th>Date Requested</th>
                                        <th>Sender Name</th>
                                        <th>Member ID</th>
                                        <th>Contact No.</th>
                                        <th>Payment Method</th>
                                        <th>Amount</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {displayedRequests.map((request) => (
                                    <tr key={request.ReqID}>
                                        <td>{request.ReqID}</td>
                                        <td>{request.dateReq}</td>
                                        <td>{request.senderName}</td>
                                        <td>{request.memberID}</td>
                                        <td>{request.contactNo}</td>
                                        <td>{request.paymethod === 'Bank-Account' ? (
                                            request.BankName || 'via Bank Name'
                                        ) : (
                                            request.paymethod
                                        )}

                                        </td>
                                        <td>₱ {request.amount}.00</td>
                                        <td>
                                            {request.status === "Pending" ? (
                                                <div className='action-btn'>
                                                    <Popconfirm
                                                        title="Are you sure to mark this as Completed?"
                                                        onConfirm={() => handleCompleted(request.ReqID)}
                                                        open={openCompleted[request.ReqID]} // Use specific state variable for each request
                                                        onCancel={handleCancel}
                                                        okButtonProps={{ loading: loading }}
                                                    >
                                                        <button className='btn btn-link text-success' onClick={() => showPopconfirmCompleted(request.ReqID)}>
                                                            Approve
                                                        </button>
                                                    </Popconfirm>
                                                    <Popconfirm
                                                        title="Are you sure to mark as Cancelled?"
                                                        onConfirm={() => handleCancelled(request.ReqID)}
                                                        open={openCancelled[request.ReqID]} // Use specific state variable for each request
                                                        onCancel={handleCancel}
                                                        cancelText="NO"
                                                        okButtonProps={{ loading: loading }}
                                                    >
                                                        <button className='btn btn-link text-danger' onClick={() => showPopconfirmCancelled(request.ReqID)}>
                                                            Cancel
                                                        </button>
                                                    </Popconfirm>
                                                </div>
                                            ) : (
                                                <span className={`status ${request.status}`}>{request.status}</span>
                                            )}
                                        </td>
                                    </tr>
                                ))}

                                </tbody>
                            </table>
                            <div className="pagination-container">
                                <Pagination
                                    current={currentPage}
                                    // total={filteredProducts.length}
                                    onChange={handlePageChange}
                                    pageSize={pageSize}
                                    showSizeChanger={false}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </main>
    );
};

export default CashoutRequest;
