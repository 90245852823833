import React, { useEffect, useState } from 'react';
import './Genealogy.css';
import { child, get, ref } from 'firebase/database';
import { db } from '../../../firebase/config';
import { FaUser } from "react-icons/fa";
import Tree from 'react-d3-tree';
import LabelComponent from './LabelComponent'; // Adjust the import path based on your actual file structure

const Genealogy = () => {
    const [treeData, setTreeData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const snapshot = await get(child(ref(db), 'users'));
                if (snapshot.exists()) {
                    const userData = snapshot.val();
                    const userArray = Object.values(userData);
                    const chartDataFromFirebase = [];

                    const findReferrals = (username, level) => {
                        userArray.forEach(creds => {
                            if (creds.referral === username) {
                                chartDataFromFirebase.push({
                                    name: creds.username,
                                    parent: creds.referral,
                                    level: level,
                                    status: creds.status,
                                });
                                findReferrals(creds.username, level + 1);
                            }
                        });
                    };

                    userArray.forEach(creds => {
                        if (creds.referral === '') {
                            chartDataFromFirebase.push({
                                name: creds.username,
                                parent: 'Loyalista',
                                level: 1,
                                status: creds.status,
                            });
                            findReferrals(creds.username, 2);
                        }
                    });

                    const transformedData = transformDataForTree(chartDataFromFirebase);
                    setTreeData(transformedData || []);
                } else {
                    alert('No data available');
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const transformDataForTree = (data) => {
        const treeData = [];
        const root = { name: 'Loyalista', children: [] };
        const nodesByName = { Loyalista: root };

        data.forEach(member => {
            const { name, parent, ...rest } = member;
            const newNode = { name, attributes: { ...rest }, children: [] };
            nodesByName[name] = newNode;

            if (parent && nodesByName[parent]) {
                nodesByName[parent].children.push(newNode);
            } else {
                root.children.push(newNode);
            }
        });

        treeData.push(root);
        return treeData;
    };

    if (!treeData) {
        return <div className='chart-data'>Loading...</div>;
    }

    return (
        <main id="genealogy">
            <div className="head-title">
                <div className="left">
                    <h1>Genealogy Chart</h1>
                </div>
            </div>

            <div className="chart-data">
                <div className="genealogy-tree">
                <Tree
                    data={treeData}
                    nodeSvgShape={{
                        shape: 'foreignObject',
                        shapeProps: {
                        width: 180,
                        height: 80,
                        x: -90,
                        y: -40,
                        },
                        children: (
                        <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <FaUser style={{ fontSize: '20px' }} />
                        </div>
                        ),
                    }}
                    nodeLabelComponent={{
                        render: <LabelComponent />, // Replace with your custom label component
                        foreignObjectWrapper: {
                        width: 220,
                        height: 200,
                        y: -50,
                        x: -100,
                        },
                    }}
                    pathFunc="step"
                    separation={{ siblings: 2, nonSiblings: 2 }}
                    orientation="vertical"
                    translate={{ x: 900, y: 100 }}
                    allowForeignObjects={true}
                    initialDepth={0.02}
                    />
                </div>
            </div>
        </main>
    );
};

export default Genealogy;
