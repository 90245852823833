import React from 'react';

const LabelComponent = ({ nodeData }) => {
    return (
        <div className='myLabelComponentInSvg'>
            <p>Name: {nodeData.name}</p>
            <p>Status: {nodeData.attributes.status}</p>
            <p>Level: {nodeData.attributes.level}</p>
        </div>
    );
};

export default LabelComponent;
