import React, { useEffect, useState } from 'react';
import './MyProfile.css'
import { Upload, message, Rate } from 'antd';
import ImgCrop from 'antd-img-crop';
import { EmailAuthProvider, reauthenticateWithCredential, updatePassword } from 'firebase/auth';
import { deleteObject, getDownloadURL, listAll, ref as storageRef, uploadBytes } from 'firebase/storage';
import { auth, db, storage } from '../../../firebase/config';
import { get, ref, update } from 'firebase/database';
import defaultProfile from '../../../Assets/images/instagram-01.jpg';
import { IoIosClose } from "react-icons/io";
import { regions, provinces, cities, barangays } from 'select-philippines-address';

const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });


const resizeImage = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target.result;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = 250;
        canvas.height = 250;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, 250, 250);
        canvas.toBlob((blob) => {
          const newFile = new File([blob], file.name, {
            type: 'image/jpeg',
          });
          resolve(newFile);
        }, 'image/jpeg');
      };
    };
  });
};

const MyProfile = ({onClose}) => {
  const [profileImage, setProfileImage] = useState('');
  const [showError, setShowError] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [contact, setContact] = useState('');
  const [showChangePass, setShowChangePass] = useState(false);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [inputRegion, setInputRegion] = useState('');
  const [inputProvince, setInputProvince] = useState('');
  const [inputCity, setInputCity] = useState('');
  const [inputBrgy, setInputBrgy] = useState('');
  const [regionData, setRegionData] = useState([]);
  const [provinceData, setProvinceData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [barangayData, setBarangayData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isProfileChanged, setProfileChanged] = useState(false);
  const [dependency, setDependency] = useState(0);
  const [passwordError, setPasswordError] = useState('');
  const [contactError, setContantError] = useState('');
  const [daysUntilExpiration, setDaysUntilExpiration] = useState(0);
  const [newFormData, setnewFormData] = useState({
    region: '',
    province: '',
    city: '',
    brgy: '',
    street: '',
    postal: '',
    landmark: ''
  });
  const user = JSON.parse(localStorage.getItem('user'));
  const [currentStreet, setCurrentStreet] = useState('')
  const [currentPostal, setCurrentPostal] = useState('')
  useEffect(() => {
    if (newFormData && newFormData.subscription_Expiration) {
      const expirationDate = new Date(newFormData.subscription_Expiration);
      const currentDate = new Date();
      const daysDifference = Math.ceil((expirationDate - currentDate) / (1000 * 60 * 60 * 24));
      if(daysDifference > 1) {
        setDaysUntilExpiration(daysDifference);
      }else{
        setDaysUntilExpiration(0);
      }
    } else {
      setDaysUntilExpiration(0);
    }
  }, [newFormData]);
  const beforeUpload = (file) => {
    if (!["image/jpeg", "image/png"].includes(file.type)) {
      message.error(`${file.name} is not a valid image type`, 2);
      return null;
    }
    return false;
  };

  useEffect(() => {
    const fetchProfile = async () => {
      const imageExtensions = ['png', 'jpg', 'jpeg'];

      for (const extension of imageExtensions) {
        const imageRef = storageRef(storage, `users/${user.uid}/profile/profile.${extension}`);
        try {
          setFileList([{
            uid: user.uid,
            name: `profile.${extension}`,
            status: 'done',
            url: await getDownloadURL(imageRef),
          }]);
          setProfileImage(await getDownloadURL(imageRef));
          break;
        } catch (error) {
          console.error("Error fetching profile image: ", error);
        }
      }
    };
    fetchProfile();
  }, [isLoading]);

  useEffect(() => {
    const getUserInfo = async () => {
      try {
        const userInfoRef = ref(db, `users/${user.uid}`);
        const snapshot = await get(userInfoRef);

        if (snapshot.exists()) {
          const userData = snapshot.val();
          setCurrentStreet(userData.street)
          setCurrentPostal(userData.postal)
          setContact(userData.contact);
          setnewFormData({
            ...userData,
          });
        }
      } catch (error) {
        console.error(error);
      }
    };
    getUserInfo();
  }, [isLoading]);

  

  useEffect(() => {
    fetchRegions();
  }, []);
  const fetchRegions = () => {
    regions()
      .then(response => {
        setRegionData(response);
      })
      .catch(error => console.error('Error fetching regions:', error));
  };
  const fetchProvinces = region_code => {
    provinces(region_code).then(response => {
      setProvinceData(response);
    });
  };
  const fetchCities = provinceCode => {
    cities(provinceCode).then(response => {
      setCityData(response);
    });
  };
  const fetchBarangays = cityCode => {
    barangays(cityCode).then(response => {
      setBarangayData(response);
    });
  };
  const onChangeRegion = (e) => {
    const selectedRegionCode = e.target.value;
    const selectedRegion = regionData.find((item) => item.region_code === selectedRegionCode);

    setInputRegion(selectedRegionCode);
    setInputProvince('');
    setInputCity('');
    setInputBrgy('');
    
      setnewFormData({
        ...newFormData,
        region: selectedRegion ? selectedRegion.region_name : '',
        province: '',
        city:'',
        brgy:''
      });
  
    fetchProvinces(selectedRegionCode);
    setProvinceData([]);
    setCityData([]);
    setBarangayData([]);
  };
  const onChangeProvince = (e) => {
    const selectedProvinceCode = e.target.value;
    const selectedProvince = provinceData.find((item) => item.province_code === selectedProvinceCode);

    setInputProvince(selectedProvinceCode);
    setInputCity('');
    setInputBrgy('');
   
      setnewFormData({
        ...newFormData,
        province: selectedProvince.province_name,
        city:'',
        brgy:''
      });
    

    fetchCities(selectedProvinceCode);
    setCityData([]); 
    setBarangayData([]);
  };
  const onChangeCity = (e) => {
    const selectedCityCode = e.target.value;
    const selectedCity = cityData.find((item) => item.city_code === selectedCityCode);

    setInputCity(selectedCityCode);
    setInputBrgy('');
    
      setnewFormData({
        ...newFormData,
        city: selectedCity ? selectedCity.city_name : '',
        brgy:''
      });
    
    fetchBarangays(selectedCityCode);
    setBarangayData([]); 
  };
  const onChangeBarangay = (e) => {
    const selectedBarangayCode = e.target.value;
    const selectedBarangay = barangayData.find((item) => item.brgy_code === selectedBarangayCode);

    setInputBrgy(selectedBarangayCode);
    
      setnewFormData({
        ...newFormData,
        brgy: selectedBarangay ? selectedBarangay.brgy_name : '',
      });
    
  };

  const uploadImage = async (imageFile, pathName) => {
    try {
      const originalFileName = imageFile.name;
      const fileExtension = originalFileName.split('.').pop().toLowerCase();
      const newFileName = `profile.${fileExtension}`;
      const uploadRef = storageRef(storage, `users/${pathName}/profile/${newFileName}`);
      await deleteExistingProfile(pathName);
      await uploadBytes(uploadRef, imageFile);
      setDependency(dependency + 1);
    } catch (error) {
      message.error('Failed to upload your profile picture!');
      console.error('Error uploading image:', error);
    }
  };

  const deleteExistingProfile = async (pathName) => {
    const listRef = storageRef(storage, `users/${pathName}/profile`);

    try {
      // List all items (files) and folders within the directory
      const listResults = await listAll(listRef);

      // Delete all files found
      const deletePromises = listResults.items.map((itemRef) => deleteObject(itemRef));
      await Promise.all(deletePromises);

      // If there are subfolders, call deleteExistingProfile recursively
      const folderDeletePromises = listResults.prefixes.map((folderRef) => deleteExistingProfile(folderRef.fullPath));
      await Promise.all(folderDeletePromises);
    } catch (error) {
      console.error('Error deleting existing profile folder:', error);
    }
  };

  const handleChange = async ({ fileList: newFileList }) => {
    const resizedFileList = await Promise.all(
      newFileList.map(async (file) => {
        if (file.originFileObj) {
          const resizedFile = await resizeImage(file.originFileObj);
          const newFile = new File([resizedFile], file.name, {
            type: 'image/jpeg',
          });
          return {
            ...file,
            originFileObj: newFile,
            url: URL.createObjectURL(newFile),
          };
        }
        return file;
      })
    );
    setFileList(resizedFileList);
    setProfileChanged(true);
  };

  const handleShowChange = () => {
    setShowChangePass(true);
  };
  const handleCloseChange = () => {
    setShowChangePass(false);
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setnewFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSave = async (e) => {
    setIsLoading(true);

    const updates = {};

    let passwordUpdated = false;
    let profileUpdated = false;
    let contactUpdated = false;
    let updateCount = 0;

    // Handle password change
    if (showChangePass) {
      try {
        const user = auth.currentUser;
        const credentials = EmailAuthProvider.credential(
          user.email,
          oldPassword
        );

        await reauthenticateWithCredential(user, credentials);
        await updatePassword(user, newPassword);
        passwordUpdated = true;
        setOldPassword('');
        setNewPassword('');
        setShowChangePass(false);
        updateCount++;
      } catch (error) {
        setPasswordError('*You Entered Wrong Password!');
        setOldPassword('');
        setNewPassword('');
        setIsLoading(false);
        return;
      }
    }

    // Handle profile picture change
    if (isProfileChanged) {
      const resizedFile = await resizeImage(fileList[0].originFileObj);
      await uploadImage(resizedFile, user.uid);
      profileUpdated = true;
      setProfileChanged(false);
      updateCount++;
    }

    // Handle contact update
    if (contact !== user.contact) {
      updates['/users/' + user.uid + '/contact'] = contact;
      contactUpdated = true;
      updateCount++;
    }
    if(inputRegion !== ''&& inputProvince !== '' & inputCity !== '' & inputBrgy !==''){
      updates['/users/' + user.uid + '/region'] = newFormData.region;
      updates['/users/' + user.uid + '/province'] = newFormData.province;
      updates['/users/' + user.uid + '/city'] = newFormData.city;
      updates['/users/' + user.uid + '/brgy'] = newFormData.brgy;  
      updateCount=+2;
    }
    if(newFormData.street !== currentStreet){
      if(newFormData.street !== ''){
        updates['/users/' + user.uid + '/street'] = newFormData.street;
        updateCount++;
      }else{
        message.error('Blank Fields ...!');
      }
    } 

    if(newFormData.street !== currentStreet || newFormData.postal !== currentPostal){
      if(newFormData.postal !== ''){
        updates['/users/' + user.uid + '/postal'] = newFormData.postal;
        updateCount++;
      }else{
        message.error('Blank Fields ...!');
      }
    } 

   
    // Update contact information if there are updates
    if (Object.keys(updates).length > 0) {
      updates['/users/' + user.uid + '/landmark'] = newFormData.landmark;
      await update(ref(db), updates);
    }

    setIsLoading(false);
    onClose();

    if (updateCount > 1) {
      message.success('Changes have been saved!');
    } else {
      if (passwordUpdated) {
        message.success('Password updated successfully!');
      }
      if (profileUpdated) {
        message.success('Your profile picture has been saved!');
      }
      if (contactUpdated) {
        message.success('Your contact number has been saved!');
      }
    }
 
  };

  return (
    <main id='MyProfile'>
      <div className='Modal-Body'>
        <form>
          <div className="row mb-0 upper-row">
            <div className="col-12 col-lg-4 p-2">
              <div className='w-100 d-flex justify-content-center align-items-center upload-profile'>
                <ImgCrop aspect={1}>
                  <Upload
                    listType="picture-circle"
                    fileList={fileList}
                    onChange={handleChange}
                    accept="image/png, image/jpeg"
                    beforeUpload={beforeUpload}
                    customRequest={dummyRequest}
                    style={{ width: '250px', height: '250px' }}
                  >
                    {fileList.length < 1 && '+ Upload Profile'}
                  </Upload>
                </ImgCrop>
              </div>
            </div>
            <div className="col-12 col-lg-8">
              <div className="user-info mb-4">
                <h3>{newFormData.firstname } {newFormData.middlename} {newFormData.lastname}</h3>
                <Rate disabled allowHalf value='5'/> <span className='rates'>(5/5)</span>
              </div>
              <div className="user-otherInfo">
                <p><b>Username: </b>{newFormData.username}</p>
                <p><b>Member ID: </b>{newFormData.accountID}</p>
                <p><b>Email: </b> {newFormData.email}</p>
                <p><b>Subscription: </b>{daysUntilExpiration} days left</p>
              </div>
              
            </div>
            
          </div>
          <div className="row mb-5">
              <div className="col-12 col-lg-4 p-2">
                <div className="form-group mb-3">
                  <label htmlFor="contact">Contact Number</label>
                  <input
                    type="text"
                    className="form-control mt-2"
                    id="contact"
                    name='contact'
                    onChange={(e) => setContact(e.target.value)}
                    value={contact}
                  />
                </div>
                {showError && (
                  <span className='text-danger'>{contactError}</span>
                )}
              {showChangePass ? (
                <div className='d-flex justify-content-between'>
                  <div className="form-group w-100 mb-3">
                    <label htmlFor="oldPassword">Old Password</label>
                    <input
                      type="password"
                      className="form-control mt-2"
                      id="oldPassword"
                      name='oldPassword'
                      placeholder='Input old password'
                      value={oldPassword}
                      onChange={(e) => setOldPassword(e.target.value)}
                    />
                    <p className='text-danger mb-0 errorMessage'>
                      {passwordError}
                    </p>
                    <label htmlFor="newPassword">New Password</label>
                    <input
                      type="password"
                      className="form-control mt-2"
                      id="newPassword"
                      name='newPassword'
                      placeholder='Input new password'
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                    />
                  </div>
                  <IoIosClose onClick={handleCloseChange} className='close-btn'/>
                </div>
              ) : (
                <button type="button" className="btn btn-primary" onClick={handleShowChange}>Change Password?</button>
              )}
              </div>
              <div className="col-12 col-lg-8 p-2">
                <div className="header">
                  <span>Address Info</span>
                </div>
                <div className="input-group gap-2">
                  <div className="form-floating mb-3">
                    <select className="form-select" 
                        name="region"
                        id="region"
                        value={newFormData.region}
                        onChange={onChangeRegion}
                        required>
                      <option value={newFormData.region} disabled selected>
                      {newFormData.region}
                      </option>
                      {regionData.map(item => (
                        <option
                          key={item.region_code}
                          value={item.region_code}
                        >
                          {item.region_name}
                        </option>
                      ))}
                    </select>
                    <label htmlFor="region">Region</label>
                  </div>
                  <div className="form-floating mb-3">
                    <select className="form-select" 
                        name="province"
                        id="province"
                        value={newFormData.province}
                        onChange={onChangeProvince}
                        required>
                      <option value={newFormData.province} disabled selected>
                      {newFormData.province}
                      </option>
                      {provinceData.map(item => (
                        <option
                          key={item.province_code}
                          value={item.province_code}
                        >
                          {item.province_name}
                        </option>
                      ))}
                    </select>
                    <label htmlFor="province">Province</label>
                  </div>
                </div>

                <div className="input-group gap-2">
                  <div className="form-floating mb-3">
                    <select className="form-select" 
                        name="city"
                        id="city"
                        value={newFormData.city}
                        onChange={onChangeCity}
                        required>
                      <option value={newFormData.city} disabled selected>
                      {newFormData.city}
                      </option>
                      {cityData.map(item => (
                        <option 
                          key={item.city_code} 
                          value={item.city_code}
                        >
                          {item.city_name}
                        </option>
                      ))}
                    </select>
                    <label htmlFor="city">City / Municipal</label>
                  </div>
                  <div className="form-floating mb-3">
                    <select className="form-select" 
                        name='brgy'
                        id="brgy"
                        value={newFormData.brgy}
                        onChange={onChangeBarangay}
                        required>
                      <option value={newFormData.brgy} disabled selected>
                      {newFormData.brgy}
                      </option>
                      {barangayData.map(item => (
                        <option 
                          key={item.brgy_code} 
                          value={item.brgy_code}
                        >
                          {item.brgy_name}
                        </option>
                      ))}
                    </select>
                    <label htmlFor="brgy">Barangay</label>
                  </div>
                </div>

                <div className="input-group gap-2">
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      className="form-control"
                      name="street"
                      id="street"
                      placeholder=""
                      value={newFormData.street}
                      onChange={handleInputChange}
                      required
                    />
                    <label htmlFor="street">Street</label>
                  </div>
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      className="form-control"
                      name="postal"
                      id="postal"
                      placeholder=""
                      value={newFormData.postal}
                      onChange={handleInputChange}
                      required
                    />
                    <label htmlFor="postal">Postal Code</label>
                  </div>
                </div>
                
                <div className="form-floating mb-3">
                  <textarea
                    style={{height: '132px'}}
                    className="form-control"
                    name="landmark"
                    id="landmark"
                    value={newFormData.landmark}
                    onChange={handleInputChange}
                    placeholder=""                      
                  />
                  <label htmlFor="landmark">Landmark (Optional)</label>
                </div>
              </div>
            </div>
        </form>
      </div>
      <div className='Modal-Footer'>
        <button type="button" className="btn btn-success" onClick={handleSave} disabled={isLoading}>
          {isLoading && (
            <span className="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true"></span>
          )}
          {isLoading ? ' Saving... ' : 'Save'}
        </button>
      </div>
    </main>
  )
}

export default MyProfile;
