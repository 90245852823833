import React, { useState, useEffect } from 'react'
import './Products.css'
import { IoSearch, IoPrint, IoBagAdd  } from "react-icons/io5";
import { TbShoppingBagEdit } from "react-icons/tb";
import { FaTrashCan } from "react-icons/fa6";
import { Pagination, Tooltip, Modal, message, Upload, Image, Popconfirm } from 'antd';
import ImgCrop from 'antd-img-crop';
import { PlusOutlined } from '@ant-design/icons';
import NoDataFount from '../../../Assets/no-data/NoDataFount';
// import SampleProduct from '../../../DataTest/SampleProducts';
import { get, child, ref, set,serverTimestamp, remove, push, update } from 'firebase/database';
import { getDownloadURL, ref as storageRef, uploadBytes, deleteObject, listAll  } from 'firebase/storage';
import { db, storage } from '../../../firebase/config';

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  const dummyEditRequest = ({ onSuccess }) => {
    setTimeout(() => {
        onSuccess("ok");
    }, 0);
};

const Products = () => {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [openAddProduct, setOpenAddProduct] = useState(false);
  const [openEditProduct, setOpenEditProduct] = useState(false);
  const [openDeleteConfirm, setOpenDeleteConfirm] =useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [currentPage, setCurrentPage] = useState(1); // State for current page
  const pageSize = 8; 
  const [dependencyKey, setDependencyKey] = useState(0);
   // search functionality

   const [filteredProducts, setFilteredProducts] = useState([]);
   const [productData, setProductData] = useState([]);
   useEffect(() => {
    const fetchProducts = async () => {
      try {
        const snapshot = await get(child(ref(db), 'products'));
        if (snapshot.exists()) {
          const productsData = snapshot.val();
          const productsArray = Object.values(productsData);
    
          const productsWithImages = await Promise.all(
            productsArray.map(async (product) => {
              const imagesListRef = storageRef(storage, `products/${product.id}/`);
              try {
                const images = await listAll(imagesListRef);
                const imageUrls = await Promise.all(
                  images.items.map(async (imageRef) => {
                    try {
                      const imageUrl = await getDownloadURL(imageRef);
                      return { url: imageUrl, name: imageRef.name };
                    } catch (error) {
                      console.error('Error fetching image:', error);
                      return null;
                    }
                  })
                );
    
                const filteredImageUrls = imageUrls.filter((item) => item !== null);
                return { ...product, images: filteredImageUrls };
              } catch (error) {
                console.error('Error listing images:', error);
                return { ...product, images: [] };
              }
            })
          );
    
          const sortedProductsWithImages = productsWithImages.map((product) => {
            if (product.category === 'Package' && product.commissions) {
              const sortedCommissions = {};
              Object.keys(product.commissions)
                .sort((a, b) => parseInt(a.replace('level', '')) - parseInt(b.replace('level', '')))
                .forEach((key) => {
                  sortedCommissions[key] = product.commissions[key];
                });
              return { ...product, commissions: sortedCommissions };
            }
            return product;
          });
          setProductData(sortedProductsWithImages);
          setFilteredProducts(sortedProductsWithImages);
          if (!selectedProduct) {
            setSelectedProduct(sortedProductsWithImages[0]);
          }
        } else {
          alert('No data available');
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchProducts();
}, [dependencyKey]);

  // Filter the members whenever the search query changes
  useEffect(() => {
    if (filteredProducts.length > 0) {
        // Map images to temp file list format
        const tempFileList = selectedProduct.images.map((image, index) => ({
            uid: `${selectedProduct.id}-${image.name}`,
            name:  image.name,
            status: 'done',
            url: image.url,
        }));

        setTempFileList(tempFileList);
    }
}, [filteredProducts, selectedProduct]);



const handleSearchChange = (e) => {
  // Get the search query from the event and convert to lowercase
  let searchQuery = e.target.value.toLowerCase();

  // Ensure productData is an array; if not, convert it to an array
  const productsArray = Array.isArray(productData) ? productData : [productData];

  // Filter products based on the search query
  const filtered = productsArray.filter((product) => {
      // Ensure the properties are strings and convert to lowercase for comparison
      return (
          (product.id && product.id.toString().toLowerCase().includes(searchQuery)) ||
          (product.name && product.name.toLowerCase().includes(searchQuery)) ||
          (product.category && product.category.toLowerCase().includes(searchQuery))
      );
  });

  // Update the state with the filtered products and reset the current page to 1
  setFilteredProducts(filtered);
  setCurrentPage(1);
};


  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const [tempFileList, setTempFileList] = useState([])
  // Function to handle selecting a member
  const handleProductSelect = async(product) => {
    setSelectedProduct(product);
  };
  //open the modal for adding product form
  const showAddProductForm = () => {
    setOpenAddProduct(true);
  };
  const handleCancel = () => {
    setOpenAddProduct(false);
    setOpenEditProduct(false);
    setOpenDeleteConfirm(false);
  }
  const [addProductForm, setAddProductForm] = useState({
    image: [],
    name: '',
    description: '',
    price: 0,
    commissions: {},
    barleyCommission: 0,
    category: '',
  });
  const handleComissionChange = (e) => {
    const { name, value } = e.target;
    setAddProductForm((prevForm) => ({
      ...prevForm,
      commissions: {
        ...prevForm.commissions,
        [name]: parseFloat(value) // Convert value to float
      }
    }));
  };
  // Handling category change
  const handleCategoryChange = (e) => {
    const { value } = e.target;
    const commissions = value === 'Single Product' ?
      {
      } :
      {
        level1: 0.00,
        level2: 0.00,
        level3: 0.00,
        level4: 0.00,
        level5: 0.00,
        level6: 0.00,
        level7: 0.00,
        level8: 0.00,
        level9: 0.00,
        level10: 0.00,
      };
  
    setAddProductForm({
      ...addProductForm,
      category: value,
      commissions: commissions
    });
  };

  const handleCommissionChange = (commissionType, value) => {
    setSelectedProduct({
      ...selectedProduct,
      commissions: {
        ...selectedProduct.commissions,
        [commissionType]: parseFloat(value) || 0,
      },
    });
  };
  
  const submitAddedProduct = async (e) => {
    e.preventDefault();
    setConfirmLoading(true);

    setTimeout(async () => {
        setOpenAddProduct(false);
        setConfirmLoading(false);

        try {
            // Calculate nextId
            const maxId = productData.length > 0 ? Math.max(...productData.map(product => product.id || 0)) : 0;
            const nextId = maxId + 1;

            // Assign the new id to addProductForm
            const newProductForm = { ...addProductForm, id: nextId };

            // Ensure newProductForm.id is properly set
            await set(ref(db, `products/${newProductForm.id}`), newProductForm);

            let fileCount = 0;
            for (const imageFile of ImageArray) {
                fileCount += 1;
                uploadAllImage(newProductForm.id, imageFile, fileCount);
            }
            setDependencyKey((prevKey) => prevKey + 1);
            setFileList([]);
            message.success('New Product is successfully added');
            setTimeout(() => {
              window.location.reload();
              }, 1000);
        } catch (error) {
            console.error('Error adding new product:', error);
            message.error('Failed to add new product');
        }
    }, 2000);
  };

const uploadAllImage = (pathName, imageFile, name) => {
  const uploadRef = storageRef(storage, `products/${pathName}/${name}`);
    uploadBytes(uploadRef, imageFile.originFileObj).then((snapshot) => {
      message.success(`Image ${name} is uploaded successfully!`);
  }).catch((err) => {
      message.warning(`Image ${name} failed to upload!`);
  });
};

  const [ImageArray, setFileList] = useState([]);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewImage(true);
  };
  
  const handleChange = ({ fileList }) => {
    if (fileList.length <= 5) {
        setFileList(fileList.filter(file => file.status !== "error"));
    } else {
        message.error('You can only upload up to 5 images.');
    }
  };

  const handleProductChange = async ({ fileList }) => {
    if (fileList.length <= 5) {
      setTempFileList(fileList.filter(file => file.status !== "error"));
  } else {
      message.error('You can only upload up to 5 images.');
  }
};


  const uploadButton = (
    <button
      style={{
        border: 0,
        background: 'none',
      }}
      type="button"
    >
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Product Image
      </div>
    </button>
  );

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAddProductForm(prevState => ({
      ...prevState,
      id:nextId,
      [name]: value,
    }));
  };
  const maxId = productData.length > 0 ? Math.max(...productData.map(product => product.id || 0)) : 0;
  const nextId = maxId + 1;
  
  //open the modal for editing product form
  const showEditProductForm = () => {

    setOpenEditProduct(true);
  }
  const submitEditedProduct = async () => {
    setConfirmLoading(true);
    try {
    const updates = {};
    updates['/products/' + selectedProduct.id + '/description'] = selectedProduct.description;
    updates['/products/' + selectedProduct.id + '/name'] = selectedProduct.name;
    updates['/products/' + selectedProduct.id + '/price'] = selectedProduct.price;
    updates['/products/' + selectedProduct.id + '/category'] = selectedProduct.category;
    updates['/products/' + selectedProduct.id + '/barleyCommission'] = selectedProduct.barleyCommission;
    if(selectedProduct.category === 'Package') {
    updates['/products/' + selectedProduct.id + '/commissions/level1'] = selectedProduct.commissions.level1;
    updates['/products/' + selectedProduct.id + '/commissions/level2'] = selectedProduct.commissions.level2;
    updates['/products/' + selectedProduct.id + '/commissions/level3'] = selectedProduct.commissions.level3;
    updates['/products/' + selectedProduct.id + '/commissions/level4'] = selectedProduct.commissions.level4;
    updates['/products/' + selectedProduct.id + '/commissions/level5'] = selectedProduct.commissions.level5;
    updates['/products/' + selectedProduct.id + '/commissions/level6'] = selectedProduct.commissions.level6;
    updates['/products/' + selectedProduct.id + '/commissions/level7'] = selectedProduct.commissions.level7;
    updates['/products/' + selectedProduct.id + '/commissions/level8'] = selectedProduct.commissions.level8;
    updates['/products/' + selectedProduct.id + '/commissions/level9'] = selectedProduct.commissions.level9;
    updates['/products/' + selectedProduct.id + '/commissions/level10'] = selectedProduct.commissions.level10;
  }


    setTimeout(() => {
      setOpenEditProduct(false);
      setConfirmLoading(false);
      message.success(`Product no. ${selectedProduct.id} is successfully updated!`);
      
    }, 2000);
    return update(ref(db), updates).then(async()=>{

        if (tempFileList.length > 0) {
          const currentImagesSnapshot = await listAll(storageRef(storage, `products/${selectedProduct.id}/`));
          const currentImageNames = currentImagesSnapshot.items.map((item) => item.name);
      
          // Identify the images to delete
          const imagesToDelete = currentImageNames.filter(
            (name) => !tempFileList.some((file) => file.name === name && file.status === 'done')
          );
          for (const imageName of imagesToDelete) {
            const imageRef = storageRef(storage, `products/${selectedProduct.id}/${imageName}`);
            await deleteObject(imageRef);
          }
      
          const newImages = tempFileList.filter((file) => file.originFileObj);
      
          // Get the current max count for renaming
          const existingFileNumbers = currentImageNames.map(name => parseInt(name.split('.')[0])).filter(num => !isNaN(num));
          let nextFileNumber = existingFileNumbers.length > 0 ? Math.max(...existingFileNumbers) + 1 : 1;
      
          for (const imageFile of newImages) {
            // Construct new filename sequentially
            const fileExtension = imageFile.name.split('.').pop().toLowerCase();
            const fileName = `${nextFileNumber}.${fileExtension}`;
            nextFileNumber += 1;
      
            uploadAllImage(selectedProduct.id, imageFile, fileName);
          }
        }
      setTimeout(() => {
      window.location.reload();
      }, 2000);

    })
  } catch (error) {
    console.error('Error updating product:', error);
    message.error('Failed to update the product!');
  }
  }
  
  const handleEditChange = (e) => {
    const { id, value } = e.target;
    setSelectedProduct(prevState => ({
      ...prevState,
      [id]: value
    }));
  };

  const beforeUpload = (file) => {
    if (!["image/jpeg", "image/png"].includes(file.type)) {
        message.error(`${file.name} is not a valid image type`, 2);
        return false;
    }
    if (ImageArray.length >= 5) {
        message.error('You can only upload up to 5 images.', 2);
        return false;
    }
    return true;
};

  //PopConfirm for deleting Product
  const showDeletePopConfirm = () => {
    setOpenDeleteConfirm(true);
  }
  const handleDeleteProduct = async() => {
    setConfirmLoading(true);

    setTimeout(async() => {
      try {
        // Assuming `selectedRow` has an `id` property
        const productId = selectedProduct.id;
    
        // Delete from Realtime Database
        const databaseRef = ref(db, `products/${productId}`);
        await remove(databaseRef);
    
        // Try deleting both PNG and JPG images from Firebase Storage
        const imageExtensions = ['png', 'jpg'];
    
        for (const extension of imageExtensions) {
          const imageOtherRef = storageRef(storage, `products/${productId}`);
          try {
            const listResult = await listAll(imageOtherRef);
            const deletePromises = listResult.items.map(async (item) => {
              await deleteObject(item);
            });
            await Promise.all(deletePromises);
            break; 
          } catch (error) {
            console.error(`Error deleting ${extension} image:`, error);
          }
        }
        setTimeout(() => {
          window.location.reload();
          }, 2000);
        setDependencyKey((prevKey) => prevKey + 1);
        setOpenDeleteConfirm(false);
        setConfirmLoading(false);
        message.warning(`Product "${selectedProduct.name}" has been deleted!`);
      } catch (error) {
        console.error('Error deleting product and image:', error.message);
        // Handle error as needed
      }
    }, 2000);
  }
  

  
  return (
    <main id='productPage'>
      <div className="head-title">
        <div className="left">
          <h1>Our Products</h1>
        </div>
      </div>

      <div className="table-data">
        <div className="product-table">
          <div className="head">
            <h5>Product Lists</h5>
            <div className="search-bar">
              <IoSearch className='bx'/>
              <input type="search"
                     placeholder='Search here...'
                     className='form-control'
                     onChange={handleSearchChange} />
              <Tooltip placement="topLeft" title={'Add Product'}>
                  <button className='btn text-primary add-product'
                          onClick={showAddProductForm}>
                    <IoBagAdd/>
                  </button>
              </Tooltip> 
            </div>

          </div>
          <Modal title="Add Product Form"
            centered
            open={openAddProduct}
            onOk={submitAddedProduct}
            onCancel={handleCancel}
            width={800}
            confirmLoading={confirmLoading}
          >
            <div className="add-form">
              <form action="">
                <div className="upload-image">
                <ImgCrop aspect={1}>
                    <Upload
                      listType="picture-card"
                      fileList={ImageArray}
                      onPreview={handlePreview}
                      onChange={handleChange}
                      accept="image/png, image/jpeg"
                      beforeUpload={beforeUpload}
                      customRequest={dummyRequest}
                      style={{ width: '1200px', height: '1200px' }} 
                    >
                      {ImageArray.length >= 5 ? null : uploadButton}
                    </Upload>
                  </ImgCrop>
                </div>
                <div className="form-product">
                  <div className="info-field">
                    <div class="mb-2">
                      <label for="name" class="form-label">Product Name:</label>
                      <input type="text" class="form-control" id="name" placeholder="Product Name"
                      name='name'
                      onChange={handleInputChange}
                      value={addProductForm.name}
                      required
                      />
                    </div>
                    <div class="mb-2">
                      <label for="price" class="form-label">Price:</label>
                      <input type="number" class="form-control" id="price" placeholder="Product Price"
                        name='price'
                        onChange={handleInputChange}
                        value={addProductForm.price}
                        required
                      />
                    </div>
                    <div class="mb-2">
                      <label for="category" class="form-label">Category:</label>
                      <select class="form-select" id='category' onChange={handleCategoryChange}>
                        <option selected disabled>Open this select menu</option>
                        <option value="Single Product">Single Product</option>
                        <option value="Package">Package</option>
                      </select>
                    </div>
                  </div>
                  <div className="description-field">
                    <label for="description" class="form-label">Description:</label>
                    <textarea class="form-control" id="description" style={{height: '190px'}} placeholder='Description here...'
                      name='description'
                      onChange={handleInputChange}
                      value={addProductForm.description}
                      required
                    />
                  </div>
                </div>
                
                {addProductForm.category === 'Package' && (
                <div className="commission-field">
                  <div className="header">
                    <p>Input the Amount of Incentives for Each Level:</p>
                  </div>
                  <div className="input-group gap-3">
                    <div class="mb-2">
                        <label for="level1" class="form-label">Level 1 Commission:</label>
                        <input type="number" class="form-control" id="level1" placeholder="Level 1" min={1} 
                          name='level1'
                          onChange={handleComissionChange}
                          value={addProductForm.commissions.level1}
                        required/>
                    </div>
                    <div class="mb-2">
                        <label for="level2" class="form-label">Level 2 Commission:</label>
                        <input type="number" class="form-control" id="level2" placeholder="Level 2" min={1} 
                          name='level2'
                          onChange={handleComissionChange}
                          value={addProductForm.commissions.level2}
                        required/>
                    </div>
                    <div class="mb-2">
                        <label for="level3" class="form-label">Level 3 Commission:</label>
                        <input type="number" class="form-control" id="level3" placeholder="Level 3" min={1} 
                        name='level3'
                        onChange={handleComissionChange}
                        value={addProductForm.commissions.level3}
                        required/>
                    </div>
                  </div>
                  <div className="input-group gap-3">
                    <div class="mb-2">
                        <label for="level4" class="form-label">Level 4 Commission:</label>
                        <input type="number" class="form-control" id="level4" placeholder="Level 4" min={1} 
                        name='level4'
                        onChange={handleComissionChange}
                        value={addProductForm.commissions.level4}
                        required/>
                    </div>
                    <div class="mb-2">
                        <label for="level5" class="form-label">Level 5 Commission:</label>
                        <input type="number" class="form-control" id="level5" placeholder="Level 5" min={1} 
                        name='level5'
                        onChange={handleComissionChange}
                        value={addProductForm.commissions.level5}
                        required/>
                    </div>
                    <div class="mb-2">
                        <label for="level6" class="form-label">Level 6 Commission:</label>
                        <input type="number" class="form-control" id="level6" placeholder="Level 6" min={1} 
                        name='level6'
                        onChange={handleComissionChange}
                        value={addProductForm.commissions.level6}
                        required/>
                    </div>
                  </div>
                  <div className="input-group gap-3">
                    <div class="mb-2">
                        <label for="level7" class="form-label">Level 7 Commission:</label>
                        <input type="number" class="form-control" id="level7" placeholder="Level 7" min={1} 
                        name='level7'
                        onChange={handleComissionChange}
                        value={addProductForm.commissions.level7}
                        required/>
                    </div>
                    <div class="mb-2">
                        <label for="level8" class="form-label">Level 8 Commission:</label>
                        <input type="number" class="form-control" id="level8" placeholder="Level 8" min={1} 
                            name='level8'
                            onChange={handleComissionChange}
                            value={addProductForm.commissions.level8}
                        required/>
                    </div>
                    <div class="mb-2">
                        <label for="level9" class="form-label">Level 9 Commission:</label>
                        <input type="number" class="form-control" id="level9" placeholder="Level 9" min={1} 
                          name='level9'
                          onChange={handleComissionChange}
                          value={addProductForm.commissions.level9}
                        required/>
                    </div>
                  </div>
                  <div className="input-group gap-3">
                    <div class="mb-2">
                        <label for="level10" class="form-label">Level 10 Commission:</label>
                        <input type="number" class="form-control" id="level10" placeholder="Level 10" min={1} 
                        name='level10'
                        onChange={handleComissionChange}
                        value={addProductForm.commissions.level10}
                        required/>
                    </div>
                  </div>
                  <div className="single-products">
                      <p >Input Incentives for Each Single Product:</p>
                      <div className="input-group gap-3">
                        <div class="mb-2">
                            <label for="barleyCommission" class="form-label">Infin8 Pure Organic Barley:</label>
                            <input type="number" class="form-control" min={0} 
                            id="barleyCommission"  
                            name='barleyCommission'
                            onChange={handleInputChange}
                            value={addProductForm.barleyCommission}
                            required/>
                        </div>
                      </div>
                    </div>
                </div>
                )}
              </form>
            </div>
          </Modal>
          {filteredProducts.length === 0 ? (
            <NoDataFount/>
          ): (
          <div className="product-list">
            <table>
              <thead>
                <tr>
                  <th>Product ID</th>
                  <th>Product Name</th>
                  <th>Category</th>
                  <th>Price</th>
                </tr>
              </thead>
              <tbody>
              {filteredProducts
              .slice((currentPage - 1) * pageSize, currentPage * pageSize)
              .map((product) => (
                <tr key={product.id}
                    onClick={() => handleProductSelect(product)}
                    className={selectedProduct && selectedProduct.id === product.id ? 'active' : ''}>
                  <td style={{width: '10%'}}>{product.id}</td>
                  <td style={{width: '15%'}}>{product.name}</td>
                  <td style={{width: '10%'}}>{product.category}</td>
                  <td style={{width: '10%'}}>₱ {product.price}</td>
                </tr>
                ))}
              </tbody>
            </table>
            <div className="pagination-container">
              <Pagination
                  current={currentPage}
                  total={filteredProducts.length}
                  pageSize={pageSize}
                  onChange={handlePageChange}
              />
            </div>
          </div>
          )}
          
        </div>
        {selectedProduct && (
        <div className="product-info">
          <div className="head">
            <h5>Product ID: <strong>{selectedProduct.id}</strong></h5>
            <div className="action-btn">
              <Tooltip placement="topLeft" title={'Edit Product'}>
                <button className="btn text-primary" onClick={() => showEditProductForm(selectedProduct)}>
                  <TbShoppingBagEdit className='edit-btn' />
                </button>
              </Tooltip>
              <Tooltip placement="topLeft" title={'Delete Product'}>
                <Popconfirm
                    title="Are you sure to delete this product?"
                    onConfirm={() => handleDeleteProduct()}
                    open={openDeleteConfirm}
                    onCancel={handleCancel}
                    okButtonProps={{ loading: confirmLoading }}
                >
                  <button className="btn text-danger" onClick={showDeletePopConfirm}>
                    <FaTrashCan className='delete-btn'/>
                  </button>
                </Popconfirm>
              </Tooltip>
            </div>
          </div>

          <Modal title="Edit Product"
            centered
            open={openEditProduct}
            onOk={submitEditedProduct}
            onCancel={handleCancel}
            width={800}
            confirmLoading={confirmLoading}
          >
            <div className="edit-form">
              <form action="">
                <div className="upload-image">
                <ImgCrop aspect={1}>
                  <Upload
                      listType="picture-card"
                      onPreview={handlePreview}
                      onChange={handleProductChange}
                      accept="image/png, image/jpeg"
                      beforeUpload={beforeUpload}
                      fileList={tempFileList}
                      customRequest={dummyRequest}
                      onRemove={(file) => {
                          setTempFileList(tempFileList.filter((item) => item.uid !== file.uid));
                      }}
                  >
                      {tempFileList.length >= 5 ? null : uploadButton}
                  </Upload>
              </ImgCrop>
                </div>
                <div className="form-product">
            <div className="info-field">
              <div className="mb-2">
                <label htmlFor="name" className="form-label">Product Name:</label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  value={selectedProduct.name}
                  onChange={handleEditChange}
                />
              </div>
              <div className="mb-2">
                <label htmlFor="price" className="form-label">Price:</label>
                <input
                  type="number"
                  className="form-control"
                  id="price"
                  value={selectedProduct.price}
                  onChange={handleEditChange}
                />
              </div>
              <div className="mb-2">
                <label htmlFor="category" className="form-label">Category:</label>
                <select
                  className="form-select"
                  id='category'
                  value={selectedProduct.category}
                  onChange={handleEditChange}
                >
                  <option disabled>Select Category</option>
                  <option value="Single Product">Single Product</option>
                  <option value="Package">Package</option>
                </select>
              </div>
            </div>
            <div className="description-field">
              <label htmlFor="description" className="form-label">Description:</label>
              <textarea
                className="form-control"
                style={{ height: '190px' }}
                id="description"
                placeholder='Description here...'
                value={selectedProduct.description}
                onChange={handleEditChange}
              />
            </div>
          </div>
                {selectedProduct && selectedProduct.category === 'Package' && selectedProduct.commissions && (
                    <div className="commission-field">
                        <div className="input-group gap-3">
                            {Object.keys(selectedProduct.commissions).map((key, index) => (
                                <div className="mb-2" key={index}>
                                    <label htmlFor={key} className="form-label">{key} Commission:</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        id={key}
                                        value={selectedProduct.commissions[key]}
                                        onChange={(e) => handleCommissionChange(key, e.target.value)}
                                        placeholder={key}
                                        min={1}
                                        required
                                    />
                                </div>
                            ))}
                            {selectedProduct.barleyCommission !== undefined && (
                                <div className="mb-2">
                                    <label htmlFor='barleyCommission' className="form-label">BarleyCommission:</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="barleyCommission"
                                        value={selectedProduct.barleyCommission}
                                        onChange={handleEditChange}
                                        placeholder='barleyCommission'
                                        min={1}
                                        required
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                )}
              </form>
            </div>
          </Modal>

          <div className="info-content">
            <div className="img-holder">
              
              <img src={selectedProduct.images[0].url} alt="" />
            </div>
            <div className="other-info">
              <h4>{selectedProduct.name}</h4>
              <span className='mt-3'>
                <strong>Description:</strong>
                <pre>{selectedProduct.description}</pre>
              </span>
              <span>
                <h6><strong>Price: </strong>₱ {selectedProduct.price}.00</h6>
              </span>
              <span>
                <h6><strong>Category: </strong>{selectedProduct.category}</h6>
              </span>
              {selectedProduct && selectedProduct.category === 'Package' && selectedProduct.commissions && (
              <span>
                <h6>
                  <strong>Commissions: </strong>
                  <ul className="lists">
                  {Object.entries(selectedProduct.commissions).map(([key, value]) => (
                      <li key={key}><strong>{key}:</strong> ₱ {value}.00</li>
                  ))}
                  </ul>
                </h6>
              </span>
              )}
              {selectedProduct.barleyCommission && selectedProduct.category === 'Package' ? (
              <span>
                <h6><strong>Barley Commission: </strong>₱ {selectedProduct.barleyCommission}.00</h6>
              </span>
              ) : (<></>)}
            </div>
          </div>
        </div>
        )}
      </div>
    </main>
  )
}

export default Products;