import React, { useState, useEffect } from 'react'
import { message } from 'antd';
import { regions, provinces, cities, barangays } from 'select-philippines-address';
import { useCartStore } from '../../DataTest/cart-store';
import { child, get, ref, serverTimestamp, set } from 'firebase/database';
import { db } from '../../firebase/config';

const PurchaseForm = ({onClose}) => {
  const {cart, clearCart} = useCartStore((state) => ({
    cart: state.cart,
    clearCart:state.clearCart,
}))
const [barleryCommissions, setBarleyCommission] = useState(0)
  const [shippingForm, setShippingForm] = useState({
    fullname: '',
    email: '',
    contact: '',
    payment: '',
    region: '',
    province: '',
    city: '',
    brgy: '',
    street: '',
    landmark: '',
    shippingFee: 0.0,
  });
  const [isFormFilled, setIsFormFilled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [regionData, setRegionData] = useState([]);
  const [provinceData, setProvinceData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [barangayData, setBarangayData] = useState([]);
  const [shippingFee, setShippingFee] = useState(0);
  const [InvoiceID, setInvoiceID] = useState('');
  
  const fetchRegions = () => {
    regions()
      .then(response => {
        setRegionData(response);
        setShippingForm(prevData => ({ ...prevData, region: response[null]?.region_code || '' }));
      })
      .catch(error => console.error('Error fetching regions:', error));
  };
  const fetchProvinces = region_code => {
    provinces(region_code).then(response => {
      setProvinceData(response);
    });
  };
  const fetchCities = provinceCode => {
    cities(provinceCode).then(response => {
      setCityData(response);
    });
  };
  const fetchBarangays = cityCode => {
    barangays(cityCode).then(response => {
      setBarangayData(response);
    });
  };
  useEffect(() => {
    fetchRegions();
  }, []);
  const onChangeRegion = (e) => {
    const selectedRegionCode = e.target.value;
    const selectedRegion = regionData.find((item) => item.region_code === selectedRegionCode);
    const shippingFee = calculateShippingFee(selectedRegionCode);
    setShippingFee(shippingFee);

    setShippingForm((prevShippingForm) => ({
      ...prevShippingForm,
      region: selectedRegionCode,
      regionName: selectedRegion ? selectedRegion.region_name : '',
      shippingFee: shippingFee,
    }));

    fetchProvinces(selectedRegionCode);
    setProvinceData([]);
    setCityData([]);
    setBarangayData([]);
  };
  const onChangeProvince = (e) => {
    const selectedProvinceCode = e.target.value;
    const selectedProvince = provinceData.find((item) => item.province_code === selectedProvinceCode);
    setShippingForm({
      ...shippingForm,
      province: selectedProvinceCode,
      provinceName: selectedProvince ? selectedProvince.province_name : '',
    });
    fetchCities(selectedProvinceCode);
    setCityData([]); 
    setBarangayData([]);
  };
  const onChangeCity = (e) => {
    const selectedCityCode = e.target.value;
    const selectedCity = cityData.find((item) => item.city_code === selectedCityCode);
    setShippingForm({
      ...shippingForm,
      city: selectedCityCode,
      cityName: selectedCity ? selectedCity.city_name : '',
    });
    fetchBarangays(selectedCityCode);
    setBarangayData([]); 
  };
  const onChangeBarangay = (e) => {
    const selectedBarangayCode = e.target.value;
    const selectedBarangay = barangayData.find((item) => item.brgy_code === selectedBarangayCode);
    setShippingForm({
      ...shippingForm,
      brgy: selectedBarangayCode,
      brgyName: selectedBarangay ? selectedBarangay.brgy_name : '',
    });
  };
  const calculateShippingFee = (selectedRegionCode) => {
    const isNCR = selectedRegionCode === '13';
    if (isNCR) {
      return 100;
    } else  {
      return 150;
    }
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-PH', { style: 'currency', currency: 'PHP' }).format(amount);
  };

   // Function to handle form change
   const handleFormChange = (e) => {
    const { name, value } = e.target;
    setShippingForm(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  useEffect(() => {
    console.log('store')
    const checkFormFilled = () => {
      const requiredFields = ['fullname', 'email', 'contact', 'payment', 'region', 'province', 'city', 'brgy'];
      return requiredFields.every(field => !!shippingForm[field]);
    };
    setIsFormFilled(checkFormFilled());
  }, [shippingForm]);

  const getCountFromFirebase = async (node) => {
    try {
        if (!node || typeof node !== 'string') {
            throw new Error('Invalid node parameter');
        }

        const nodeRef = ref(db, node);
        const snapshot = await get(nodeRef);

        if (snapshot.exists()) {
            const data = snapshot.val();
            
            if (Array.isArray(data)) {
                return data.length;
            } else if (typeof data === 'object' && data !== null) {
                // Assuming data is an object, count its keys
                return Object.keys(data).length;
            } else {
                throw new Error('Snapshot data is not in a countable format');
            }
        } else {
            return 0; // Return 0 if node doesn't exist
        }
    } catch (error) {
        console.error('Error retrieving count from Firebase:', error);
        // Handle error accordingly
        return null;
    }
};

const createIDGenerator = async (prefix) => {
  try {
      let letter = 'A';
      let count = await getCountFromFirebase('orders');

      if (count === null) {
          count = 0;
      }
      if (count === 999999) {
        count = 1;
        letter = String.fromCharCode(letter.charCodeAt(0) + 1);
      } else {
          count++;
      }
      const numericPart = count.toString().padStart(6, '0');
      setInvoiceID(`INVC-${numericPart}${letter}`)
      const id = `${prefix}-${numericPart}${letter}`;
      return id;
  } catch (error) {
      console.error('Error generating ID:', error);
      // Handle the error accordingly
      return null;
  }
};

  // Function to handle purchase
  const handlePurchase = async (e) => {
    // e.preventDefault()
    setIsLoading(true);

    console.log(await createIDGenerator('LYLT'))
    try {
      await new Promise(resolve => setTimeout(resolve, 1500));
      const orderID = await createIDGenerator('LYLT');
      const timestamp = serverTimestamp(); 
      const orderData = {        
        invoice: {
        From: {
          Name: 'LOYALISTA ONLINE SHOP CORP.',
          Address: 'No. 39, Commercial Unit 2nd Floor Left Wing, 39 Sct. Ybardolaza, Quezon City, Metro Manila',
          Phone: '02-8534-7369',
          Email: 'loyalistaonlineshopcorp@gmail.com',
        },
        To: {
          Name: shippingForm.fullname,
          Address: shippingForm.landmark+", "+shippingForm.brgyName+", "+ shippingForm.cityName+", "+shippingForm.province +", "+shippingForm.regionName,
          Phone: shippingForm.contact,
          Email: shippingForm.email ,
        },
        InvoiceNumber: InvoiceID,
        OrderID: orderID,
        PaymentMethod: shippingForm.payment,
        items: cart.map((item) => ({
          productId: item.id,
          name: item.name,
          quantity: item.quantity,
          price: item.price,
          TotalPrice: item.price * item.quantity,
          barleyCommission:item.barleyCommission,
          commissions: item.commissions || null,
          image:item.image,
          category:item.category
        })),
        AmountDue: {
          Subtotal: calculateTotalPrice(),
          Shipping:shippingFee,
          Total: calculateTotalPrice() + shippingFee,
        },
      },
      address: {
        fullAddress:shippingForm.brgyName+", "+ shippingForm.cityName+", "+shippingForm.province,
        street: shippingForm.street,
        landmark: shippingForm.landmark,
      },
        user: 'N/A',
        seller: 'Company',
        status: "Pending",
        username:'',
        shopType:'CompanyStore',
        total: calculateTotalPrice(),
        companySales:calculateTotalPrice(),
        timestamp: timestamp,
        OrderID: orderID,
        MemberID: 'N/A',
        barley: 0,
        customer:{
          Email:shippingForm.email,
          Fullname:shippingForm.fullname,
          ContactNum:shippingForm.contact,
          Region: shippingForm.regionName
        },
        items: cart.map((item) => ({
          productId: item.id,
          name: item.name,
          quantity: item.quantity,
          price: item.price,
          TotalPrice: item.price * item.quantity,
          barleyCommission:item.barleyCommission,
          commissions: item.commissions || null,
          image:item.image,
          category:item.category
        })),

      };
      await set(ref(db, `orders/${orderID}`), orderData).then(async()=>{
        const subject = `Order ${orderID} is Successful wait for Admin Approval`
        const email = shippingForm.email
        const response = await fetch('https://loyalista-mailer-aa5b8a82053e.herokuapp.com/placeOrder', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email, orderData , subject }),
        });
        if (response.ok) {
          
          const subject = `We have new order received!`
          const email = 'vincesahagun@yahoo.com'
          const bossresponse = await fetch('https://loyalista-mailer-aa5b8a82053e.herokuapp.com/NewplaceOrder', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email, orderData , subject }),
          });
          if (bossresponse.ok) {
            console.log('Email sent successfully');
          } else {
            console.error('Failed to send email');
          }

        } else {
          setIsLoading(true)
          console.error('Failed to send email');
        }
      })
    setTimeout(() => {
      setIsLoading(false);
      clearCart()
      onClose();
      setTimeout(() => { 
        message.success("Order Successfully!");
        setShippingForm({
          fullname: '',
          email: '',
          contact: '',
          payment: '',
          region: '',
          province: '',
          city: '',
          brgy: '',
          street: '',
          landmark: '',
          shippingFee: 0.0,
        });
      }, 800);
    }, 2000); // Delay for 2 seconds
      message.success('Ordered Successfully');
      setIsLoading(false); 
    } catch (error) {
      console.error('Error placing order:', error);
      setIsLoading(false);
    }
  };
  const calculateTotalPrice = () => {
    let totalPrice = 0;
    cart.forEach(product => {
        totalPrice += product.price * product.quantity;
    });
    return totalPrice;
};
  return (
    <div className='container-fluid'>
      <section className="center-form">
        <form action="">
          <div className="row">
            {/* list of order */}
            <div className="col-12 col-lg-4">
              <div className='table-responsive'>
                <table className="table table-striped">
                  <caption>List of orders</caption>
                  <thead>
                    <tr>
                      <th></th>
                      <th>Items</th>
                      <th>Quantity</th>
                      <th>Price</th>
                      <th>SubTotal</th>
                    </tr>
                  </thead>
                  <tbody>
                    { cart.map((product, index) => (
                      <tr>
                      <td style={{maxWidth: '80px'}}>
                        <div className="img-holder" style={{width: '50px', height: '50px'}}>
                          <img src={product.image} alt="" style={{width: '100%', height: 'auto'}}/>
                        </div>
                      </td>
                      <td>{product.name}</td>
                      <td>{product.quantity}</td>
                      <td>₱{parseFloat(product.price).toFixed(2).toLocaleString()}</td>
                      <td>₱{(product.price * product.quantity).toFixed(2).toLocaleString()}</td>
                    </tr>
                    ))}
                  </tbody>
                </table>
                <div className="subtotal">
                  <p className="lead">Amount Due</p>
                  <table className='table'>
                    <tr>
                      <th scope="col">Subtotal:</th>
                      <td>₱{calculateTotalPrice().toLocaleString()}</td>
                    </tr>
                    <tr>
                      <th>Shipping fee:</th>
                      <td>{formatCurrency(shippingFee)}</td>
                    </tr>
                    <tr>
                      <th>Total:</th>
                      <td>₱{(shippingFee+calculateTotalPrice()).toLocaleString()}</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>

            {/* Form Field */}
            <div className="col-12 col-lg-8">
              <div className="card mb-2">
                <div className="card-header border-0">
                  <h5>Contact Info</h5>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-12 col-sm-6">
                      <div className="form-floating mb-3">
                        <input
                          type="text"
                          className="form-control"
                          name="fullname"
                          id="fullname"
                          placeholder=""
                          value={shippingForm.fullname}
                          onChange={handleFormChange}
                          required
                        />
                        <label htmlFor="fullname">Recipient Name <small className='text-danger'>(Full Name)</small></label>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="form-floating mb-3">
                        <input
                          type="email"
                          className="form-control"
                          name="email"
                          id="email"
                          placeholder=""
                          value={shippingForm.email}
                          onChange={handleFormChange}
                          required
                        />
                        <label htmlFor="email">Email Address</label>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="form-floating mb-3">
                        <input
                          type="text"
                          className="form-control"
                          name="contact"
                          id="contact"
                          placeholder=""
                          value={shippingForm.contact}
                          onChange={handleFormChange}
                          required
                        />
                        <label htmlFor="contact">Contact Number</label>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="form-floating mb-3">
                        <select className="form-select"
                           name="payment"
                           id="payment" 
                           value={shippingForm.payment}
                           onChange={handleFormChange}
                           required>
                          <option value='' disabled>
                            Select Payment Method
                          </option>
                          <option value="COD">Cash on delivery</option>
                          <option value="Bank">Bank Transfer</option>
                        </select>
                        <label htmlFor="payment">Payment Method</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card mb-2">
                <div className="card-header border-0">
                  <h5>Shipping Address</h5>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12 col-sm-6">
                        <div className="form-floating mb-3">
                          <select className="form-select" 
                             name="region"
                             id="region"
                             value={shippingForm.region}
                             onChange={onChangeRegion}
                             required>
                            <option value='' disabled>
                              Select Region
                            </option>
                            {regionData.map(item => (
                              <option
                                key={item.region_code}
                                value={item.region_code}
                              >
                                {item.region_name}
                              </option>
                            ))}
                          </select>
                          <label htmlFor="region">Region</label>
                        </div>
                      </div>
                      <div className="col-12 col-sm-6">
                        <div className="form-floating mb-3">
                          <select className="form-select" 
                             name="province"
                             id="province"
                             value={shippingForm.province}
                             onChange={onChangeProvince}
                             required>
                            <option value='' disabled>
                              Select Province
                            </option>
                            {provinceData.map(item => (
                              <option
                                key={item.province_code}
                                value={item.province_code}
                              >
                                {item.province_name}
                              </option>
                            ))}
                          </select>
                          <label htmlFor="province">Province</label>
                        </div>
                      </div>
                      <div className="col-12 col-sm-6">
                        <div className="form-floating mb-3">
                          <select className="form-select" 
                             name="city"
                             id="city"
                             value={shippingForm.city}
                             onChange={onChangeCity}
                             required>
                            <option value='' disabled>
                              Select City / Municipality
                            </option>
                            {cityData.map(item => (
                              <option 
                                key={item.city_code} 
                                value={item.city_code}
                              >
                                {item.city_name}
                              </option>
                            ))}
                          </select>
                          <label htmlFor="city">City / Municipal</label>
                        </div>
                      </div>
                      <div className="col-12 col-sm-6">
                        <div className="form-floating mb-3">
                          <select className="form-select" 
                             name='brgy'
                             id="brgy"
                             value={shippingForm.brgy}
                             onChange={onChangeBarangay}
                             required>
                            <option value='' disabled>
                              Select Barangay
                            </option>
                            {barangayData.map(item => (
                              <option 
                                key={item.brgy_code} 
                                value={item.brgy_code}
                              >
                                {item.brgy_name}
                              </option>
                            ))}
                          </select>
                          <label htmlFor="brgy">Barangay</label>
                        </div>
                      </div>
                      <div className="col-12 col-sm-6">
                        <div className="form-floating mb-3">
                          <input
                            type="text"
                            className="form-control"
                            name="street"
                            id="street"
                            placeholder=""
                            value={shippingForm.street}
                            onChange={handleFormChange}
                          />
                          <label htmlFor="street">Street</label>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-floating mb-3">
                          <textarea 
                            class="form-control" 
                            placeholder="Leave a comment here" 
                            id="landmark" 
                            name="landmark"
                            value={shippingForm.landmark}
                            onChange={handleFormChange}
                            style={{height: '100px'}}>
                          </textarea>
                          <label for="landmark">Landmark</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Submit Button */}
            <div className="col-12 d-flex justify-content-between modal-footer">
              <div className="total-cost">
                <h3>Total: ₱{(shippingFee+calculateTotalPrice()).toLocaleString()} </h3>
              </div>
              <div className="checkout-btn">
                {/* Remove type='submit' from button */}
                 <button className='btn btn-success' 
                    onClick={handlePurchase} 
                    disabled={!isFormFilled || isLoading}>
                  {isLoading && (
                    <span className="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true"></span>
                  )}
                  {isLoading ? 'Processing...' : 'Proceed Purchase'}
                </button>
              </div>
            </div>

          </div>
        </form>
      </section>
    </div>
  )
}

export default PurchaseForm;