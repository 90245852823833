import React, { useEffect, useState } from 'react';
import './MyWallet.css';
import GCash from '../../../Assets/images/GCash-Emblem.png';
import Palawan from '../../../Assets/images/palawan-logo.png';
import MasterCard from '../../../Assets/images/mastercard.png';
import { FaWallet, FaCashRegister } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";
import { GrTransaction } from "react-icons/gr";
import { Pagination, message, Drawer } from 'antd';
import { useAuth } from '../../../contexts/authContext';
import { Navigate } from 'react-router-dom';
import { get, ref, set,serverTimestamp, child, query, orderByChild, equalTo } from 'firebase/database';
import moment from 'moment';
import { db } from '../../../firebase/config';

const MyWallet = () => {
    const { userLoggedIn } = useAuth();
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 5;

    const currentDay = new Date().getDay();
    const isAvailable = currentDay === 1;
    const [enteredAmount, setEnteredAmount] = useState('');
    const [walletBalance, setWalletBalance] = useState(0);
    const [selectedMethod, setSelectedMethod] = useState('GCash');
    const [paymentSelection, setPaymentSelection] = useState(true);
    const [showPaymentInfo, setPaymentInfo] = useState(false);
    const [paymentDetails, setPaymentDetails] = useState(null);
    const [isLoadingMethod, setIsLoadingMethod] = useState(false);
    const [isLoadingCashbond, setIsLoadingCashbond] = useState(false);
    const [isLoadingCashout, setIsLoadingCashout] =useState(false);
    const [isRequestedCashbond, setIsRequestedCashbond] = useState(false);
    const [ReturnedCharges, setReturnCharges] =useState(0)
    const [cashbondStatus, setcashbondStatus] = useState()
    const [CashBondBalance, setCashBondBalance] = useState(0)
    const [CashbondRequest, setCashbondRequest] = useState(0)
    const [NetWork, setNetWork] =useState([])
    const [userInformation, setUserInfo] = useState();
    const [userFullName, setFullname] = useState();
    const [CheckPending, SetPending] = useState(true);
    const [commissionHistory, setCommissionHistory] = useState({});
    const [openCommissionDrawer, setOpenCommissionDrawer] = useState(false);
    const user = JSON.parse(localStorage.getItem('user'))

    const [GcashInfo, setGcashInfo] = useState({
        AccountName: '',
        GcashNumber: ''
      })
      const [PalawanInfo, setPalawanInfo] = useState({
        FullName: '',
        PhoneNumber: ''
      })
      const [BankInfo, setBankInfo] = useState({
        BankName: '',
        AccountName: '',
        AccountNumber:'',
        PhoneNumber: ''
      })
      const [requestTable, setRequestTable] = useState([]);
     
    useEffect(() => {
        const requestFetch = async () => {
          try {
            // Assuming 'request' is the root node for requests in your database
            const requestRef = ref(db, 'request');
    
            // Query only for requests where user is user.uid
            const userQuery = query(requestRef, orderByChild('user'), equalTo(user?.uid));
    
            const snapshot = await get(userQuery);
    
            if (snapshot.exists()) {
              // Convert the snapshot data to an array
              const requestArray = Object.entries(snapshot.val())
                .map(([requestID, requestData]) => ({
                  requestID,
                  ...requestData
                }))
                .sort((a, b) => b.timestamp - a.timestamp);
    
              setRequestTable(requestArray);

            } else {
              setRequestTable([]);
            }
          } catch (error) {
            console.error(error);
          }
        };
    
        requestFetch();
      }, [isLoadingCashout]);



    useEffect(() => {
        const fetchData = async () => {
            try {
                const snapshot = await get(child(ref(db), 'users'));
                if (snapshot.exists()) {
                    const userData = snapshot.val();
                    const userArray = Object.values(userData);
                    const chartDataFromFirebase = [];

                    const findReferrals = (username, level) => {
                        if (level > 10) return; // Adjust level limit as needed

                        userArray.forEach((creds) => {
                            if (creds.referral === username) {
                                chartDataFromFirebase.push({
                                    name: creds.username,
                                    parent: creds.referral,
                                    level: level,
                                });
                                findReferrals(creds.username, level + 1);
                            }
                        });
                    };

                    userArray.forEach((creds) => {
                        if (creds.email === user.email) {
                            chartDataFromFirebase.push({
                                name: creds.username,
                                parent: null,
                                level: 1,
                            });
                            findReferrals(creds.username, 2);
                        }
                    });

                    setNetWork([...chartDataFromFirebase]);
                } else {
                  alert('No data available');
                }
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        const fetchCommissionsByLevel = async () => {
            let barleyCommissions = 0;
            let highestBarleyCommission = 0;
            let returnedShipping = 0;
            const comissionsPerOrder = {}; // Declare comissionsPerOrder here
    
            try {
                const ordersRef = ref(db, 'orders');
                const snapshot = await get(ordersRef);
    
                if (!snapshot.exists()) {
                    console.warn('No orders found.');
                    return;
                }
    
                const orderItemsArray = Object.entries(snapshot.val())
                    .filter(([orderId, orderData]) => orderData.status === 'Completed')
                    .map(([orderId, orderData]) => ({
                        orderId: orderId,
                        ...orderData,
                    }));
    
                const orderReturnedItemsArray = Object.entries(snapshot.val())
                    .filter(([orderId, orderData]) => orderData.user === user.uid && orderData.status === 'Returned')
                    .map(([orderId, orderData]) => ({
                        orderId: orderId,
                        ...orderData,
                    }))
                    .sort((a, b) => b.timestamp - a.timestamp);
    
                orderReturnedItemsArray.forEach((order) => {
                    returnedShipping += order.invoice.AmountDue.Shipping;
                });
    
                const commissionsByLevel = {};
    
                // Function to recursively find commissions for users at each level
                const findCommissionsForLevel = (user, level) => {
                    if (!(level in commissionsByLevel)) {
                        commissionsByLevel[level] = 0;
                    }
    
                    orderItemsArray.forEach(order => {
                        order.items.forEach((item) => {
                            if (item.category === 'Package' && item.barleyCommission !== undefined) {
                                const itemBarleyCommission = item.barleyCommission;
                                if (itemBarleyCommission > highestBarleyCommission) {
                                    highestBarleyCommission = itemBarleyCommission;
                                }
                            }
                        });
                    });
    
                    // Find orders for this user and sum the corresponding level commissions
                    orderItemsArray.forEach((order, orderIndex) => {
                        if (order.username === user.name) {
                            if (order.shopType !== 'ClientStore') {
                                // Non-ClientStore orders
                                if (order.items) {
                                    let totalCommission = 0;
                                    let orderDetails = {
                                        customer: order.customer.Fullname,
                                        items: {},
                                        timestamp: order.timestamp,
                                        totalCommission: 0,
                                        level: level,
                                    };
                                    order.items.forEach((item, itemIndex) => {
                                        const commissionLevel = `level${level}`;
                                        if (item.commissions && item.commissions[commissionLevel]) {
                                            commissionsByLevel[level] += item.commissions[commissionLevel] * item.quantity;
                                            const commissionAmount = item.commissions[commissionLevel] * item.quantity;
                                            totalCommission += commissionAmount;
                                            orderDetails.items[item.name] = {
                                                commissions: commissionAmount,
                                                commissionLevel: level
                                            };
                                        } else {
                                            console.warn(`Item ${itemIndex + 1} in Order ${orderIndex + 1} does not have a valid commission property at level ${level}.`);
                                        }
                                        if (item.category === 'Single Product' && item.name === 'Infin8 Pure Organic Barley') {
                                            barleyCommissions = highestBarleyCommission * item.quantity;
                                        }
                                    });
                                    orderDetails.totalCommission = totalCommission;
                                    comissionsPerOrder[order.OrderID] = orderDetails;
                                } else {
                                    console.warn(`Order ${orderIndex + 1} does not have valid items.`);
                                }
                            } else {
                                // ClientStore orders
                                if (level === 1) {
                                    return;
                                }
                                if (order.items) {
                                    let totalCommission = 0;
                                    let orderDetails = {
                                        customer: order.customer.Fullname,
                                        items: {},
                                        timestamp: order.timestamp,
                                        totalCommission: 0,
                                        level: level,
                                    };
                                    order.items.forEach((item, itemIndex) => {
                                        const commissionLevel = `level${level - 1}`;
                                        if (item.commissions && item.commissions[commissionLevel]) {
                                            commissionsByLevel[level] += item.commissions[commissionLevel] * item.quantity;
                                            const commissionAmount = item.commissions[commissionLevel] * item.quantity;
                                            totalCommission += commissionAmount;
                                            orderDetails.items[item.name] = {
                                                commissions: commissionAmount,
                                                commissionLevel: level - 1
                                            };
                                        } else {
                                            console.warn(`Item ${itemIndex + 1} in Order ${orderIndex + 1} does not have a valid commission property at level ${level}.`);
                                        }
                                    });
                                    orderDetails.totalCommission = totalCommission;
                                    comissionsPerOrder[order.OrderID] = orderDetails;
                                } else {
                                    console.warn(`Order ${orderIndex + 1} does not have valid items.`);
                                }
                            }
                        }
                    });
                };
    
                // Iterate through the user array and find commissions for each user at their respective levels
                NetWork.forEach(user => {
                    findCommissionsForLevel(user, user.level);
                });
                // Calculate the sum of commissions for each level
                const sumOfCommissionsByLevel = Object.values(commissionsByLevel).reduce((acc, cur) => acc + cur, 0);
    
                try {
                    const pendingRef = ref(db, 'request');
                    const pendingSnapshot = await get(pendingRef);
                    let totalApprovedAmount = 0;
    
                    if (pendingSnapshot.exists()) {
                        const requestData = pendingSnapshot.val();
                        Object.values(requestData).forEach((request) => {
                            if (request.user === user.uid && request.status === 'Approved') {
                                totalApprovedAmount += request.amount || 0;
                            }
                        });
                    }
    
                    setWalletBalance(sumOfCommissionsByLevel + barleyCommissions);
                } catch (error) {
                    console.error('Error fetching pending requests:', error);
                }
    
                setReturnCharges(returnedShipping);
            } catch (error) {
                console.error('Error fetching orders:', error);
            }
            setCommissionHistory(comissionsPerOrder);
        };
    
        fetchCommissionsByLevel();
    }, [NetWork]);
    
    
    
    useEffect(() => {
        const cashbondInfo = async () => {
          try {
            const cashbondInfoRef = ref(db, 'cashbond');
            const snapshot = await get(cashbondInfoRef);
      
            if (snapshot.exists()) {
              const cashbondArray = Object.entries(snapshot.val())
                .filter(([cashbondID, cashbondData]) => cashbondData.user === user.uid)
                .map(([cashbondID, cashbondData]) => ({
                  cashbondID: cashbondID,
                  amount: cashbondData.amount,
                  ...cashbondData,
                }));
              if (cashbondArray.length > 0) {
                const totalCashBondBalance = cashbondArray
                  .filter(
                    (cashbond, index) =>
                      cashbond.status === 'Approved' &&
                      !isNaN(parseFloat(cashbond.amount))
                  )
                  .reduce((sum, cashbond) => sum + parseFloat(cashbond.amount), 0);
                setCashBondBalance(totalCashBondBalance);
                const lastStatus = cashbondArray[cashbondArray.length - 1].status;
                setcashbondStatus(lastStatus);
              } else {
                setcashbondStatus(null);
                setCashBondBalance(0);
              }
            } else {
              setcashbondStatus(null);
              setCashBondBalance(0);
            }
          } catch (error) {
            console.error(error);
          }
        };
      
        cashbondInfo();
      }, [isLoadingCashbond]);
      
      useEffect(() => {
        const getUserInfo = async () => {
          try {
            const userInfoRef = ref(db, `users/${user.uid}`);
            const snapshot = await get(userInfoRef);
      
            if (snapshot.exists()) {
              const userData = snapshot.val();
              setUserInfo(userData);
              setFullname(`${userData.firstname} ${userData.middlename} ${userData.lastname}`)
            } else {
              setUserInfo(null);
              setFullname(null)
            }
          } catch (error) {
            console.error(error);
          }
        };
  
        getUserInfo();
      }, []); 

    useEffect(() => {
        const fetchMethod = async () => {
          try {
    
            const methodRef = ref(db, `cashout_method/${user.uid}`);
            const snapshot = await get(methodRef);
      
            if (snapshot.exists()) {
              setPaymentSelection(false);
              setPaymentInfo(true);
              setPaymentSelection(false);

              const methods = snapshot.val();
              setSelectedMethod(methods.Method);
                if (methods.Method === 'GCash') {
                  setGcashInfo({
                   AccountName:methods.AccountName
                  ,GcashNumber: methods.GcashNumber
                });
                setPaymentDetails(GcashInfo)
                }
                if (methods.Method === 'Palawan') {
                  setPalawanInfo({
                   FullName:methods.Recipient
                  ,PhoneNumber: methods.PhoneNumber
                });
                setPaymentDetails(PalawanInfo)
              }
              if (methods.Method === 'Bank-Account') {
                setBankInfo({
                  BankName:methods.BankName,
                  AccountName:methods.Recipient,
                  AccountNumber:methods.BankAccountNumber,
                  PhoneNumber: methods.PhoneNumber
              });
              setPaymentDetails(BankInfo)
              }
            }
          } catch (error) {
            console.error(error);
          }
          try {
            const pendingRef = ref(db, 'request');
            const snapshot = await get(pendingRef);
            if (snapshot.exists()) {
              const requestData = snapshot.val();
              const userUid = user.uid;
              SetPending(true);
              Object.keys(requestData).forEach((requestID) => {
                const request = requestData[requestID];
                if (request.user === userUid && request.status === 'Pending') {
                  SetPending(false);
                }
              });
            }
          } catch (error) {
            console.error(error);
          }
        };
        fetchMethod();
    
      }, [PalawanInfo, GcashInfo,BankInfo]);

    const formatBankAccountNumber = (event) =>{
        const input = event.target;
        const value = input.value.replace(/\D/g, '');
        const formattedValue = value.replace(/(\d{4})/g, '$1 ').trim(); 
        input.value = formattedValue;
      }
    
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleAmountChange = (e) => {
        const enteredValue = parseFloat(e.target.value);
        if (!isNaN(enteredAmount) && enteredValue > walletBalance) {
          setEnteredAmount(walletBalance);
        } else {
          setEnteredAmount(e.target.value);
        }
      };
    const handleMethodSelection = (method) => {
        setSelectedMethod(method);
        setPaymentDetails(null); 
    };

    const handleMethodSubmit = async (e) => {
        e.preventDefault();
        setIsLoadingMethod(true);
        setPaymentInfo(false);

        // Assuming you get the form data here
        const form = e.target;
        const formData = new FormData(form);
        const details = Object.fromEntries(formData.entries());
        const CashOutInfo = {  
            BankName: BankInfo.BankName || '',
            Method:selectedMethod,
            Recipient: BankInfo.AccountName || GcashInfo.AccountName || PalawanInfo.FullName,
            BankAccountNumber: BankInfo.AccountNumber || 'N/A',
            PhoneNumber: BankInfo.PhoneNumber || GcashInfo.GcashNumber || PalawanInfo.PhoneNumber,
          };
          await set(ref(db, `cashout_method/${user.uid}`), CashOutInfo);

        setTimeout(() => {
            setIsLoadingMethod(false);
            setPaymentSelection(false);
            setPaymentInfo(true);
            setPaymentDetails(details); // Save the payment details
            setTimeout(() => {
                message.success("Your payment method is processed!");
            }, 800);
        }, 2000);
    };

    const handleOpenRequest = () => {
        setIsRequestedCashbond(true);
    };

    const handleCloseRequest = () => {
        setIsRequestedCashbond(false);
    };

    const handleSentRequest = async(e) => {
        e.preventDefault();
        setIsLoadingCashbond(true);
        const cashBond = ref(db, `cashbond`);
        try {
          const cashBondSnapshot = await get(cashBond);
      
          let cashBondItemsCount = 0;
      
          if (cashBondSnapshot.exists()) {
            cashBondItemsCount = Object.keys(cashBondSnapshot.val()).length;
          }
      
          const timestamp = serverTimestamp();
          const cashBondID = generateCashBondID(cashBondItemsCount);
      
          set(ref(db, `cashbond/${cashBondID}`), {
            user: user.uid,
            date: timestamp,
            sender: userFullName,
            email: userInformation?.email,
            phone: userInformation?.contact,
            amount: CashbondRequest,
            status: 'Pending',
          });
        } catch (error) {
          console.error('Error fetching or updating cash bond data:', error);
        }
        setTimeout(() => {
            setIsLoadingCashbond(false);
            setIsRequestedCashbond(false);

            setTimeout(() => {
                message.success("RTS Load Request has been sent!");
            }, 800);
        }, 2000);
    };
    const getCountFromFirebase = async (node) => {
        try {
            if (!node || typeof node !== 'string') {
                throw new Error('Invalid node parameter');
            }
    
            const nodeRef = ref(db, node);
            const snapshot = await get(nodeRef);
    
            if (snapshot.exists()) {
                const data = snapshot.val();
                
                if (Array.isArray(data)) {
                    return data.length;
                } else if (typeof data === 'object' && data !== null) {
                    // Assuming data is an object, count its keys
                    return Object.keys(data).length;
                } else {
                    throw new Error('Snapshot data is not in a countable format');
                }
            } else {
                return 0; // Return 0 if node doesn't exist
            }
        } catch (error) {
            console.error('Error retrieving count from Firebase:', error);
            // Handle error accordingly
            return null;
        }
    };


    const createIDGenerator = async (prefix) => {
        try {
            let letter = 'A';
            let count = await getCountFromFirebase('request');
      
            if (count === null) {
                count = 0;
            }
            if (count === 999999) {
              count = 1;
              letter = String.fromCharCode(letter.charCodeAt(0) + 1);
            } else {
                count++;
            }
            const numericPart = count.toString().padStart(6, '0');
            const id = `${prefix}-${numericPart}${letter}`;
            return id;
        } catch (error) {
            console.error('Error generating ID:', error);
            // Handle the error accordingly
            return null;
        }
      };

    const handleSubmitCashout = async(e) => {
        e.preventDefault();
        setIsLoadingCashout(true);
            try {
              const RequestID = await createIDGenerator('COR');
              const timestamp = serverTimestamp();
              const requestData = {    
                RequestID: RequestID,
                timestamp: timestamp,
                amount: enteredAmount,
                Method: selectedMethod,
                Recipient: BankInfo.AccountName || GcashInfo.AccountName || PalawanInfo.FullName,
                BankName: BankInfo.BankName,
                BankAccountNumber: BankInfo.AccountNumber || 'N/A',
                PhoneNumber: BankInfo.PhoneNumber || GcashInfo.GcashNumber || PalawanInfo.PhoneNumber,
                status: "Pending",
                user: user.uid,
                MemberID: userInformation.accountID,
              };
              await set(ref(db, `request/${RequestID}`), requestData).then(async()=>{
                const subject = `Your account has been created successfully`
            const email = user.email
            const response = await fetch('https://loyalista-mailer-aa5b8a82053e.herokuapp.com/onRequest', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ email, requestData , subject }),
            });
            if (response.ok) {
              
              const subject = `New Member has been registered.`
              const email = 'vincesahagun@yahoo.com'
              const bossresponse = await fetch('https://loyalista-mailer-aa5b8a82053e.herokuapp.com/CashoutRequest', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, requestData , subject }),
              });
              if (bossresponse.ok) {
                console.log('Email sent successfully');
              } else {
                console.error('Failed to send email');
              }

            } else {
              console.error('Failed to send email');
            }
              })
            } catch (error) {
              console.error('Error placing order:', error);
            }
        setTimeout(() => {
            setIsLoadingCashout(false);
            setTimeout(() => {
                message.success("Cash-out Request has been sent!");
            }, 800);
        }, 2000);
    }

    const generateCashBondID = (count) => {
        const numericPart = (count + 1).toString().padStart(8, '0');
        const cashBondID = `CB-${numericPart}`;
        return cashBondID;
    };

    const handleOpenDrawer = () => {
        setOpenCommissionDrawer(true);
    }
    const handleCloseDrawer = () => {
        setOpenCommissionDrawer(false);
    }

    const [openTransactionId, setOpenTransactionId] = useState(null);

    const handleShowDetails = (orderId) => {
      setOpenTransactionId((prevOrderId) => (prevOrderId === orderId ? null : orderId));
    };

    return (
        <main id='myWallet'>
            {!userLoggedIn && (<Navigate to={'/login'} replace={true} />)}
            <div className="head-title">
                <div className="left">
                    <h1>My Wallet</h1>
                </div>
                <div className="right">
                    <button className='btn btn-primary' onClick={handleOpenDrawer}>
                        Transaction History <GrTransaction/>
                    </button>
                    <Drawer
                        title={<h4 style={{ fontSize: '20px', margin: '0' }}>Transactions as of {new Date().toDateString()}</h4>}
                        placement="right"
                        width={500}
                        onClose={handleCloseDrawer}
                        open={openCommissionDrawer}
                        footer={null}
                    >
                        <div className="transaction-history">
                            {Object.entries(commissionHistory)
                            .sort(([, a], [, b]) => new Date(b.timestamp) - new Date(a.timestamp))
                            .map(([orderId, orderDetails]) => (
                                <div key={orderId}
                                     className={`transaction-item ${openTransactionId === orderId ? 'active' : ''}`}
                                     onClick={() => handleShowDetails(orderId)}>
                                    <div className="transaction-header">
                                        <div className="left">
                                        <p>{new Date(orderDetails.timestamp).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })}</p>
                                        <span>You have received commission from...</span>
                                        </div>
                                        <p className="transaction-amount">
                                        +{orderDetails.totalCommission ? orderDetails.totalCommission.toFixed(2) : '0.00'}
                                        </p>
                                    </div>
                                    {openTransactionId === orderId && (
                                        <div className="transaction-details">
                                            <p>Order ID: {orderId}</p>
                                            <p>Customer: {orderDetails.customer}</p>
                                            <p>Level: {orderDetails.level}</p>
                                        </div>
                                    )}
                                </div>
                            ))
                            }
                        </div>
                    </Drawer>
                </div>
            </div>

            <ul className="box-info">
                <li>
                    <FaWallet className='bx' />
                    <span className="text">
                        <h3>₱ {walletBalance}.00</h3>
                        <p>Remaining Balance</p>
                        {!isAvailable && (
                        <span className='text-danger' style={{textDecoration: 'none'}}>(*Cashout is available only on Mondays)</span>
                        )}
                    </span>
                    {showPaymentInfo && paymentDetails ? (
                        <div className="paymentMethod-info">
                            <div className={`icon-holder ${selectedMethod}`}>
                                <img src={selectedMethod === 'GCash' ? GCash : selectedMethod === 'Palawan' ? Palawan : MasterCard} alt={selectedMethod} />
                                {selectedMethod === 'Bank-Account' ? (
                                    <span>{paymentDetails.BankName}</span>
                                ) : (
                                    <span>{selectedMethod}</span>
                                )}
                            </div>
                            <div className='info'>
                                {selectedMethod === 'Bank-Account' ? (
                                <>
                                    <p><strong>Account Name: </strong>{paymentDetails.AccountName}</p>
                                    <p><strong>Account Number: </strong>{paymentDetails.AccountNumber}</p>
                                    <p><strong>Phone Number: </strong>{paymentDetails.PhoneNumber}</p>
                                </>
                                ) : (
                                <>
                                    <p><strong>Account Name: </strong>{paymentDetails.AccountName || paymentDetails.FullName}</p>
                                    {
                                        selectedMethod === 'GCash' ?
                                        <p><strong>GCash Number: </strong>{paymentDetails.GcashNumber || paymentDetails.PhoneNumber}</p>
                                        :
                                        <p><strong>Phone Number: </strong>{paymentDetails.GcashNumber || paymentDetails.PhoneNumber}</p>
                                    }

                                </>
                                )}
                            </div>
                        </div>
                    ) : (
                        <div className="paymentMethod-info">
                            <span className='text-danger'>*Please Select Payment Method First to proceed to the Cashout Request</span>
                        </div>
                    )}
                </li>
                <li>
                    <FaCashRegister className='bx' />
                    <span className="text">
                        <h3>₱ {CashBondBalance - ReturnedCharges}.00</h3>
                        <p>RTS Load Balance</p>
                        { cashbondStatus === 'Pending' ? 
                        <p className='text-danger'>Please wait for your request to be processed.</p>
                        :
                        <button className='btn btn-link' onClick={handleOpenRequest}>Request RTS Load?</button>
                        }
                    </span>
                    {isRequestedCashbond && (
                        <div className='cashbond-form '>
                            <form action="" className='form-cashbond' onSubmit={handleSentRequest}>
                                <label htmlFor="amount">Amount:
                                    <div className="close-btn text-muted" onClick={handleCloseRequest}><IoClose /></div>
                                </label>
                                <div className="form-input">
                                <input 
                                        type="number" 
                                        className="form-control" 
                                        id="amount" 
                                        min={500} 
                                        max={1000} 
                                        value={CashbondRequest} 
                                        onChange={(e) => setCashbondRequest(e.target.value)}
                                    />
                                    <button className='btn btn-success' type='submit' disabled={isLoadingCashbond}>
                                        {isLoadingCashbond && (
                                            <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                                        )}
                                        {isLoadingCashbond ? '' : 'Send'}
                                    </button>
                                </div>
                            </form>
                        </div>
                    )}
                </li>
            </ul>

            <div className="request-container">
                {paymentSelection ? (
                    <>
                        <div className="payment-selection">
                            <div className="head">
                                <h4 className=''>Select the preferred payment method of your choice.</h4>
                            </div>
                            <div className="body">
                                <p className='text-muted'>
                                    Note: Please be aware that changes to your chosen payment method cannot be made.
                                    Be sure you thoroughly consider your options before selecting the payment method.
                                </p>
                                <div className="selection">
                                    <div className={`selection-holder GCash ${selectedMethod === 'GCash' ? 'selected' : ''}`}
                                        onClick={() => handleMethodSelection('GCash')}>
                                        <img src={GCash} alt="GCASH Emblem" />
                                        <h4>GCash</h4>
                                    </div>
                                    <div className={`selection-holder Palawan ${selectedMethod === 'Palawan' ? 'selected' : ''}`}
                                        onClick={() => handleMethodSelection('Palawan')}>
                                        <img src={Palawan} alt="Palawan Logo" />
                                        <h4>Palawan</h4>
                                    </div>
                                    <div className={`selection-holder Bank-Account ${selectedMethod === 'Bank-Account' ? 'selected' : ''}`}
                                        onClick={() => handleMethodSelection('Bank-Account')}>
                                        <img src={MasterCard} alt="Master Card Emblem" />
                                        <h4>Bank Account</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="payment-selected-form">
                            <div className="head">
                                <h4>Payment Method Form</h4>
                            </div>
                            <form onSubmit={handleMethodSubmit}>
                                {selectedMethod === 'GCash' && (
                                    <>
                                        <div className="mb-3">
                                            <label htmlFor="AccountName" className="form-label">Gcash Name:</label>
                                            <input type="text" className="form-control" name="AccountName" id="AccountName" 
                                            onChange={(e) => setGcashInfo({ ...GcashInfo, AccountName: e.target.value })}
                                            required />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="PhoneNumber" className="form-label">GCash Number:</label>
                                            <input type="tel" className="form-control" name="PhoneNumber" id="PhoneNumber" pattern="[0-9]{11}" 
                                            onChange={(e) => setGcashInfo({ ...GcashInfo, GcashNumber: e.target.value })}
                                            required />
                                        </div>
                                    </>
                                )}
                                {selectedMethod === 'Palawan' && (
                                    <>
                                        <div className="mb-3">
                                            <label htmlFor="AccountName" className="form-label">Full Name:</label>
                                            <input type="text" className="form-control" name="AccountName" id="AccountName" 
                                            onChange={(e) => setPalawanInfo({ ...PalawanInfo, FullName: e.target.value })}
                                            required />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="PhoneNumber" className="form-label">Phone Number:</label>
                                            <input type="tel" className="form-control" name="PhoneNumber" id="PhoneNumber" 
                                            onChange={(e) => setPalawanInfo({ ...PalawanInfo, PhoneNumber: e.target.value })}
                                            required />
                                        </div>
                                    </>
                                )}
                                {selectedMethod === 'Bank-Account' && (
                                    <>
                                        <div className="mb-3">
                                            <label htmlFor="BankName" className="form-label">Bank Name:</label>
                                            <input type="text" className="form-control" name="BankName" id="BankName" 
                                            onChange={(e) => setBankInfo({ ...BankInfo, BankName: e.target.value })}
                                            required />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="AccountName" className="form-label">Account Name:</label>
                                            <input type="text" className="form-control" name="AccountName" id="AccountName" 
                                            onChange={(e) => setBankInfo({ ...BankInfo, AccountName: e.target.value })}
                                            required />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="AccountNumber" className="form-label">Account Number:</label>
                                            <input type="text" className="form-control" name="AccountNumber" id="AccountNumber"
                                            onChange={(e) => setBankInfo({ ...BankInfo, AccountNumber: e.target.value })}
                                            onInput={(e) => formatBankAccountNumber(e)}
                                            required />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="PhoneNumber" className="form-label">Phone Number:</label>
                                            <input type="tel" className="form-control" name="PhoneNumber" id="PhoneNumber" 
                                            onChange={(e) => setBankInfo({ ...BankInfo, PhoneNumber: e.target.value })}
                                            required />
                                        </div>
                                    </>
                                )}
                                <div className="mb-3">
                                    <button type='submit' className='btn btn-primary' disabled={isLoadingMethod}>
                                        {isLoadingMethod && (
                                            <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                                        )}
                                        {isLoadingMethod ? 'Processing...' : 'Submit'}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </>
                ) : (
                    <>
                        {isAvailable && CheckPending ? (
                        <div className="cashout-form">
                            <div className="head">
                                <h4>Cash-out Form</h4>
                            </div>
                            <form action="" className='mt-4' onSubmit={handleSubmitCashout}>
                                <div className="mb-3">
                                    <label htmlFor="amount" className="form-label">Amount:</label>
                                    <input type="number" className="form-control" id="amount" 
                                    min={500}
                                    max={walletBalance}
                                    value={enteredAmount}
                                    onChange={handleAmountChange}
                                    required/>
                                </div>
          
                                <div className="mb-3">
                                    <label htmlFor="name" className="form-label">Full Name:</label>
                                    <input type="text" className="form-control" id="name" value={paymentDetails.AccountName} disabled/>
                                </div>
                                {selectedMethod === "Bank-Account" && (
                                <div className="mb-3">
                                    <label htmlFor="contact" className="form-label">Account Number:</label>
                                    <input type="text" className="form-control" id="contact" value={paymentDetails.AccountNumber}/>
                                </div>
                                )}
                                <div className="mb-3">
                                    {selectedMethod === "Palawan" && (
                                    <label htmlFor="contact" className="form-label">Phone Number:</label>
                                    )}
                                    {selectedMethod === "GCash" && (
                                    <label htmlFor="contact" className="form-label">Gcash Number:</label>
                                    )}
                                    {selectedMethod === "Bank-Account" && (
                                    <label htmlFor="contact" className="form-label">Phone Number:</label>
                                    )}
                                    <input type="text" className="form-control" id="contact" value={paymentDetails.PhoneNumber||paymentDetails.GcashNumber} disabled/>
                                </div>
                                <div className="mt-4">
                                    <button type='submit' className='btn btn-success' disabled={isLoadingCashout}>
                                        {isLoadingCashout && (
                                            <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                                        )}
                                        {isLoadingCashout ? 'Processing...' : 'Submit Request'}
                                    </button>
                                </div>
                            </form>
                        </div>
                        ) : (
                            <>
                            </>
                        )}
                        <div className="cashout-history">
                            <div className="head">
                                <h4>Cash-out History</h4>
                            </div>
                            <div className="table-data">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Transaction ID</th>
                                            <th>Date</th>
                                            <th>Amount</th>
                                            <th>Recipient</th>
                                            <th>Payment Method</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {requestTable.map((data, index) => (
                                        <tr key={index}>
                                            <td>{data.RequestID}</td>
                                            <td>{moment(data.timestamp).format('MMM. DD, YYYY')}</td>
                                            <td>₱ {data.amount}.00</td>
                                            <td>{data.Recipient}</td>
                                            <td>via 
                                                {data.Method === 'Bank-Account' ? (
                                                     ' Bank Name'
                                                    ) : (
                                                    data.Method
                                                )}
                                            </td>
                                            <td><span class={`status ${data.status}`}>{data.status}</span></td>
                                        </tr>
                                    ))}

                                    </tbody>
                                </table>
                                <div className="pagination-container">
                                    <Pagination
                                        current={currentPage}
                                        // total={filteredProducts.length}
                                        onChange={handlePageChange}
                                        pageSize={pageSize}
                                        showSizeChanger={false}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </main>
    );
};

export default MyWallet;
