import React, { useState, useEffect } from 'react'
import './AddMember.css'
import { IoSearch, IoCameraSharp } from "react-icons/io5";
import { BsPersonFillAdd } from "react-icons/bs";
import { FaShareAlt } from "react-icons/fa";
import { GrFormNextLink, GrFormPreviousLink } from "react-icons/gr";
import { regions, provinces, cities, barangays } from 'select-philippines-address';
import { Pagination, Tooltip, Modal, message, Steps } from 'antd';
import NoDataFound from '../../../Assets/no-data/NoDataFount'
import SampleProduct from '../../../DataTest/SampleProducts';
import { useAuth } from '../../../contexts/authContext';
import {  Navigate, useNavigate, useParams } from 'react-router-dom';
import { auth, db, storage } from '../../../firebase/config';
import { get, ref ,equalTo , orderByChild, query, set } from 'firebase/database';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { deleteObject, getDownloadURL, listAll, ref as sRef, uploadBytes } from 'firebase/storage';

const { Step } = Steps;


const AddMember = () => {
  const {userLoggedIn} = useAuth() 
  const [isLoading, setIsLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [username, setUserName] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPass, setConfirmPass] = useState('');
  const [inputRegion, setInputRegion] = useState('');
  const [inputProvince, setInputProvince] = useState('');
  const [inputCity, setInputCity] = useState('');
  const [inputBrgy, setInputBrgy] = useState('');
  const [regionData, setRegionData] = useState([]);
  const [provinceData, setProvinceData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [barangayData, setBarangayData] = useState([]);
  const [isIdPictureUploaded, setIsIdPictureUploaded] = useState(false);
  const [fileName, setFileName] = useState('');
  const [imageFile, setImageFile] = useState(null);
  const [referralID, setreferralID] = useState('');
  const user =  JSON.parse(localStorage.getItem('user'));
  const [filteredMembers, setFilteredMembers] = useState([]);
  const [credentials,setUserInformation] = useState([])
  
  const [signupForm, setsignupForm] = useState({
      firstname: '',
      middlename: '',
      lastname: '',
      contact: '',
      username: '',
      email: '',
      membership: '',
      region: '',
      province: '',
      city: '',
      brgy: '',
      street: '',
      postal: '',
      landmark: '',
      referral: '',
      status: 'Pending',
  });
  useEffect(() => {
    const getUserInfo = async () => {
      try {
        const userInfoRef = ref(db, `users/${user.uid}`);
        const snapshot = await get(userInfoRef);
  
        if (snapshot.exists()) {
          const userData = snapshot.val();
          setUserInformation({...userData,
            Fullname:userData.firstname+ " " + userData.middlename + " " + userData.lastname,
            Address: userData.street + userData.brgy + ", " + userData.city+ ", " + userData.province ,
            username: userData.username
          });
        }
      } catch (error) {
        console.error(error);
      }
    };
    getUserInfo();
      
  }, []);

  useEffect(() => {
    const getUserInfo = async () => {
      const userInfoRef = ref(db, 'users');
      const snapshot = await get(userInfoRef);
      if (snapshot.exists()) {
        const userData = snapshot.val();
        // Assuming userData is an object where keys are user IDs and values are user objects
        const filteredData = Object.values(userData).filter(level1 => level1.referral === credentials.username);
        setFilteredMembers(filteredData);
      } else {
        alert('No data available');
      }
    };

    getUserInfo();
  }, [credentials, isLoading]);

  const fetchRegions = () => {
    regions()
      .then(response => {
        setRegionData(response);
        setsignupForm(prevData => ({ ...prevData, region: response[null]?.region_code || '' }));
      })
      .catch(error => console.error('Error fetching regions:', error));
  };
  const fetchProvinces = region_code => {
    provinces(region_code).then(response => {
        setProvinceData(response);
    });
  };
  const fetchCities = provinceCode => {
    cities(provinceCode).then(response => {
        setCityData(response);
    });
  };
  const fetchBarangays = cityCode => {
    barangays(cityCode).then(response => {
        setBarangayData(response);
    });
  };
  useEffect(() => {
      fetchRegions();
  }, []);
  const onChangeRegion = (e) => {
    const selectedRegionCode = e.target.value;
    const selectedRegion = regionData.find((item) => item.region_code === selectedRegionCode);

    setInputRegion(selectedRegionCode);
    setInputProvince('');
    setInputCity('');
    setInputBrgy('');
    setsignupForm({
        ...signupForm,
        region: selectedRegion ? selectedRegion.region_name : '',
    });
    fetchProvinces(selectedRegionCode);
    setProvinceData([]);
    setCityData([]);
    setBarangayData([]);
  };
  const onChangeProvince = (e) => {
    const selectedProvinceCode = e.target.value;
    const selectedProvince = provinceData.find((item) => item.province_code === selectedProvinceCode);

    setInputProvince(selectedProvinceCode);
    setInputCity('');
    setInputBrgy('');

    setsignupForm({
        ...signupForm,
        province: selectedProvince ? selectedProvince.province_name : '',
    });

    fetchCities(selectedProvinceCode);
    setCityData([]); 
    setBarangayData([]);
  };
  const onChangeCity = (e) => {
    const selectedCityCode = e.target.value;
    const selectedCity = cityData.find((item) => item.city_code === selectedCityCode);

    setInputCity(selectedCityCode);
    setInputBrgy('');

    setsignupForm({
        ...signupForm,
        city: selectedCity ? selectedCity.city_name : '',
    });

    fetchBarangays(selectedCityCode);
    setBarangayData([]); 
  };
  const onChangeBarangay = (e) => {
    const selectedBarangayCode = e.target.value;
    const selectedBarangay = barangayData.find((item) => item.brgy_code === selectedBarangayCode);

    setInputBrgy(selectedBarangayCode);

    setsignupForm({
        ...signupForm,
        brgy: selectedBarangay ? selectedBarangay.brgy_name : '',
    });
  };

  const handleNext = async() => {
    signupForm.username = username
    signupForm.email = newEmail
    const usernameExist = await checkUserName(username);
    const EmailExist = await checkEmail(newEmail)
    
    if (currentStep === 0 ) {
      if (!signupForm.firstname || !signupForm.lastname || !signupForm.contact) {
        setErrorMessage('Please fill out all required fields');
        setShowError(true);
        return;
      }
      if (signupForm.contact.length !== 11) {
        setErrorMessage('Phone number must have 11 digits');
        setShowError(true);
        return;
      }
    }

    if (currentStep === 1 ) {
      if (!username || !newEmail || !password || !confirmPass) {
        setErrorMessage('Please fill out all required fields');
        setShowError(true);
        return;
      }
      if (password !== confirmPass) {
        setErrorMessage('Password and confirm password not matched!');
        setShowError(true);
        return;
      }
      if (usernameExist) {
        setErrorMessage("Username is already used. Please choose another one!");
        setShowError(true);
        return;
      }
      if (EmailExist) {
        setErrorMessage(`email address ${newEmail} already in use.`);
        setShowError(true);
        return;
      }
    }
    if (currentStep === 2 ) {
      
      if (!isIdPictureUploaded) {
        setErrorMessage("Please upload your Government ID picture before proceeding to the next step.");
        setShowError(true);
        return;
      }
    }
    if (currentStep === 3 ) {
      if (!inputRegion || !inputProvince || !inputCity || !inputBrgy) {
        setErrorMessage('Please fill out all required fields');
        setShowError(true);
        return;
      }
    }
    setErrorMessage('');
    setCurrentStep(currentStep + 1);
   
  };
  const checkUserName = async (username) => {
    try {
      const snapshot = await get(query(ref(db, 'users'), orderByChild('username'), equalTo(username)));
      
      if (snapshot.exists()) {
        return true;
      } else {
        return false; 
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  };
  const checkEmail = async (email) => {
    try {
      const snapshot = await get(query(ref(db, 'users'), orderByChild('email'), equalTo(email)));
      
      if (snapshot.exists()) {
        return true;
      } else {
        return false; 
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  };
  const uploadImage = (userid) =>{
      const storageRef = sRef(storage, `users/${userid}/id/${fileName}`);
      uploadBytes(storageRef, imageFile).then((snapshot) => {
      });
  };
  const handlePrev = () => {
    setCurrentStep(currentStep - 1);
  };
  const handleImageUpload = (event) => {
      const file = event.target.files[0];
      if (file) {
        setImageFile(file)
        const fileName = file.name;
        setFileName(fileName); 
        setIsIdPictureUploaded(true);
        setErrorMessage(false);
      }
  };
  const truncateString = (str, maxLength) => {
    if (str.length > maxLength) {
        return str.substring(0, maxLength) + '...';
    }
    return str;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (currentStep === 4) {
      if (!signupForm.street || !signupForm.postal) {
        setErrorMessage('Please fill out all required fields');
        setShowError(true);
        setIsLoading(false);
        return;
      }
    }
    const usernameExist = await checkUserName(username);
    if (!usernameExist) {
    try {
        createUserWithEmailAndPassword(auth, newEmail, confirmPass).then(userCredential => {   
          const newuser = userCredential.user;
          signupForm.userID = newuser.uid;
          signupForm.referral = credentials.username
          const userData = signupForm
          const creds = signupForm
          set(ref(db, `users/${newuser.uid}`), 
          signupForm
       ).then( async()=>{
        uploadImage(newuser.uid)
        const subject = `Your account has been created successfully`
            const email = creds.email
            const response = await fetch('https://loyalista-mailer-aa5b8a82053e.herokuapp.com/registration', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ email, userData , subject }),
            });
            if (response.ok) {
              
              const subject = `New Member has been registered.`
              const email = 'vincesahagun@yahoo.com'
              const bossresponse = await fetch('https://loyalista-mailer-aa5b8a82053e.herokuapp.com/newMember', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, creds , subject }),
              });
              if (bossresponse.ok) {
                console.log('Email sent successfully');
              } else {
                console.error('Failed to send email');
              }
            }
       })
        }).catch(error => {
          switch (error.code) {
             case 'auth/email-already-in-use':
              alert(`email address ${newEmail} already in use.`);
               break;
             case 'auth/invalid-email':
              alert(`email address ${newEmail} is invalid.`);
               break;
             case 'auth/operation-not-allowed':
              alert(`error during sign up.`);
               break;
             case 'auth/weak-password':
              alert('password is not strong enough. add additional characters including special characters and numbers.');
               break;
             default:
              alert(error.message);
               break;
           }
       });
     } catch (error) {
        alert('Error occurred during registration');
     }
      // Simulate some asynchronous operation
      setTimeout(() => {
        setIsLoading(false);
        setTimeout(() => {  
          message.success("New invitee is successfully added!");
        }, 800);
      }, 2000); // Delay for 2 seconds
    }
      setOpenAddForm(false);
  };

  const [currentPage, setCurrentPage] = useState(1); // State for current page
  const pageSize = 10; // Number of items per page
  // search functionality
  const [searchQuery, setSearchQuery] = useState('');


  // Filter the members whenever the search query changes
  useEffect(() => {
    const filtered = filteredMembers.filter(
        (invitee) =>
            invitee.firstname?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            invitee.lastname?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            invitee.middlename?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            invitee.email?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            invitee.status?.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredMembers(filtered);
    // Reset current page to 1 when search query changes
    setCurrentPage(1);
  }, [searchQuery]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // Calculate the start and end index of the products to display based on current page and page size
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const displayedInvitee = filteredMembers.slice(startIndex, endIndex);

  const [openAddForm, setOpenAddForm] = useState(false);

  const handleOpenForm = () => {
    setOpenAddForm(true);
  }
  const handleCancel = () => {
    setOpenAddForm(false);
  }
  const handleShareLink = () => {
    const prodLink = window.location.origin + `/Referral/${user.uid}`;
    navigator.clipboard.writeText(prodLink)
      .then(() => message.success('Referral link is copied'))
      .catch((error) => message.error('Failed to copy link'));
  }

  return (
    <main id='invitee'>
    {!userLoggedIn && (<Navigate to={'/login'} replace={true} />)}
      <div className="head-title">
        <div className="left">
            <h1>Invitee</h1>
        </div>
        <div className="right">
          <button className='btn btn-primary' onClick={handleShareLink}>
            <FaShareAlt/> Share Referral Link
          </button>
        </div>
      </div>

      <div className="invites-list">
        <div className="head">
            <h5>Invites List</h5>
            <div className="search-bar">
                <IoSearch className='search-icon'/>
                <input type="search" 
                        className='form-control' 
                        placeholder='Search here...'
                        onChange={handleSearchChange}
                />
                <Tooltip placement="topRight" title={'Add Invite'}>
                    <button className='btn' onClick={handleOpenForm}>
                        <BsPersonFillAdd className='add-btn'/>
                    </button>
                </Tooltip>
            </div>
            <Modal
                title="Add Member Form"
                centered
                open={openAddForm}
                onCancel={handleCancel}
                width={800}
                footer={false}
            >
                <div className="adding-form">
                  <Steps current={currentStep} size="small" style={{ marginBottom: '20px' }} >
                    <Step title="Personal" />
                    <Step title="Account" />
                    <Step title="Member"/>
                    <Step title="Address" />
                    <Step title="Others" />
                  </Steps>
                  <form action="" onSubmit={handleSubmit}>
                    {showError && (
                        <div className="text-danger d-flex align-items-center justify-content-center m-2" 
                             style={{textAlign: 'center', fontWeight: '600', padding: '5px', fontSize: '16px'}}>
                        {errorMessage}
                        </div>
                    )}
                    {currentStep === 0 && (
                      <>
                      <div className="form-floating mb-3">
                          <input
                          type="text"
                          className="form-control"
                          name="firstname"
                          id="firstname"
                          placeholder=''
                          value={signupForm.firstname}
                          onChange={(e) => setsignupForm({ ...signupForm, firstname: e.target.value })}
                          required
                          />
                          <label htmlFor="firstname">First Name</label>
                      </div>
                      <div className="form-floating mb-3">
                          <input
                          type="text"
                          className="form-control"
                          name="middlename"
                          id="middlename"
                          placeholder=''
                          value={signupForm.middlename}
                          onChange={(e) => setsignupForm({ ...signupForm, middlename: e.target.value })}
                          />
                          <label htmlFor="middlename">Middle Name</label>
                      </div>
                      <div className="form-floating mb-3">
                          <input
                          type="text"
                          className="form-control"
                          name="lastname"
                          id="lastname"
                          placeholder=''
                          value={signupForm.lastname}
                          onChange={(e) => setsignupForm({ ...signupForm, lastname: e.target.value })}
                          required
                          />
                          <label htmlFor="lastname">Last Name</label>
                      </div>
                      <div className="form-floating mb-3">
                          <input
                          type="text"
                          className="form-control"
                          name="contact"
                          id="contact"
                          placeholder=''
                          value={signupForm.contact}
                          onChange={(e) => {
                              const input = e.target.value;
                              if (input.length <= 11) {
                              setsignupForm({ ...signupForm, contact: input });
                              }
                          }}
                          required
                          />
                          <label htmlFor="contact">Contact Number</label>
                      </div>
                      </>
                    )}
                    {currentStep === 1 && (
                    <>
                    <div className="form-floating mb-3">
                        <input
                        type="text"
                        className="form-control"
                        name="username"
                        id="username"
                        placeholder=""
                        value={username}
                        onChange={(e) => setUserName(e.target.value)}
                        required
                        />
                        <label htmlFor="username">Username</label>
                    </div>
                    <div className="form-floating mb-3">
                        <input
                        type="email"
                        className="form-control"
                        name="email"
                        id="email"
                        placeholder=""
                        value={newEmail}
                        onChange={(e) => {
                            setNewEmail(e.target.value)
                        }}
                        required
                        />
                        <label htmlFor="email">Email</label>
                    </div>
                    <div className="form-floating mb-3">
                    <input
                        type="password"
                        className="form-control"
                        name="password"
                        id="password"
                        placeholder=""
                        required
                        value={password}
                        onChange={(e) => {
                        setPassword(e.target.value);
                        setErrorMessage('');
                        }}
                    />
                    <label htmlFor="password">Password</label>
                    </div>
                    <div className="form-floating mb-3">
                    <input
                        type="password"
                        className="form-control"
                        name="confirmPass"
                        id="confirmPass"
                        placeholder=""
                        required
                        value={confirmPass}
                        onChange={(e) => {
                        setConfirmPass(e.target.value);
                        setErrorMessage('');
                        }}
                    />
                    <label htmlFor="confirmPass">Confirm Password</label>
                    </div>
                    </>
                    )}
                    {currentStep === 2 && (
                    <>
                    <div className="form-floating mb-3">
                    <input
                        type="text"
                        className="form-control"
                        name="referred"
                        id="referred"
                        placeholder=""
                        required
                        value={credentials.username}
                        readOnly
                    />
                    <label htmlFor="referred">Referral</label>
                    </div>
                        
                    <div class="upload-id mb-3">
                        <span className='label'>Government ID</span>
                        <input className='upload inputfile'
                            type="file"
                            capture="camera"
                            accept="image/*"
                            name="IdPicture"
                            value={signupForm.IdPicture}
                            onChange={handleImageUpload}
                            id="IdPicture"/>
                            <label htmlFor="IdPicture" className='col-6 IDpic'>
                                <span>
                                    <IoCameraSharp/> {truncateString(fileName, 20) || 'Choose a file...'}
                                </span>
                            </label>
                    </div>
                        
                    </>
                    )}
                    {currentStep === 3 && (
                    <>
                    <div className="form-floating mb-3">
                        <select className="form-select" 
                            name="region"
                            id="region"
                            value={inputRegion}
                            onChange={onChangeRegion}
                            required>
                        <option value='' disabled selected>
                            Select Region
                        </option>
                        {regionData.map(item => (
                            <option
                            key={item.region_code}
                            value={item.region_code}
                            >
                            {item.region_name}
                            </option>
                        ))}
                        </select>
                        <label htmlFor="region">Region</label>
                    </div>
                    <div className="form-floating mb-3">
                        <select className="form-select" 
                            name="province"
                            id="province"
                            value={inputProvince}
                            onChange={onChangeProvince}
                            required>
                        <option value='' disabled selected>
                            Select Province
                        </option>
                        {provinceData.map(item => (
                            <option
                            key={item.province_code}
                            value={item.province_code}
                            >
                            {item.province_name}
                            </option>
                        ))}
                        </select>
                        <label htmlFor="province">Province</label>
                    </div>
                    <div className="form-floating mb-3">
                        <select className="form-select" 
                            name="city"
                            id="city"
                            value={inputCity}
                            onChange={onChangeCity}
                            required>
                        <option value='' disabled selected>
                            Select City / Municipality
                        </option>
                        {cityData.map(item => (
                            <option 
                            key={item.city_code} 
                            value={item.city_code}
                            >
                            {item.city_name}
                            </option>
                        ))}
                        </select>
                        <label htmlFor="city">City / Municipal</label>
                    </div>
                    <div className="form-floating mb-3">
                        <select className="form-select" 
                            name='brgy'
                            id="brgy"
                            value={inputBrgy}
                            onChange={onChangeBarangay}
                            required>
                        <option value='' disabled selected>
                            Select Barangay
                        </option>
                        {barangayData.map(item => (
                            <option 
                            key={item.brgy_code} 
                            value={item.brgy_code}
                            >
                            {item.brgy_name}
                            </option>
                        ))}
                        </select>
                        <label htmlFor="brgy">Barangay</label>
                    </div>
                    </>
                    )}
                    {currentStep === 4 && (
                    <>
                    <div className="form-floating mb-3">
                        <input
                        type="text"
                        className="form-control"
                        name="street"
                        id="street"
                        placeholder=""
                        value={signupForm.street}
                        onChange={(e) => setsignupForm({ ...signupForm, street: e.target.value })}
                        required
                        />
                        <label htmlFor="street">Street</label>
                    </div>
                    <div className="form-floating mb-3">
                        <input
                        type="text"
                        className="form-control"
                        name="postal"
                        id="postal"
                        placeholder=""
                        value={signupForm.postal}
                        onChange={(e) => setsignupForm({ ...signupForm, postal: e.target.value })}
                        required
                        />
                        <label htmlFor="postal">Postal Code</label>
                    </div>
                    <div className="form-floating mb-3">
                        <textarea
                        style={{height: '132px'}}
                        className="form-control"
                        name="landmark"
                        id="landmark"
                        placeholder=""                      
                        />
                        <label htmlFor="landmark">Landmark (Optional)</label>
                    </div>
                    </>
                    )}
                    <div className="d-flex justify-content-between align--items-center">
                    {currentStep > 0 && (
                        <button type='button' className='btn btn-primary' style={{ minWidth: '110px' }} 
                        onClick={handlePrev}>
                            <GrFormPreviousLink/> Previous
                        </button>
                    )}
                    {currentStep < 4 && (
                        <button type='button' className='btn btn-primary' style={{ minWidth: '110px' }} 
                        onClick={handleNext}>
                            Next <GrFormNextLink/>
                        </button>
                    )}
                    {currentStep === 4 && (
                        <button type="submit" className='btn btn-success' style={{ minWidth: '110px' }} 
                        disabled={isLoading}>
                        {isLoading && (
                        <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                        )}
                        {isLoading ? 'Creating...' : 'Submit Form'}
                    </button>
                    )}
                    </div>
                  </form> 
                </div>
            </Modal>
        </div>
        <div className="table-data">
        {displayedInvitee.length === 0 ? (
            <NoDataFound/>
          ) : (
          <div className="invite-data">
            <table>
              <thead>
                <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Contact</th>
                    <th colSpan={2}>Address</th>
                    <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {displayedInvitee.map((invitee) => (
                  <tr key={invitee.inviteID}>
                    <td width={'20%'}>{invitee.firstname+' '+ invitee.middlename +' '+ invitee.lastname}</td>
                    <td width={'30%'}>
                      <p style={{margin: '0px', overflowWrap: 'break-word', maxWidth: '350px', minWidth: '200px'}}>
                        {invitee.email}
                      </p>
                    </td>
                    <td width={'15%'}>{invitee.contact}</td>
                    <td colSpan={2}>
                      <p style={{margin: '0px', textWrap: 'wrap', width: '400px'}}>
                        {invitee.postal+' '+ invitee.street +" '"+ invitee.landmark +"' "+ invitee.brgy +', '+ invitee.city +' '+ invitee.province +' '+ invitee.region}
                      </p>
                    </td>
                    <td width={'8%'}><span class={`status ${invitee.status}`}>{invitee.status}</span></td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="pagination-container">
              <Pagination
                  current={currentPage}
                  total={displayedInvitee.length}
                  onChange={handlePageChange}
                  pageSize={pageSize}
                  showSizeChanger={false}
              />
            </div>
          </div>
          )}
        </div>

      </div>
    </main>
  )
}

export default AddMember;
