import React from 'react'
import './noData.css'
import no_data from '../images/nodata-table.png'
const NoDataFount = () => {
  return (
    <div className='empty-table'>
      <img src={no_data} alt="" />
      <h5>No data found!</h5>
    </div>
  )
}

export default NoDataFount;
