import { create } from 'zustand';

export const useCartStore = create((set) => ({
    cart: [], // Use SampleCart as the initial state of the cart
    addToCart: (product) => set((state) => {
        const existingProductIndex = state.cart.findIndex(item => item.id === product.id);
        if (existingProductIndex !== -1) {
            // Update the quantity if the product already exists in the cart
            const updatedCart = state.cart.map((item, index) =>
                index === existingProductIndex ? { ...item, quantity: item.quantity + product.quantity } : item
            );
            return { cart: updatedCart };
        } else {
            // Add new product to the cart
            return { cart: [...state.cart, product] };
        }
    }),
    removeFromCart: (productId) => set((state) => ({ cart: state.cart.filter(product => product.id !== productId) })),
    clearCart: () => set({ cart: [] }),
    setCart: (updatedCart) => set({ cart: updatedCart }) // Define setCart function to update the cart state
}));

export const useCheckType = create((set) => ({
        seller: [],
        setSeller: (sellerInfo) => set({ seller: sellerInfo }),
        userInformation:[],
        setUserInformation: (userInfo) => set({ userInformation: userInfo }),
}));

export const userCashBond = create((set) => ({
    cashbond: 0,
    setCashbond: (value) => set({ cashbond: value}),
}));