import React, { useState } from 'react'
import './Contact.css'
import Header2 from '../../Header/Header2';
import Footer from '../../Footer/Footer';
import { FaPaperPlane } from "react-icons/fa";
import { Skeleton, message } from 'antd';
const ContactUs = () => {
  const [mapLoaded, setMapLoaded] = useState(false);
  const [formData, setFormData] = useState({
    fullname: '',
    email: '',
    message: '',
});
const handleSubmit = async(e) => {
  e.preventDefault();
            const subject = `${formData.fullname} has been sent an email.`
            const email = 'loyalistaonlineshopcorp@gmail.com'
            const response = await fetch('https://loyalista-mailer-aa5b8a82053e.herokuapp.com/contactUS', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ email, formData , subject }),
            });
              if (response.ok) {
                setFormData({
                  ...formData,
                  fullname: '',
                  email: '',
                  message: '',
              }) 
                message.success('Your message has been sent!')
              } else {
                console.error('Failed to send email');
              }
}
  return (
    <>
   <Header2/>
    <section class="contact-us" id='contactus'>
        <div class="container">
            <div class="row center">
                {/* <div class="col-lg-3">
                    <div id="map">
                    {!mapLoaded && <Skeleton.Image active style={{width: '600px', height: '400px'}} />}
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15441.776351522016!2d121.03454586077116!3d14.63071472280234!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3397b7e56ab1ca79%3A0x25a17ad07d9fde0e!2sLoyalista%20Online%20Shop%20Corp!5e0!3m2!1sen!2sph!4v1715988117989!5m2!1sen!2sph"
                        style={{ width: '100%', height: '400px', border: '0' }}
                        allowFullScreen
                        onLoad={() => setMapLoaded(true)} // Update mapLoaded state to true when the iframe is loaded
                      ></iframe>
                    </div>
                </div> */}
                <div class="col-lg-7">
                    <div class="section-heading">
                      <div className="loyalista-details">
                        <p>No. 39, Commercial Unit 2nd Floor Left Wing, 39 Sct. Ybardolaza, Quezon City, Metro Manila</p>
                        <p>loyalistaonlineshopcorp@gmail.com</p>
                        <p>(02)-8534-7369</p>
                      </div>
                        {/* <br />
                        <span>Details to details is what makes Loyalista Online Shop different from the other themes.</span> */}
                    </div>
                    <form id="contact" onSubmit={handleSubmit} action="" method="post">
                        <div class="row">
                          <div class="col-lg-6">
                            <fieldset>
                              <input name="fullname" type="text" id="fullname" placeholder="Your name" 
                               value={formData.fullname}
                               onChange={(e) => setFormData({ ...formData, fullname: e.target.value })}
                              required/>
                            </fieldset>
                          </div>
                          <div class="col-lg-6">
                            <fieldset>
                              <input name="email" type="text" id="email" placeholder="Your email" 
                              value={formData.email}
                              onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                              required/>
                            </fieldset>
                          </div>
                          <div class="col-lg-12">
                            <fieldset>
                              <textarea name="message" rows="6" id="message" placeholder="Your message" 
                              value={formData.message}
                              onChange={(e) => setFormData({ ...formData, message: e.target.value })}
                              required/>
                            </fieldset>
                          </div>
                          <div class="col-lg-12">
                            <fieldset>
                              <button type="submit" id="form-submit" class="main-dark-button"><FaPaperPlane/></button>
                            </fieldset>
                          </div>
                        </div>
                      </form>
                </div>
            </div>
        </div>
    </section>

    {/* <section class="subscribe">
        <div class="container">
            <div class="row">
                <div class="col-lg-8">
                    <div class="section-heading">
                        <h2>By Subscribing To Our Newsletter You Can Get 30% Off</h2>
                        <span>Details to details is what makes (Company name) different from the other themes.</span>
                    </div>
                    <form id="subscribe" action="" method="get">
                        <div class="row">
                          <div class="col-lg-5">
                            <fieldset>
                              <input name="name" type="text" id="name" placeholder="Your Name" required=""/>
                            </fieldset>
                          </div>
                          <div class="col-lg-5">
                            <fieldset>
                              <input name="email" type="text" id="email" pattern="[^ @]*@[^ @]*" placeholder="Your Email Address" required=""/>
                            </fieldset>
                          </div>
                          <div class="col-lg-2">
                            <fieldset>
                              <button type="submit" id="form-submit" class="main-dark-button"><FaPaperPlane/></button>
                            </fieldset>
                          </div>
                        </div>
                    </form>
                </div>
                <div class="col-lg-4">
                    <div class="row">
                        <div class="col-6">
                            <ul>
                                <li>Store Location:<br/><span>16501 Example Ave, Manila, 2510, Philippines</span></li>
                                <li>Phone:<br/><span>010-020-0340</span></li>
                                <li>Office Location:<br/><span>Manila, Philippines</span></li>
                            </ul>
                        </div>
                        <div class="col-6">
                            <ul>
                                <li>Work Hours:<br/><span>07:30 AM - 9:30 PM Daily</span></li>
                                <li>Email:<br/><span>example@company.com</span></li>
                                <li>Social Media:<br/><span><a href="#">Facebook</a>, <a href="#">Instagram</a>, <a href="#">Twitter</a>, <a href="#">Linkedin</a></span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> */}
    <Footer/>
    </>
  )
}

export default ContactUs