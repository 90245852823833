import React, { useState } from "react";
import './App.css'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Home from './LandingPage/Components/Pages/Home/Home';
import ViewProd from "./LandingPage/Components/Pages/Store/ViewProd";
import LoginSignup from "./LandingPage/Components/Pages/LoginSignup/LoginSignup";
import ForgotPassword from "./LandingPage/Components/Pages/Forgot Password/ForgotPassword";
import Error404 from "./LandingPage/Components/Pages/Error404/Error404";
import ProductsPage from "./LandingPage/Components/Pages/Product/Products";
import AboutUs from "./LandingPage/Components/Pages/AboutUs/AboutUs";
import ContactUs from "./LandingPage/Components/Pages/ContactUs/ContactUs";
import PrivacyPolicy from "./LandingPage/Components/Pages/Privacy Policy/PrivacyPolicy";
import TermsandConditions from "./LandingPage/Components/Pages/Terms and Conditions/TermsandConditions";
import License from "./LandingPage/Components/Pages/License/License";

import ClientApp from "./App_client/Client_App";
import Dashboard from "./App_client/Pages/Dashboard/Dashboard";
import MyStore from "./App_client/Pages/My Store/MyStore";
import ProductLink from "./App_client/Pages/Product Link/ProductLink";
import OrderHistory from "./App_client/Pages/Order History/OrderHistory";
import MyWallet from "./App_client/Pages/My Wallet/MyWallet";
import MyProfile from "./App_client/Pages/Profile Setting/MyProfile";
import MembersGenealogy from "./App_client/Pages/Genealogy/MembersGenealogy"
import AddMember from "./App_client/Pages/Add Member/AddMember";
import SellerViewProd from "./App_client/Pages/SellerStore/SellerViewProd";

import AdminLogin from "./Admin_Page/AdminLogin";
import AdminPage from "./Admin_Page/Layout/AdminPage";
import AdminDashboard from "./Admin_Page/Pages/Dashboard/AdminDashboard";
import SalesReport from "./Admin_Page/Pages/Sales Report/SalesReport";
import OrdersList from "./Admin_Page/Pages/Orders List/OrdersList";
import OrderHistory_admin from "./Admin_Page/Pages/Order History/OrderHistory_admin";
import Products from "./Admin_Page/Pages/Products/Products";
import CashoutRequest from "./Admin_Page/Pages/Cashout Request/CashoutRequest";
import CashbondRequest from "./Admin_Page/Pages/Cashbond Request/CashbondRequest";
import UserManagement from "./Admin_Page/Pages/User Management/UserManagement";
import Genealogy from "./Admin_Page/Pages/Genealogy/Genealogy";
import { AuthProvider } from "./contexts/authContext";
import ReferralLink from "./Referral Link/ReferralLink";
import ProtectUser from "./contexts/protectUser";
import ResetPass from "./LandingPage/Components/Pages/Reset Password/ResetPass";

function App() {

  return (
    <AuthProvider>
    <Router>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/About-us' element={<AboutUs/>}/>
        <Route path='/Products' element={<ProductsPage/>}/>
        <Route path="/Products/:productId" element={<ViewProd />} />
        <Route path="/Contact-Us" element={<ContactUs/>} />
        <Route path="/:userID/:productId" element={<SellerViewProd />} />
        <Route path='/Login' element={<LoginSignup/>} />
        <Route path="/Privacy Policy" element={<PrivacyPolicy/>}/>
        <Route path="/Terms And Conditions" element={<TermsandConditions/>}/>
        <Route path="/License" element={<License/>}/>
        <Route path='/Reset-Password' element={<ResetPass/>} />
        <Route path="/Forgot Password" element={<ForgotPassword/>}/>
        <Route path="*" element={<Error404 />} />
        <Route path="/MyPage" element={<ClientApp/>}>
          <Route index element={<Dashboard/>}/>
          <Route path="/MyPage/MyStore" element={<MyStore/>}/>
          <Route path="/MyPage/ProductLink" element={<ProductLink/>}/>
          <Route path="/MyPage/OrderHistory" element={<OrderHistory/>}/>
          <Route path="/MyPage/MyWallet" element={<MyWallet/>}/>
          <Route path="/MyPage/AddInvitees" element={<AddMember/>}/>
          <Route path="/MyPage/MyGenealogy" element={<MembersGenealogy/>}/>
        </Route>
        <Route path="/Admin" element={<AdminLogin/>}/>
        <Route path="/MyAdmin" element={<ProtectUser/>}>
          <Route index element={<AdminDashboard/>}/>
          <Route path="/MyAdmin/SalesReport" element={<SalesReport/>}/>
          <Route path="/MyAdmin/OrdersList" element={<OrdersList/>}/>
          <Route path="/MyAdmin/OrderHistory" element={<OrderHistory_admin/>}/>
          <Route path="/MyAdmin/OurProducts" element={<Products/>}/>
          <Route path="/MyAdmin/CashoutRequest" element={<CashoutRequest/>}/>
          <Route path="/MyAdmin/CashbondRequest" element={<CashbondRequest/>}/>
          <Route path="/MyAdmin/userManagement" element={<UserManagement/>}/>
          <Route path="/MyAdmin/GenealogyChart" element={<Genealogy/>}/>
        </Route>
        <Route path="/Referral/:ReferalUID" element={<ReferralLink/>}/>
      </Routes>
    </Router>
    </AuthProvider>
  );
}

export default App;
