import React, { useState, useEffect } from 'react';
import './Dashboard.css'
import { MdFormatListBulletedAdd } from "react-icons/md";
import { FaRegListAlt, FaArrowUp, FaArrowDown, FaUsers, FaWallet } from "react-icons/fa";
import { FcSalesPerformance } from "react-icons/fc";
import { useAuth } from '../../../contexts/authContext';
import {  Navigate } from 'react-router-dom';
import { Select } from 'antd';
import {
    BarChart,
    Bar,
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
  } from "recharts";
import { child, get, ref } from 'firebase/database';
import { auth, db } from '../../../firebase/config';
const { Option } = Select;

const Dashboard = () => {
    const currentMonth = new Date().toLocaleString('en-US', { month: 'long' });
    const currentYear = new Date().toLocaleString('en-US', { year: 'numeric' });
    const credentials = JSON.parse(localStorage.getItem('user'))
    const [totalSales, setTotalSales] = useState(0)
    const [chartData, setChartData] = useState(0)
    const [TotalOrders, setTotalOrders] = useState(0)
    const [walletBalance, setWalletBalance] = useState(0)
    const {userLoggedIn} = useAuth() 
    const [NetWork, setNetWork] =useState([])
    const user = JSON.parse(localStorage.getItem('user'))
    const [SalesData, setSalesData] = useState([])
    const [selectedMonth, setSelectedMonth] = useState(currentMonth);
    const [IncomeData, setIncomeData] = useState([])
    const [selectedYear, setSelectedYear] = useState(currentYear);
    const [barleySales, setBarleySales] = useState(0)
    useEffect(() => {
        const fetchOrderItems = async () => {
            try {
                const ordersRef = ref(db, 'orders');
                const snapshot = await get(ordersRef);
    
                if (snapshot.exists()) {
                    const orderItemsArray = Object.entries(snapshot.val())
                        .filter(([orderId, orderData]) => (orderData.user === user.uid) && (orderData.status === 'Completed'))
                        .map(([orderId, orderData]) => ({
                            orderId: orderId,
                            ...orderData,
                        }))
                        .sort((b, a) => b.timestamp - a.timestamp);
    
                    let overallSalesSum = 0;
                    const transformedData = {};
    
                    orderItemsArray.forEach((order) => {
                        const date = new Date(order.timestamp);
                        const year = date.getFullYear();
                        const month = date.toLocaleString('en-US', { month: 'long' });
                        const week = `Week ${Math.ceil(date.getDate() / 7)}`;
                        const highestBarleyCommission = order.barley
                        if (!transformedData[year]) {
                            transformedData[year] = {};
                        }
    
                        if (!transformedData[year][month]) {
                            transformedData[year][month] = {
                                weeks: {},
                                income: 0
                            };
                        }
    
                        if (!transformedData[year][month].weeks[week]) {
                            transformedData[year][month].weeks[week] = {};
                        }
                        if(order.shopType !== 'ClientStore') {
                        order.items.forEach((product) => {
                            const productName = product.name;
                            const quantity = product.quantity;

                            if (!transformedData[year][month].weeks[week][productName]) {
                                transformedData[year][month].weeks[week][productName] = 0;
                            }
    
                            transformedData[year][month].weeks[week][productName] += quantity;
    
                            if ('commissions' in product && 'level1' in product.commissions) {
                                overallSalesSum += product.commissions.level1;
                                transformedData[year][month].income += product.commissions.level1 * product.quantity;
                            }
    
                            // Additional logic for barley commissions
                            if (product.category === 'Single Product' && product.name === 'Infin8 Pure Organic Barley') {
                                const barleyCommissions = highestBarleyCommission * product.quantity;
                                transformedData[year][month].income += barleyCommissions;
                            }
                        });
                    }
                    });
    
                    const finalData = Object.entries(transformedData).map(([year, monthsData]) => {
                        return Object.entries(monthsData).map(([month, monthData]) => {
                            return {
                                year: parseInt(year),
                                month: month,
                                weeks: Object.entries(monthData.weeks).map(([week, weekData]) => ({
                                    week: week,
                                    ...weekData
                                })),
                                income: monthData.income
                            };
                        });
                    }).flat();
                    setSalesData(finalData);
                    
                }
            } catch (error) {
                console.error(error);
            }
        };
    
        fetchOrderItems();
    }, [userLoggedIn]);
    
    
    useEffect(() => {
        const fetchOrderItems = async () => {
            try {
                const ordersRef = ref(db, 'orders');
                const snapshot = await get(ordersRef);

                if (snapshot.exists()) {
                    const orderItemsArray = Object.entries(snapshot.val())
                        .filter(([orderId, orderData]) => orderData.status === 'Completed')
                        .map(([orderId, orderData]) => ({
                            orderId: orderId,
                            ...orderData,
                        }))
                        .sort((b, a) => b.timestamp - a.timestamp);

                    const transformedData = {};

                    let barleyCommissions = 0
                    let highestBarleyCommission = 0;
                    const findCommissionsForLevel = (user, level) => {
        
                        orderItemsArray.forEach(order => {
                            order.items.forEach((item) => {
                                if (item.category === 'Package' && item.barleyCommission !== undefined) {
                                    const itemBarleyCommission = item.barleyCommission;
                                    if (itemBarleyCommission > highestBarleyCommission) {
                                        highestBarleyCommission = itemBarleyCommission;
                                    }
                                }
                            });
                        });
        
                        // Find orders for this user and sum the corresponding level commissions
                        orderItemsArray.forEach((order, orderIndex) => {
                            const date = new Date(order.timestamp);
                            const year = date.getFullYear();
                            const month = date.toLocaleString('en-US', { month: 'short' });
    
                            if (!transformedData[year]) {
                                transformedData[year] = {};
                            }
    
                            if (!transformedData[year][month]) {
                                transformedData[year][month] = 0;
                            }
                            
                            if (order.username === user.name) {
                                if (order.shopType !== 'ClientStore') {
                                    // Non-ClientStore orders
                                    if (order.items) {
                                        order.items.forEach((item, itemIndex) => {
                                            const commissionLevel = `level${level}`;
                                            if (item.commissions && item.commissions[commissionLevel]) {
                                                    transformedData[year][month] +=  item.commissions[commissionLevel] * item.quantity;
                                        }
                                            if (item.category === 'Single Product' && item.name === 'Infin8 Pure Organic Barley') {
                                                const barleyCommissions = order.barley * item.quantity;
                                                transformedData[year][month] += barleyCommissions;
                                            }
                                        });
                                    } else {
                                        console.warn(`Order ${orderIndex + 1} does not have valid items.`);
                                    }
                                } 
                                else {
                                    // ClientStore orders
                                    if (level === 1) {
                                        return;
                                    }
                                    if (order.items) {
                                        order.items.forEach((item, itemIndex) => {
                                            const commissionLevel = `level${level - 1}`;
                                            if (item.commissions && item.commissions[commissionLevel]) {
                                                        transformedData[year][month] +=  item.commissions[commissionLevel] * item.quantity;
                                            }
                                            if (item.category === 'Single Product' && item.name === 'Infin8 Pure Organic Barley') {
                                                const barleyCommissions = order.barley * item.quantity;
                                                transformedData[year][month] += barleyCommissions;
                                            }
                                        });
                                    } else {
                                        console.warn(`Order ${orderIndex + 1} does not have valid items.`);
                                    }
                                }
                            }
                        });
                    };
        
                    // Iterate through the user array and find commissions for each user at their respective levels
                    NetWork.forEach(user => {
                        findCommissionsForLevel(user, user.level);
                    });
                    //     const date = new Date(order.timestamp);
                    //     const year = date.getFullYear();
                    //     const month = date.toLocaleString('en-US', { month: 'short' });

                    //     if (!transformedData[year]) {
                    //         transformedData[year] = {};
                    //     }

                    //     if (!transformedData[year][month]) {
                    //         transformedData[year][month] = 0;
                    //     }

                    //     if (order.shopType !== 'ClientStore') {
                    //         order.items.forEach((product) => {
                    //             if (product.commissions) {
                    //                 for (const [level, commission] of Object.entries(product.commissions)) {
                    //                     if (level.startsWith('level')) {
                    //                         transformedData[year][month] += commission * product.quantity;
                    //                     }
                    //                 }
                    //             }
                    //             if (product.category === 'Single Product' && product.name === 'Infin8 Pure Organic Barley') {
                    //                 const barleyCommissions = order.barley * product.quantity;
                    //                 transformedData[year][month] += barleyCommissions;
                    //             }
                    //         });
                    //     }
                    // });

                    const finalData = Object.entries(transformedData).map(([year, monthsData]) => {
                        const months = Object.entries(monthsData).map(([month, income]) => ({
                            month: month,
                            income: income,
                        }));
                        return {
                            year: parseInt(year),
                            months: months,
                        };
                    });
                    setIncomeData(finalData);
                }
            } catch (error) {
                console.error(error);
            }
        };

        fetchOrderItems();
    }, [userLoggedIn, db]);



    useEffect(() => {
        const fetchData = async () => {
            try {
                const snapshot = await get(child(ref(db), 'users'));
                if (snapshot.exists()) {
                    const userData = snapshot.val();
                    const userArray = Object.values(userData);
                    const chartDataFromFirebase = [];
                    const chartDataFromNextLevel = [];

                    const findReferrals = (username, level) => {
                        if (level > 10) return; // Adjust level limit as needed

                        userArray.forEach((user) => {
                            if (user.referral === username) {
                                chartDataFromFirebase.push({
                                    name: user.username,
                                    parent: user.referral,
                                    level: level,
                                });
                                findReferrals(user.username, level + 1);
                            }
                        });
                    };

                    userArray.forEach((user) => {
                        if (user.email === credentials.email) {
                            chartDataFromFirebase.push({
                                name: user.username,
                                parent: null,
                                level: 0,
                            });
                            findReferrals(user.username, 1); // Start recursive search from level 1
                        }
                    });

                    setChartData([...chartDataFromFirebase, ...chartDataFromNextLevel]);
                    
                } else {
                    alert('No data available');
                }
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, [credentials]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const snapshot = await get(child(ref(db), 'users'));
                if (snapshot.exists()) {
                    const userData = snapshot.val();
                    const userArray = Object.values(userData);
                    const chartDataFromFirebase = [];

                    const findReferrals = (username, level) => {
                        if (level > 10) return; // Adjust level limit as needed

                        userArray.forEach((creds) => {
                            if (creds.referral === username) {
                                chartDataFromFirebase.push({
                                    name: creds.username,
                                    parent: creds.referral,
                                    level: level,
                                });
                                findReferrals(creds.username, level + 1);
                            }
                        });
                    };

                    userArray.forEach((creds) => {
                        if (creds.email === user.email) {
                            chartDataFromFirebase.push({
                                name: creds.username,
                                parent: null,
                                level: 1,
                            });
                            findReferrals(creds.username, 2);
                        }
                    });

                    setNetWork([...chartDataFromFirebase]);
                } else {
                    alert('No data available');
                }
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, [userLoggedIn]);


    useEffect(() => {
        let barleyCommissions = 0
        let highestBarleyCommission = 0;
        const fetchCommissionsByLevel = async () => {
            try {
                const ordersRef = ref(db, 'orders');
                const snapshot = await get(ordersRef);
                if (snapshot.exists()) {
                    const orderItemsArray = Object.entries(snapshot.val())
                        .filter(([orderId, orderData]) => orderData.status === 'Completed')
                        .map(([orderId, orderData]) => ({
                            orderId: orderId,
                            ...orderData,
                        }));
                    const orderRetrurnedItemsArray = Object.entries(snapshot.val())
                        .filter(([orderId, orderData]) => (orderData.user === user.uid) && (orderData.status=== 'Returned'))
                        .map(([orderId, orderData]) => ({
                            orderId: orderId,
                            ...orderData,
                        }))
                        const TotalOrdersArray = Object.entries(snapshot.val())
                        .filter(([orderId, orderData]) => (orderData.user === user.uid) && (orderData.status=== 'Completed'))
                        .map(([orderId, orderData]) => ({
                            orderId: orderId,
                            ...orderData,
                        }))

                        .sort((b, a) => b.timestamp - a.timestamp);
                        let returnedShipping= 0;
                    orderRetrurnedItemsArray.forEach((order, index) => {
                            returnedShipping += order.invoice.AmountDue.Shipping;
                        });
                    const commissionsByLevel = {};
    
                    const findCommissionsForLevel = (user, level) => {
                        if (!(level in commissionsByLevel)) {
                            commissionsByLevel[level] = 0;
                        }
        
                        orderItemsArray.forEach(order => {
                            order.items.forEach((item) => {
                                if (item.category === 'Package' && item.barleyCommission !== undefined) {
                                    const itemBarleyCommission = item.barleyCommission;
                                    if (itemBarleyCommission > highestBarleyCommission) {
                                        highestBarleyCommission = itemBarleyCommission;
                                    }
                                }
                            });
                        });
        
                        // Find orders for this user and sum the corresponding level commissions
                        orderItemsArray.forEach((order, orderIndex) => {
                            if (order.username === user.name) {
                                if (order.shopType !== 'ClientStore') {
                                    // Non-ClientStore orders
                                    if (order.items) {
                                        order.items.forEach((item, itemIndex) => {
                                            const commissionLevel = `level${level}`;
                                            if (item.commissions && item.commissions[commissionLevel]) {
                                                commissionsByLevel[level] += item.commissions[commissionLevel] * item.quantity;
                                            } else {
                                                console.warn(`Item ${itemIndex + 1} in Order ${orderIndex + 1} does not have a valid commission property at level ${level}.`);
                                            }
                                            if (item.category === 'Single Product' && item.name === 'Infin8 Pure Organic Barley') {
                                                barleyCommissions = highestBarleyCommission * item.quantity;
                                            }
                                        });
                                    } else {
                                        console.warn(`Order ${orderIndex + 1} does not have valid items.`);
                                    }
                                } else {
                                    // ClientStore orders
                                    if (level === 1) {
                                        return;
                                    }
                                    if (order.items) {
                                        order.items.forEach((item, itemIndex) => {
                                            const commissionLevel = `level${level - 1}`;
                                            if (item.commissions && item.commissions[commissionLevel]) {
                                                commissionsByLevel[level - 1] += item.commissions[commissionLevel] * item.quantity;
                                            } else {
                                                console.warn(`Item ${itemIndex + 1} in Order ${orderIndex + 1} does not have a valid commission property at level ${level}.`);
                                            }
                                        });
                                    } else {
                                        console.warn(`Order ${orderIndex + 1} does not have valid items.`);
                                    }
                                }
                            }
                        });
                    };
        
                    // Iterate through the user array and find commissions for each user at their respective levels
                    NetWork.forEach(user => {
                        findCommissionsForLevel(user, user.level);
                    });
                
                    // Calculate the sum of commissions for each level
                    const sumOfCommissionsByLevel = Object.values(commissionsByLevel).reduce((acc, cur) => acc + cur, 0);
                    try {
                        const pendingRef = ref(db, 'request');
                        const snapshot = await get(pendingRef);
                        let totalApprovedAmount = 0;
                        if (snapshot.exists()) {
                          const requestData = snapshot.val();
                          const userUid = user.uid;
                          Object.keys(requestData).forEach((requestID) => {
                            const request = requestData[requestID];
                            if (request.user === userUid && request.status === 'Approved') {
                              totalApprovedAmount += request.amount || 0;
                            }
                          });
                        }
                        setBarleySales(barleyCommissions)
                        setTotalSales(commissionsByLevel[1] + barleyCommissions)
                        setWalletBalance(sumOfCommissionsByLevel + barleyCommissions);
                        setTotalOrders(TotalOrdersArray.length)
                      } catch (error) {
                        console.error(error);
                      }
                    // setReturnCharges(returnedShipping)
                }
            } catch (error) {
                console.error(error);
            }
        };
    
        fetchCommissionsByLevel();
    }, [NetWork]);

    const selectedMonthData = SalesData.find((item) => item.month === selectedMonth)?.weeks || [];
    const handleMonthChange = (value) => {
        setSelectedMonth(value);
    };
    const totalSalesPerMonth = SalesData.map((month) => ({
        Month: month.month,
        total: month.weeks.reduce((acc, day) => acc + Object.values(day).slice(1).reduce((a, b) => a + b, 0), 0),
    }));
    const selectedMonthIndex = totalSalesPerMonth.findIndex((item) => item.Month === selectedMonth);
    const selectedMonthTotalSales = SalesData.find((item) => item.month === selectedMonth)?.income || 0;
    const lastMonthTotalSales = selectedMonthIndex > 0 ? totalSalesPerMonth[selectedMonthIndex - 1].total : 0;



    const fontSize = window.innerWidth > 600 ? 13 : 8;
    const selectedYearData = IncomeData.find((item) => item.year === parseInt(selectedYear))?.months || [];
    const handleYearChange = (value) => {
        setSelectedYear(parseInt(value));
    };

    const formatCurrency = (value) => {
        return new Intl.NumberFormat('en-PH', { style: 'currency', currency: 'PHP' }).format(value);
    };

    const totalIncomePerYear = IncomeData.map((year) => ({
        year: year.year,
        total: year.months.reduce((acc, month) => acc + month.income, 0),
    }));


    const selectedYearIndex = totalIncomePerYear.findIndex((item) => item.year === parseInt(selectedYear));
    const selectedYearTotalIncome = selectedYearIndex !== -1 ? totalIncomePerYear[selectedYearIndex].total : 0;
    const lastYearTotalIncome = selectedYearIndex > 0 ? totalIncomePerYear[selectedYearIndex - 1].total : 0;

    // Calculate the percentage change for Income
    let percentageChangeIncome;
    if (lastYearTotalIncome !== 0) {
        percentageChangeIncome = ((selectedYearTotalIncome - lastYearTotalIncome) / lastYearTotalIncome) * 100;
    } else {
        // Set the percentage change to 0 when lastYearTotalIncome is zero
        percentageChangeIncome = 0;
    }
    //Calcumate the percentage change for sales
    let percentageChangeSales;
    if (lastMonthTotalSales !== 0) {
        percentageChangeSales = ((selectedMonthTotalSales - lastMonthTotalSales) / lastMonthTotalSales) * 100;
    } else {
        // Set the percentage change to 0 when lastYearTotalIncome is zero
        percentageChangeSales = 0;
    }
    

    // Determine arrow icon and text color for total income
    let arrowIconIncome, textColorIncome;
    if (percentageChangeIncome > 0) {
        arrowIconIncome = <FaArrowUp style={{fontSize: '14px', marginRight: '2px'}}/>;
        textColorIncome = 'text-success';
    } else if (percentageChangeIncome < 0) {
        arrowIconIncome = <FaArrowDown style={{fontSize: '14px', marginRight: '2px'}}/>;
        textColorIncome = 'text-danger';
    } else {
        // No arrow icon when percentageChange is 0
        arrowIconIncome = null;
        textColorIncome = 'text-muted';
    }
    // Determine arrow icon and text color for total sales
    let arrowIconSales, textColorSales;
    if (percentageChangeSales > 0) {
        arrowIconSales = <FaArrowUp style={{fontSize: '14px', marginRight: '2px'}}/>;
        textColorSales = 'text-success';
    } else if (percentageChangeSales < 0) {
        arrowIconSales = <FaArrowDown style={{fontSize: '14px', marginRight: '2px'}}/>;
        textColorSales = 'text-danger';
    } else {
        // No arrow icon when percentageChange is 0
        arrowIconSales = null;
        textColorSales = 'text-muted';
    }
    const productColors = {
        'Infin8 Pure Organic Barley': '#ffac81',
        'Package 1': '#ff928b',
        'Package 2': '#fec3a6',
        'Package 3': '#efe9ae',
        'Package 4': '#cdeac0',
    };
    const productNames = Array.from(new Set(
        selectedMonthData.flatMap(weekData => 
          Object.keys(weekData).filter(key => key !== 'week')
        )
      )).sort();

  return (
    <main id='dashboard'>
              {!userLoggedIn && (<Navigate to={'/login'} replace={true} />)}
        <div class="head-title">
            <div class="left">
                <h1>Dashboard</h1>
            </div>
        </div>

        <ul class="box-info">
            <li>
                <MdFormatListBulletedAdd className='bx'/>
                <span class="text">
                    <h3>{TotalOrders}</h3>
                    <p>Total Orders</p>
                </span>
            </li>
            <li>
                <FaWallet className='bx'/>
                <span class="text">
                    <h3>{formatCurrency(walletBalance)}</h3>
                    <p>Wallet Balance</p>
                </span>
            </li>
            <li>
                <FcSalesPerformance className='bx'/>
                <span class="text">
                    <h3>{formatCurrency(totalSales)}</h3>
                    <p>Total Sales</p>
                </span>
            </li>
            <li>
                <FaUsers className='bx'/>
                <span class="text">
                    <h3>{chartData.length - 1}</h3>
                    <p>Total Members</p>
                </span>
            </li>
        </ul>

        <div class="table-data">
            <div class="total-sales">
                <div class="head">
                    <h3>Total Orders</h3>
                    <div>
                        <span>Select Month: </span>
                        <Select defaultValue={selectedMonth.toString()} style={{ width: 120 }} onChange={handleMonthChange}>
                            {SalesData.map((item) => (
                            <Option key={item.month} value={item.month.toString()}>
                                {item.month}
                            </Option>
                            ))}
                        </Select>
                    </div>
                </div>
                <div className="head-info">
                    <div className='texts'>
                        <h4>₱{selectedMonthTotalSales.toLocaleString()}.00</h4>
                        <p>Total Sales Monthly</p>
                    </div>
                    <div className='arrows'>
                        <span className={` ${textColorSales}`}>
                            {arrowIconSales} {Math.abs(percentageChangeSales).toFixed(1)}%
                        </span>
                        <span className="text-muted">Since last month</span>
                    </div>
                </div>
                <div className="sales-graph">
                    <ResponsiveContainer width="100%" height={400}>
                        <BarChart data={selectedMonthData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="week" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            {productNames.map((productName, index) => (
                            <Bar key={productName} dataKey={productName} fill={productColors[productName]} name={productName} />
                        ))}
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </div>
            <div class="total-income">
                <div class="head">
                    <h3>Total Sales</h3>
                    <div>
                        <span>Select Year: </span>
                        <Select defaultValue={selectedYear.toString()} style={{ width: 120 }} onChange={handleYearChange}>
                            {IncomeData.map((item) => (
                            <Option key={item.year} value={item.year.toString()}>
                                {item.year}
                            </Option>
                            ))}
                        </Select>
                    </div>
                </div>
                <div className="head-info">
                    <div className='texts'>
                        <h4>{formatCurrency(selectedYearTotalIncome)}</h4>
                        <p>Total Sales per year</p>
                    </div>
                    <div className='arrows'>
                        <span className={` ${textColorIncome}`}>
                            {arrowIconIncome} {Math.abs(percentageChangeIncome).toFixed(1)}%
                        </span>
                        <span className="text-muted">Since last year</span>
                    </div>
                </div>
                <div class="income-graph">
                <ResponsiveContainer width="100%" height={400}>
            <LineChart
                data={selectedYearData}
                margin={{ top: 20, right: 30, bottom: 20, left: 20 }}
            >
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis dataKey="month" scale="band" />
                <YAxis tickFormatter={(value) => formatCurrency(value)} tick={{ fontSize }}/>
                <Tooltip formatter={(value) => formatCurrency(value)}/>
                <Legend />
                <Line type="monotone" dataKey="income" stroke="#8884d8" activeDot={{ r: 5 }} />
                </LineChart>
                </ResponsiveContainer>
                </div>
            </div>
        </div>
    </main>
  )
}

export default Dashboard;
