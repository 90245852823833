import React, { useState, useEffect } from 'react'
import './CashbondRequest.css';
import { IoSearch } from "react-icons/io5";
import { Pagination, Popconfirm, message } from 'antd';
import NoDataFount from '../../../Assets/no-data/NoDataFount';
import { equalTo, get, orderByChild, query, ref, update } from 'firebase/database';
import { db } from '../../../firebase/config';
import moment from 'moment'
import SampleProduct from '../../../DataTest/SampleProducts';

const CashbondRequest = () => {

    const [currentPage, setCurrentPage] = useState(1); // State for current page
    const pageSize = 10; 
    const [loading, setLoading] = useState(false); 
    const [openCompleted, setOpenCompleted] = useState(false);
    const [openCancelled, setOpenCancelled] = useState(false);
    const [data, setData] = useState([]);

      // search functionality
   const [searchQuery, setSearchQuery] = useState('');
   const [filteredRequests, setFilteredRequests] = useState([]);
   useEffect(() => {
    const cashbondInfo = async () => {
      try {
        const cashbondInfoRef = ref(db, 'cashbond');
        const snapshot = await get(cashbondInfoRef);
        if (snapshot.exists()) {
          const cashbondArray = Object.entries(snapshot.val())
            .map(([cashbondID, cashbondData]) => ({
              cashbondID: cashbondID,
              ...cashbondData,
            }));
            setFilteredRequests(cashbondArray)
            setData(cashbondArray);
        } else {
            setData([]);
        }
      } catch (error) {
        console.error(error);
      }
    };
  
    cashbondInfo();
  }, [loading]);
    // Filter the members whenever the search query changes
    useEffect(() => {
        const filtered = data.filter(
            (request) =>
            request.cashbondID.toLowerCase().includes(searchQuery.toLowerCase()) ||
            request.sender.toLowerCase().includes(searchQuery.toLowerCase()) ||
            request.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
            request.phone.toLowerCase().includes(searchQuery.toLowerCase()) 
        );
        setFilteredRequests(filtered);
        // Reset current page to 1 when search query changes
        setCurrentPage(1);
    }, [searchQuery]);
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const displayedRequests = filteredRequests.slice(startIndex, endIndex);


    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const showPopconfirmCompleted = (ReqID) => {
        setOpenCompleted(true);
    };
    const showPopconfirmCancelled = (ReqID) => {
        setOpenCancelled(true);
    };

    const handleCancel = () => {
        setOpenCompleted(false);
        setOpenCancelled(false);
    };
    
    const handleCompleted = (cashoutID) => {
        setLoading(true);

        const updates = {};
        const action = 'Approved'
        updates['/cashbond/' + cashoutID + '/status'] = action;
        setTimeout(() => {
            message.success(`Request ID ${cashoutID} has been marked as Completed`);
    
            // Set loading state back to false after action is completed
            setLoading(false);
            setOpenCompleted(false);
        }, 2000);
        return update(ref(db), updates);
    };

    const handleCancelled = (cashoutID) => {
        setLoading(true);

        const updates = {};
        const action = 'Cancelled'
        updates['/cashbond/' + cashoutID + '/status'] = action;
        setTimeout(() => {
           
            message.warning(`Request ID ${cashoutID} has been marked as Cancelled`);
    
            // Set loading state back to false after action is completed
            setLoading(false);
            setOpenCancelled(false);
        }, 2000);
        return update(ref(db), updates);
    };
  return (
    <main id='cashbondReq'>
        <div className="head-title">
            <div className="left">
                <h1>RTS Load Requests</h1>
            </div>
        </div>
        <div className="requests-list">
            <div className="head">
                <div className="row justify-content-between align-items-center">
                    <div className="col-12 col-lg-9 col-md-7">
                        <h4>Requests List</h4>
                    </div>
                    <div className="col-12 col-lg-3 col-md-5">
                        <div className="search-bar">
                            <IoSearch className='search-icon-admin'/>
                            <input type="search"
                                placeholder='Search here...'
                                className='form-control'
                                onChange={handleSearchChange}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="table-data">
                {displayedRequests.length === 0 ? (
                    <NoDataFount/>
                ) : (
                <div className="table-container">
                    <table>
                        <caption>Total Approved RTS Load Requests: ₱ 800.00</caption>
                        <thead>
                            <tr>
                                <th>Request ID</th>
                                <th>Date Requested</th>
                                <th>Sender Name</th>
                                <th>Email</th>
                                <th>Contact No.</th>
                                <th>Amount</th>
                                <th>Status</th>
                            </tr>
                        
                        </thead>
                        <tbody>
                        {displayedRequests.map((request) => (
                            <tr key={request.cashbondID}>
                                <td data-cell='Request ID'>{request.cashbondID}</td>
                                <td data-cell='Date Requested'>{moment(request.date).format('MMMM DD YYYY') }</td>
                                <td data-cell='Sender Name'>{request.sender}</td>
                                <td data-cell='Email'>{request.email}</td>
                                <td data-cell='Contact No.'>{request.phone}</td>
                                <td data-cell='Amount'>₱ {request.amount}.00</td>
                                <td data-cell='Status'>
                                    {request.status === "Pending" ? (
                                        <div className='action-btn'>
                                            <Popconfirm
                                                title="Are you sure to mark this as Completed?"
                                                onConfirm={() => handleCompleted(request.cashbondID)}
                                                open={openCompleted[request.cashbondID]} // Use specific state variable for each request
                                                onCancel={handleCancel}
                                                okText="YES"
                                                cancelText="NO"
                                                okButtonProps={{ loading: loading }}
                                            >
                                                <button className='btn btn-link text-success' onClick={() => showPopconfirmCompleted(request.cashbondID)}>
                                                    Complete
                                                </button>
                                            </Popconfirm>
                                            <Popconfirm
                                                title="Are you sure to mark as Cancelled?"
                                                onConfirm={() => handleCancelled(request.cashbondID)}
                                                open={openCancelled[request.cashbondID]} // Use specific state variable for each request
                                                onCancel={handleCancel}
                                                okText='YES'
                                                cancelText="NO"
                                                okButtonProps={{ loading: loading }}
                                            >
                                                <button className='btn btn-link text-danger' onClick={() => showPopconfirmCancelled(request.cashbondID)}>
                                                    Cancel
                                                </button>
                                            </Popconfirm>
                                        </div>
                                    ) : (
                                        <span className={`status ${request.status}`}>{request.status}</span>
                                    )}
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    <div className="pagination-container">
                        <Pagination
                            current={currentPage}
                            // total={filteredProducts.length}
                            onChange={handlePageChange}
                            pageSize={pageSize}
                            showSizeChanger={false}
                        />
                    </div>
                </div>
                )}
            </div>
      </div>
    </main>
  )
}

export default CashbondRequest;
