import React, { useEffect, useState } from 'react';
import './Home.css'
import Header from '../../Header/Header';
import Header2 from '../../Header/Header2';
import Footer from '../../Footer/Footer';
import AboutUs from '../AboutUs/AboutUs';
import Products from '../Product/Products';
import product_banner from '../../../../Assets/images/products/baner-right-image.jpg';
import home_banner from '../../../../Assets/images/home_banner.jpg'
import ContactUs from '../ContactUs/ContactUs';
import { BackTop } from 'antd';

const Home = () => {

  const [showScrollButton, setShowScrollButton] = useState(false);

  useEffect(() => {
      const handleScroll = () => {
          if (window.scrollY > 600) {
              setShowScrollButton(true);
          } else {
              setShowScrollButton(false);
          }
      };

      window.addEventListener('scroll', handleScroll);

      return () => {
          window.removeEventListener('scroll', handleScroll);
      };
  }, []);

  const handleScrollTop = () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
        <Header2/> 
        <section id="home">
            <div className="container home-section">
              <div className="row">
                <div className="col-lg-6 left-banner">
                  <div className="text-banner">
                    <h1>Welcome to <br/> <span style={{fontSize: '70px', color: 'var(--second-color)'}}>Loyalista!</span></h1>
                    <p>A revolutionary platform designed to transform the way you engage with e-commerce through dropshipping. 
                        Reflecting our commitment to providing modern, efficient, and profitable solutions for online entrepreneurs.
                    </p>
                  </div>
                  
                </div>
                <div className="col-lg-6 right-banner">
                  <div className='img-banner'>
                    <img src={home_banner} alt="" />
                  </div>
                </div>
              </div>
              
            </div>
        </section>
        {/* <AboutUs/>
        <Products/>
        <ContactUs/>
        {showScrollButton && <BackTop onClick={handleScrollTop} />} */}
        <Footer/> 
    </>
  )
}

export default Home;
