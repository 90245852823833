import React, { useState, useEffect } from 'react';
import './AdminDashboard.css'
import { MdFormatListBulletedAdd } from "react-icons/md";
import { FaRegListAlt, FaArrowUp, FaArrowDown, FaUsers } from "react-icons/fa";
import { FcSalesPerformance } from "react-icons/fc";
import { GiProgression } from "react-icons/gi";
import { Select } from 'antd';
import { db } from '../../../firebase/config';
import {
    BarChart,
    Bar,
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
  } from "recharts";
import { child, get, ref } from 'firebase/database';
const { Option } = Select;

const AdminDashboard = () => {
    const currentMonth = new Date().toLocaleString('en-US', { month: 'long' });
    const currentYear = new Date().toLocaleString('en-US', { year: 'numeric' });
    const [SalesData,setSalesData] = useState([]);
    const [totalSales, setTotalSales] = useState(0)
    const [selectedMonth, setSelectedMonth] = useState(currentMonth);
    // const [IncomeData, setIncomeData] = useState([])
    const [selectedYear, setSelectedYear] = useState(currentYear);
    const [IncomeData, setIncomeData] = useState([])
    const [topSeller, setTopSeller] = useState([])
    const [memberCount, setMemberCount] = useState([])
    const [TotalIncome, setTotalIncome] = useState([])
    useEffect(() => {
        const fetchData = async () => {
            try {
                const snapshot = await get(child(ref(db), 'users'));
                if (snapshot.exists()) {
                    const userData = snapshot.val();
                    const userArray = Object.values(userData);
                    setMemberCount(userArray.length)
                } else {
                    alert('No data available');
                }
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, []);
    useEffect(() => {
        const fetchOrderItems = async () => {
            try {
                const ordersRef = ref(db, 'orders');
                const snapshot = await get(ordersRef);
    
                if (snapshot.exists()) {
                    const ordersData = snapshot.val();
                    const sellerSales = {};
                    let overallTotalQuantity = 0;
    
                    Object.entries(ordersData).forEach(([orderId, orderData]) => {
                        if (orderData.status === 'Completed') {
                            const seller = orderData.seller;
    
                            if (!sellerSales[seller]) {
                                sellerSales[seller] = 0;
                            }
    
                            orderData.items.forEach((product) => {
                                if (product.quantity) {
                                    const quantity = product.quantity;
                                    sellerSales[seller] += quantity;
                                    overallTotalQuantity += quantity;
                                }
                            });
                        }
                    });
    
                    // Find the top seller
                    const topSeller = Object.entries(sellerSales).reduce((top, [seller, totalQuantity]) => {
                        if (totalQuantity > top.totalQuantity) {
                            return { seller, totalQuantity };
                        }
                        return top;
                    }, { seller: null, totalQuantity: 0 });
    
                    setTopSeller(topSeller); // Assuming you have a state setter for top seller
                    setTotalSales(overallTotalQuantity); // Set the overall total quantity of products sold
                }
            } catch (error) {
                console.error(error);
            }
        };
    
        fetchOrderItems();
    }, []);
    
    useEffect(() => {
        const fetchOrderItems = async () => {
            try {
                const ordersRef = ref(db, 'orders');
                const snapshot = await get(ordersRef);
    
                if (snapshot.exists()) {
                    const orderItemsArray = Object.entries(snapshot.val())
                        .filter(([orderId, orderData]) => orderData.status === 'Completed')
                        .map(([orderId, orderData]) => ({
                            orderId: orderId,
                            ...orderData,
                        }))
                        .sort((b, a) => b.timestamp - a.timestamp);
    
                    const transformedData = {};
                    let overallTotalIncome = 0;
    
                    orderItemsArray.forEach((order) => {
                        const date = new Date(order.timestamp);
                        const year = date.getFullYear();
                        const month = date.toLocaleString('en-US', { month: 'short' });
    
                        if (!transformedData[year]) {
                            transformedData[year] = {};
                        }
    
                        if (!transformedData[year][month]) {
                            transformedData[year][month] = 0;
                        }
    
                        order.items.forEach((product) => {
    
                            // Calculate income from product price and quantity
                            if ('price' in product && 'quantity' in product) {
                                const incomeFromProduct = parseInt(product.category === 'Package' ? (product.price - (500 * product.quantity)) : product.price * product.quantity);
                                transformedData[year][month] += incomeFromProduct;
                                overallTotalIncome += incomeFromProduct;
                            }
                        });
                    });
    
                    const finalData = Object.entries(transformedData).map(([year, monthsData]) => {
                        const months = Object.entries(monthsData).map(([month, income]) => ({
                            month: month,
                            income: income,
                        }));
                        return {
                            year: parseInt(year),
                            months: months,
                        };
                    });
                    
                    setIncomeData(finalData);
                    setTotalIncome(overallTotalIncome); // Set the overall total income
                }
            } catch (error) {
                console.error(error);
            }
        };
    
        fetchOrderItems();
    }, []);
    

    useEffect(() => {
        const fetchOrderItems = async () => {
            try {
                const ordersRef = ref(db, 'orders');
                const snapshot = await get(ordersRef);
    
                if (snapshot.exists()) {
                    const orderItemsArray = Object.entries(snapshot.val())
                        .filter(([orderId, orderData]) => orderData.status === 'Completed')
                        .map(([orderId, orderData]) => ({
                            orderId: orderId,
                            ...orderData,
                        }))
                        .sort((b, a) => b.timestamp - a.timestamp);
    
                    let overallSalesSum = 0;
                    const transformedData = {};
    
                    orderItemsArray.forEach((order) => {
                        const date = new Date(order.timestamp);
                        const year = date.getFullYear();
                        const month = date.toLocaleString('en-US', { month: 'long' });
                        const week = `Week ${Math.ceil(date.getDate() / 7)}`;
    
                        if (!transformedData[year]) {
                            transformedData[year] = {};
                        }
    
                        if (!transformedData[year][month]) {
                            transformedData[year][month] = {
                                weeks: {},
                                income: 0
                            };
                        }
    
                        if (!transformedData[year][month].weeks[week]) {
                            transformedData[year][month].weeks[week] = {};
                        }
    
                        order.items.forEach((product) => {
                            const productName = product.name;
                            const quantity = product.quantity;
                            const price = product.price;
                            const productIncome = price * quantity;
    
                            if (!transformedData[year][month].weeks[week][productName]) {
                                transformedData[year][month].weeks[week][productName] = 0;
                            }
    
                            transformedData[year][month].weeks[week][productName] += quantity;
    
                            overallSalesSum += productIncome;
                            transformedData[year][month].income += productIncome;
    
                            // Optional: If you still want to track commissions separately
                            if ('commissions' in product && 'level1' in product.commissions) {
                                overallSalesSum += product.commissions.level1;
                                transformedData[year][month].income += product.commissions.level1;
                            }
                        });
                    });
    
                    const finalData = Object.entries(transformedData).map(([year, monthsData]) => {
                        return Object.entries(monthsData).map(([month, monthData]) => {
                            return {
                                year: parseInt(year),
                                month: month,
                                weeks: Object.entries(monthData.weeks).map(([week, weekData]) => ({
                                    week: week,
                                    ...weekData
                                })),
                                income: monthData.income
                            };
                        });
                    }).flat();

                    setSalesData(finalData);
    
                }
            } catch (error) {
                console.error(error);
            }
        };
    

        fetchOrderItems();
    }, []);
    
    const productColors = {
        'Infin8 Pure Organic Barley': '#ffac81',
        'Package 1': '#ff928b',
        'Package 2': '#fec3a6',
        'Package 3': '#efe9ae',
        'Package 4': '#cdeac0',
    };

    const selectedMonthData = SalesData.find((item) => item.month === selectedMonth)?.weeks || [];
    const handleMonthChange = (value) => {
        setSelectedMonth(value);
    };
    const totalSalesPerMonth = SalesData.map((month) => ({
        Month: month.month,
        total: month.weeks.reduce((acc, week) => acc + Object.values(week).slice(1).reduce((a, b) => a + b, 0), 0),
    }));
    const selectedMonthIndex = totalSalesPerMonth.findIndex((item) => item.Month === selectedMonth);
    const selectedMonthTotalSales = selectedMonthIndex !== -1 ? totalSalesPerMonth[selectedMonthIndex].total : 0;
    const lastMonthTotalSales = selectedMonthIndex > 0 ? totalSalesPerMonth[selectedMonthIndex - 1].total : 0;



    const productNames = Array.from(new Set(
        selectedMonthData.flatMap(weekData => 
          Object.keys(weekData).filter(key => key !== 'week')
        )
      )).sort();

    const fontSize = window.innerWidth > 600 ? 13 : 8;
    const selectedYearData = IncomeData.find((item) => item.year === parseInt(selectedYear))?.months || [];
    const handleYearChange = (value) => {
        setSelectedYear(parseInt(value));
    };

    const formatCurrency = (value) => {
        return new Intl.NumberFormat('en-PH', { style: 'currency', currency: 'PHP' }).format(value);
    };

    const totalIncomePerYear = IncomeData.map((year) => ({
        year: year.year,
        total: year.months.reduce((acc, month) => acc + month.income, 0),
    }));

    const selectedYearIndex = totalIncomePerYear.findIndex((item) => item.year === parseInt(selectedYear));
    const selectedYearTotalIncome = selectedYearIndex !== -1 ? totalIncomePerYear[selectedYearIndex].total : 0;
    const lastYearTotalIncome = selectedYearIndex > 0 ? totalIncomePerYear[selectedYearIndex - 1].total : 0;

    // Calculate the percentage change for Income
    let percentageChangeIncome;
    if (lastYearTotalIncome !== 0) {
        percentageChangeIncome = ((selectedYearTotalIncome - lastYearTotalIncome) / lastYearTotalIncome) * 100;
    } else {
        // Set the percentage change to 0 when lastYearTotalIncome is zero
        percentageChangeIncome = 0;
    }
    //Calcumate the percentage change for sales
    let percentageChangeSales;
    if (lastMonthTotalSales !== 0) {
        percentageChangeSales = ((selectedMonthTotalSales - lastMonthTotalSales) / lastMonthTotalSales) * 100;
    } else {
        // Set the percentage change to 0 when lastYearTotalIncome is zero
        percentageChangeSales = 0;
    }
    
    

    // Determine arrow icon and text color for total income
    let arrowIconIncome, textColorIncome;
    if (percentageChangeIncome > 0) {
        arrowIconIncome = <FaArrowUp style={{fontSize: '14px', marginRight: '2px'}}/>;
        textColorIncome = 'text-success';
    } else if (percentageChangeIncome < 0) {
        arrowIconIncome = <FaArrowDown style={{fontSize: '14px', marginRight: '2px'}}/>;
        textColorIncome = 'text-danger';
    } else {
        // No arrow icon when percentageChange is 0
        arrowIconIncome = null;
        textColorIncome = 'text-muted';
    }
    // Determine arrow icon and text color for total sales
    let arrowIconSales, textColorSales;
    if (percentageChangeSales > 0) {
        arrowIconSales = <FaArrowUp style={{fontSize: '14px', marginRight: '2px'}}/>;
        textColorSales = 'text-success';
    } else if (percentageChangeSales < 0) {
        arrowIconSales = <FaArrowDown style={{fontSize: '14px', marginRight: '2px'}}/>;
        textColorSales = 'text-danger';
    } else {
        // No arrow icon when percentageChange is 0
        arrowIconSales = null;
        textColorSales = 'text-muted';
    }
  return (
    <main id='adminDashboard'>
        <div class="head-title">
            <div class="left">
                <h1>Dashboard</h1>
            </div>
        </div>

        <ul class="box-info">
            <li>
                <span class="text">
                    <p>Top Seller</p>
                    <h4>{topSeller.seller || 'No Orders Yet'}</h4>
                    <span>Total Sales: <strong>{topSeller.totalQuantity || 0} </strong></span>
                    
                </span>
            </li>
            <li>
                <FaUsers  className='bx'/>
                <span class="text">
                    <h3>{memberCount}</h3>
                    <p>Total Members</p>
                </span>
            </li>
            <li>
                <GiProgression className='bx'/>
                <span class="text">
                    <h3>{totalSales.toLocaleString()}</h3>
                    <p>Total Sales</p>
                </span>
            </li>
            <li>
                <FcSalesPerformance className='bx'/>
                <span class="text">
                    <h3>{formatCurrency(TotalIncome)}</h3>
                    <p>Total Income</p>
                </span>
            </li>
        </ul>

        <div class="table-data">
            <div class="total-sales">
                <div class="head">
                    <h3>Total Orders</h3>
                    <div>
                        <span>Select Month: </span>
                        <Select defaultValue={selectedMonth.toString()} style={{ width: 120 }} onChange={handleMonthChange}>
                            {SalesData.map((item) => (
                            <Option key={item.month} value={item.month.toString()}>
                                {item.month}
                            </Option>
                            ))}
                        </Select>
                    </div>
                </div>
                <div className="head-info">
                    <div className='texts'>
                        <h4>{selectedMonthTotalSales}</h4>
                        <p>Total Orders Monthly</p>
                    </div>
                    <div className='arrows'>
                        <span className={` ${textColorSales}`}>
                            {arrowIconSales} {Math.abs(percentageChangeSales).toFixed(1)}%
                        </span>
                        <span className="text-muted">Since last month</span>
                    </div>
                </div>
                <div className="sales-graph">
                   <ResponsiveContainer width="100%" height={400}>
                        <BarChart data={selectedMonthData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="week" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            {productNames.map((productName, index) => (
                            <Bar key={productName} dataKey={productName} fill={productColors[productName]} name={productName} />
                        ))}
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </div>
            <div class="total-income">
                <div class="head">
                    <h3>Total Income</h3>
                    <div>
                        <span>Select Year: </span>
                        <Select defaultValue={selectedYear.toString()} style={{ width: 120 }} onChange={handleYearChange}>
                            {IncomeData.map((item) => (
                            <Option key={item.year} value={item.year.toString()}>
                                {item.year}
                            </Option>
                            ))}
                        </Select>
                    </div>
                </div>
                <div className="head-info">
                    <div className='texts'>
                        <h4>{formatCurrency(selectedYearTotalIncome)}</h4>
                        <p>Total Income per year</p>
                    </div>
                    <div className='arrows'>
                        <span className={` ${textColorIncome}`}>
                            {arrowIconIncome} {Math.abs(percentageChangeIncome).toFixed(1)}%
                        </span>
                        <span className="text-muted">Since last year</span>
                    </div>
                </div>
                <div class="income-graph">
                    <ResponsiveContainer width="100%" height={400}>
                        <LineChart
                            data={selectedYearData}
                            margin={{ top: 20, right: 30, bottom: 20, left: 20 }}
                        >
                            <CartesianGrid strokeDasharray="3 3"/>
                            <XAxis dataKey="month" scale="band" />
                            <YAxis tickFormatter={(value) => formatCurrency(value)} tick={{ fontSize }}/>
                            <Tooltip formatter={(value) => formatCurrency(value)}/>
                            <Legend />
                            <Line type="monotone" dataKey="income" stroke="#8884d8" activeDot={{ r: 5 }}
                            />
                        </LineChart>
                    </ResponsiveContainer>
                </div>
            </div>
        </div>
    </main>
  )
}

export default AdminDashboard;
