import React, { useState, useEffect, useRef } from 'react'
import './OrderHistory_admin.css'
import { FaDownload } from "react-icons/fa6";
import { IoSearch } from "react-icons/io5";
import { message, Pagination, Popconfirm } from 'antd';
import { CSVLink } from 'react-csv';
import SampleProduct from '../../../DataTest/SampleProducts'; //for UI only
import NoDataFount from '../../../Assets/no-data/NoDataFount';
import { get, ref, serverTimestamp, set, update } from 'firebase/database';
import {db} from '../../../firebase/config'
import moment from 'moment'

const OrderHistory = () => {
    const [amount, setAmount] = useState(0)
    const [orders, setOrders] = useState([]);
    const [openCompleted, setOpenCompleted] = useState(false);
    const [openReturned, setOpenReturned] = useState(false);
    const [openCancelled, setOpenCancelled] = useState(false);
    const [loading, setLoading] = useState(false); 
    const [OrderData, setOrderData] = useState([])
    const [dependencyKey, setDependencyKey] = useState(0)
    const formatCurrency = (value) => {
        return new Intl.NumberFormat('en-PH', { style: 'currency', currency: 'PHP' }).format(value);
      };
    useEffect(() => {
        const fetchOrderItems = async () => {
          try {
            const ordersRef = ref(db, 'orders');
            const snapshot = await get(ordersRef);
    
            if (snapshot.exists()) {
              const orderItemsArray = Object.entries(snapshot.val())
                .map(([orderId, orderData]) => ({
                  orderId: orderId,
                  ...orderData,
                  
                  items: orderData.items || [], // Ensure items is always an array
                }))
                .sort((a, b) => b.timestamp - a.timestamp); //(latest data first)

              const formattedData = orderItemsArray.map((order) => ({
                orderID: order.orderId,
                dateOrder: moment(order.timestamp).format('MMM. DD, YYYY'),
                customer: order.customer?.Fullname,
                seller:order.seller,
                user:order.user,
                email: order.customer?.Email,
                contact: order.customer?.ContactNum,
                address:order.address?.fullAddress,
                shippingFee: order.invoice.AmountDue.Shipping,
                PaymentMethod:order.invoice.PaymentMethod,
                items: order.items.map((product) => ({
                  product: product.name,
                  quantity: product.quantity,
                  category:product.category,
                  cashbond: product.category === 'Package' ? product.quantity * 500 : 0
                })),
                total: formatCurrency(parseInt(order.total).toFixed(2)),
                status: order.status,
              }));
              setOrderData(formattedData);
              setFilteredProducts(formattedData);
            } else {
                setOrderData([]);
            }
          } catch (error) {
            console.error(error);
          }
        };
    
        fetchOrderItems();
    }, [loading]);
    
    useEffect(() => {
        // Fetch orders or set sample data
        setOrders(OrderData.orders || []);
    }, []);

    //CSV download table
    const [csvData, setCsvData] = useState([]);
    const csvHeaders = ['Order ID', 'Date Order', 'Customer', 'Items', 'Total Amount', 'Status'];
    const csvLinkRef = useRef(null);

    const handleDownload = () => {
        const formattedCsvData = [
            csvHeaders,
            ...OrderData.map(order => [
                order.orderID,
                order.dateOrder,
                order.customer,
                order.items.map(item => `${item.product} (${item.quantity}x)`).join(', '),
                order.total,
                order.status
            ])
        ];
        setCsvData(formattedCsvData);
    };
    
    const [currentPage, setCurrentPage] = useState(1); // State for current page
    const pageSize = 5; // Number of items per page

    const productColors = {
        'Infin8 Pure Organic Barley': 'var(--product-one)',
        'Package 1': 'var(--product-two)',
        'Package 2': 'var(--product-three)',
        'Package 3': 'var(--product-four)',
        'Product Five': 'var(--product-five)',
        // Add more colors for other products if needed
      };

    // search functionality
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredProducts, setFilteredProducts] = useState([]);
   
    // Filter the products whenever the search query changes
    useEffect(() => {
        const filtered = OrderData.filter(order =>
            order.orderID?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            order.customer?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            order.seller?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            order.status?.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredProducts(filtered);
        setCurrentPage(1);
    }, [searchQuery]); // Add SampleProduct.orders as a dependency
    

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    // Calculate the start and end index of the products to display based on current page and page size
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const displayedProducts = filteredProducts.slice(startIndex, endIndex);
    
    const showPopconfirmCompleted = (orderID) => {
        setOpenCompleted(true);
    };
    const showPopconfirmReturned = (orderID) => {
        setOpenReturned(true);
    };
    const showPopconfirmCancelled = (orderID) => {
        setOpenCancelled(true);
    };
    const handleCancel = () => {
        setOpenCompleted(false);
        setOpenReturned(false);
        setOpenCancelled(false);
    };
    const cashbondRequest = async(selectedOrder) => {
        const cashBond = ref(db, `cashbond`);
        try {
          const cashBondSnapshot = await get(cashBond);
      
          let cashBondItemsCount = 0;
      
          if (cashBondSnapshot.exists()) {
            cashBondItemsCount = Object.keys(cashBondSnapshot.val()).length;
          }
          let totalCashBond = 0
          selectedOrder.items.forEach((product) => {
            if(product.category === 'Package'){
                totalCashBond += product.quantity * 500;
            }
          }
          )
          const timestamp = serverTimestamp();
          const cashBondID = generateCashBondID(cashBondItemsCount);
      
          set(ref(db, `cashbond/${cashBondID}`), {
            user: selectedOrder.user,
            date: timestamp,
            sender: selectedOrder.seller,
            email: selectedOrder.email,
            phone: selectedOrder.contact,
            amount:  totalCashBond,
            status: 'Approved',
          });
          // const cashbond = {
          //   id:cashBondID,
          //   user: user.uid,
          //   date: moment(timestamp).format('MMM. DD, YYYY'),
          //   sender: userFullName,
          //   email: userInformation?.email,
          //   phone: userInformation?.phone,
          //   amount: CashbondRequest,
          //   status: 'Pending',
    
          // }
          // message.success('Send Successfully');
          // const subject = `Your Cashbond Request ${cashBondID}has been approved`
          // const email = user.email
          // const response = await fetch('http://localhost:8080/CashbondRequest', {
          //   method: 'POST',
          //   headers: {
          //     'Content-Type': 'application/json',
          //   },
          //   body: JSON.stringify({ email, cashbond, subject }),
          // });
          // if (response.ok) {
          //   console.log('Email sent successfully');
          // } else {
          //   console.error('Failed to send email');
          // }
        } catch (error) {
          console.error('Error fetching or updating cash bond data:', error);
        }
      }
        const generateCashBondID = (count) => {
          const numericPart = (count + 1).toString().padStart(8, '0');
          const cashBondID = `CB-${numericPart}`;
          return cashBondID;
        };

    const handleCompleted = (order) => {
        setLoading(true);

        const updates = {};
        const action = 'Completed'
        updates['/orders/' + order.orderID + '/status'] = action;
        if  (order.shopType === 'ClientStore') {
        order.items.forEach((product) => {
            if (product.cashbond !== 0) {
                cashbondRequest(order)
            }
        });
        }

        setTimeout(() => {
            message.success(`Order No. ${order.orderID} has been marked as Completed`);
            setLoading(false);
            setOpenCompleted(prevState => ({
                ...prevState,
                [order.orderID]: false 
            }));
        }, 2000);

        return update(ref(db), updates)
    };
    
    const handleReturned = (orderId) => {
        setLoading(true);
    
        const updates = {};
        updates['/orders/' + orderId + '/status'] = 'Returned';
        setTimeout(() => {
            message.warning(`Order No. ${orderId} has been marked as Returned`);
            setLoading(false);
            setOpenReturned(prevState => ({
                ...prevState,
                [orderId]: false 
            }));
        }, 2000);
        return update(ref(db), updates);
    };
    
    const handleCancelled = (orderId) => {
        setLoading(true);
    
        const updates = {};
        updates['/orders/' + orderId + '/status'] = 'Cancelled';
        setTimeout(() => {
            message.error(`Order No. ${orderId} has been Cancelled`);
            setLoading(false);
            setOpenCancelled(prevState => ({
                ...prevState,
                [orderId]: false
            }));
        }, 2000);
        return update(ref(db), updates);
    };
  return (
    <main id='orderHistory'>
        <div class="head-title">
            <div class="left">
                <h1>Order History</h1>
            </div>
            <div className="right">
                <CSVLink
                    onClick={handleDownload}
                    ref={csvLinkRef}
                    data={csvData}
                    filename={'order_lists.csv'}
                    className="btn btn-primary"
                    >
                    <FaDownload/> Download Table
                </CSVLink>
            </div>
        </div>
        <div className="order-lists">
            <div className="head">
                <div className="row justify-content-between align-items-center">
                    <div className="col-12 col-lg-9 col-md-7">
                        <h4>Orders List</h4>
                    </div>
                    <div className="col-12 col-lg-3 col-md-5">
                        <div className="search-bar">
                            <IoSearch className='search-icon-admin'/>
                            <input type="search" 
                                    className='form-control' 
                                    placeholder='Search here...'
                                    onChange={handleSearchChange}
                            />
                        </div>
                    </div>
                </div>
                
                
            </div>
            <div className="table-data">
            {filteredProducts.length === 0 ? (
                <NoDataFount/>
            ) : (
                <div className='table-container'>
                <table>
                    <thead>
                        <tr>
                            <th>Seller Name</th>
                            <th>Order ID</th>
                            <th>Date Order</th>
                            <th>Customer</th>
                            <th>Items</th>
                            <th>Total Amount</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                    {displayedProducts.map((order, orderID) => (
                            <tr key={order.orderID}>
                                <td style={{ width: '20%' }} data-cell='Seller Name'>{order.seller}</td>
                                <td style={{ width: '10%' }} data-cell='Order ID'>{order.orderID}</td>
                                <td style={{ width: '10%' }} data-cell='Date Order'>{order.dateOrder}</td>
                                <td style={{ width: '20%' }} data-cell='Customer'>{order.customer}</td>
                                <td colSpan="2" id='items-holder' data-cell='Items'>
                                    {order.items.map((item, index) => (
                                        <span key={index} className='items-tag' style={{ backgroundColor: productColors[item.product] }}>
                                            {item.product} ({item.quantity}x)
                                        </span>
                                    ))}
                                </td>
                                <td style={{ width: '10%' }} data-cell='Total Amount'>{order.total}</td>
                                <td style={{ width: '10%' }} data-cell='Status'>
                                    { order.status === 'Approved' ? (
                                        <div className='action-btn'>
                                            <Popconfirm
                                                title="Are you sure to mark as Delivered?"
                                                onConfirm={() => handleCompleted(order)}
                                                open={openCompleted[order.orderID]}
                                                onCancel={handleCancel}
                                                okButtonProps={{ loading: loading }}
                                            >
                                                <button className='btn btn-link text-success' onClick={() => showPopconfirmCompleted(order.orderID)}>
                                                    Delivered
                                                </button>
                                            </Popconfirm>
                                            <Popconfirm
                                                title="Are you sure to mark as Returned?"
                                                onConfirm={() => handleReturned(order.orderID)}
                                                open={openReturned[order.orderID]}
                                                onCancel={handleCancel}
                                                okButtonProps={{ loading: loading }}
                                            >
                                                <button className='btn btn-link text-warning' onClick={() =>  showPopconfirmReturned(order.orderID)}>
                                                    Returned
                                                </button>
                                            </Popconfirm>
                                            <Popconfirm
                                                title="Are you sure to mark as Cancelled?"
                                                onConfirm={() => handleCancelled(order.orderID)}
                                                open={openCancelled[order.orderID]}
                                                onCancel={handleCancel}
                                                okButtonProps={{ loading: loading }}
                                            >
                                                <button className='btn btn-link text-danger' onClick={() => showPopconfirmCancelled(order.orderID)}>
                                                    Cancelled
                                                </button>
                                            </Popconfirm>
                                        </div>
                                    ) : (
                                        <span className={`status ${order?.status}`}>
                                            {order?.status}
                                        </span>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className="pagination-container">
                    <Pagination
                        current={currentPage}
                        total={filteredProducts.length}
                        onChange={handlePageChange}
                        pageSize={pageSize}
                        showSizeChanger={false}
                    />
                </div>
                </div>
            )}
            </div>
        </div>
    </main>
  )
}

export default OrderHistory;
