import React, { useEffect, useState } from 'react';
import './CartContent.css'
import { TbShoppingCartOff } from "react-icons/tb";
import { FaTrashAlt } from "react-icons/fa";
import { message, Popconfirm, Modal  } from 'antd';
import PurchaseForm from '../PurchaseForm/PurchaseForm';
import { useCartStore, useCheckType, userCashBond } from '../../DataTest/cart-store';
import SellerStorePurchaseForm from '../PurchaseForm/SellerStorePurchaseForm ';
import MyStorePurchaseForm from '../PurchaseForm/MyStorePurchaseForm ';
import { ref, remove } from 'firebase/database';
import { db } from '../../firebase/config';

const CartContent = ({ closeCart }) => {

    const {cart, setCart, clearCart} = useCartStore((state) => ({
        cart: state.cart,
        setCart:state.setCart,
        clearCart:state.clearCart,
    }))
    const { userInformation} = useCheckType((state) => ({
        userInformation: state.userInformation,
      }))
      const handleClearCart = async () => {
        try {
            await remove(ref(db, `cart/${userInformation.userID}`));
            clearCart()
            closeCart()
            message.success('All items in your cart have been deleted!');
        } catch (error) {
            console.error('Error removing items from the cart:', error);
            // Optionally display an error message if the removal fails
            message.error('Failed to clear cart. Please try again later.');
        }
    };

    const handleRemoveFromCart = async(index) => {
        try {
        await remove(ref(db, `cart/${userInformation.userID}/${index}`));
        // const updatedCartItems = [...cart];
        // updatedCartItems.splice(index, 1);
        // setCart(updatedCartItems);
        // closeCart()
        } catch (error) {
        console.error('Error removing item from the cart:', error);
        }
        message.success('Item has been removed from your cart!');
    }

    return (
        <div className="cartContent">
        {cart.length === 0 ? (
            <section className='d-flex justify-content-center align-items-center'>
                <TbShoppingCartOff style={{fontSize: '30px', marginRight: '10px'}}/>
                <p style={{margin: '0', fontWeight: '600', fontSize: '18px'}}>Your cart is currently empty.</p>
            </section>
            ) : (
            <section>
                <div className="row p-3">
                    <div className="col-12 d-flex justify-content-end">
                       
                        <Popconfirm
                            title="Delete All Items"
                            description="Are you sure to delete all items?"
                            onConfirm={handleClearCart}
                            okText="Yes"
                            cancelText="No"
                            >
                            <button className='btn btn-light text-secondary'>
                                REMOVE ALL ITEMS
                            </button>
                        </Popconfirm>   
                    </div>
                </div>
                <div className="item-holder">
                {cart.map((productData, index) => (
                    <React.Fragment key={index}>
                        <div className="row cart-items">
                            <div className="col-2 d-flex justify-content-center align-items-center">
                                <div className="image-holder">
                                    <img src={productData.image} alt={productData.name} />
                                </div>
                            </div>
                            <div className="col-5 d-flex flex-column">
                                <span style={{ fontSize: '15px' }}><b>{productData.name}</b></span>
                                <span style={{ fontSize: '15px' }}> {productData.quantity} x</span>
                            </div>
                            <div className="col-5 d-flex flex-column align-items-end">
                                <p className="float-end ml-auto mb-0" style={{ fontSize: '13px' }}>
                                    <strong>SubTotal: </strong> ₱{productData.price * productData.quantity}
                                </p>
                                <button className="btn float-end ml-auto" onClick={() => handleRemoveFromCart(productData.id)}>
                                    <FaTrashAlt className='text-danger' />
                                </button>
                            </div>
                        </div>
                        <div className='br' />
                    </React.Fragment>
                ))}
                </div>
            </section>
            )}
        </div>
    );
}

const CartFooter = ({ closeCart }) => {    

    const { cart } = useCartStore((state) => ({
        cart: state.cart
    }));
    
    const [openPurchaseForm, setOpenPurchaseForm] = useState(false);

    const calculateTotalPrice = () => {
        return cart.reduce((totalPrice, product) => totalPrice + product.price * product.quantity, 0);
    };
    
    const totalPrice = calculateTotalPrice().toLocaleString();

    const handleCheckOut = () => {
        setOpenPurchaseForm(true);
        closeCart(false);
    };

    const cancelCheckOut = () => {
        setOpenPurchaseForm(false);
    };

    return (
        <div className='footer-drawer'>
            <h5 className='text-muted m-0'>Total Price: <b>₱ {totalPrice} </b></h5>
            <button className='btn btn-primary' onClick={handleCheckOut} disabled={cart.length < 1}>Check Out</button>
            <Modal 
                title="Purchase Form"
                visible={openPurchaseForm}
                onCancel={cancelCheckOut}
                width={1300}
                footer={null}
            >
                <MyStorePurchaseForm viewForm={handleCheckOut} onClose={cancelCheckOut} />
            </Modal>
        </div>
    );
};



export { CartContent, CartFooter };
