import React, { useState } from 'react';
import './License.css';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.min.css';
import 'owl.carousel/dist/assets/owl.theme.default.min.css';
import { Image } from 'antd';
import Header2 from '../../Header/Header2';
import Footer from '../../Footer/Footer';

import bida_logo from '../../../../Assets/images/BiDA.png';
import IOS from '../../../../Assets/images/IOS.jpg';
import FDA from '../../../../Assets/License/FDA.jpg';
import HALAL from '../../../../Assets/License/HALAL.jpg';
import BR from '../../../../Assets/License/BR.jpg';
import COR1 from '../../../../Assets/License/COR1.jpg';
import COR2 from '../../../../Assets/License/COR2.jpg';
import COR3 from '../../../../Assets/License/COR3.jpg';

const License = () => {
  const [previewVisible, setPreviewVisible] = useState(false);

  const licenses = [FDA, HALAL, BR, COR1, COR2, COR3];

  const handlePreview = () => {
    setPreviewVisible(!previewVisible); // Toggle preview visibility
  };

  const handleClosePreview = () => {
    setPreviewVisible(false);
  };

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: false,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    dots: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 3,
      },
      1200: {
        items: 4,
      },
    },
  };

  return (
    <>
      <Header2 />
      <section id='license'>
        <div className="container mb-5">
          <div className="row justify-content-center align-items-center">
            <div className='col-12 col-lg-9'>
              <div className="header text-center">
                <h1 className="mb-4">Our Partnerships</h1>
                <p className="lead">Explore the licenses and certifications we hold with our trusted partners.</p>
              </div>
              <OwlCarousel className="owl-theme" {...carouselOptions}>
                <div className="item">
                  <img src={bida_logo} alt="BiDA Logo" />
                </div>
                <div className="item">
                  <img src={IOS} alt="IOS Logo" />
                </div>
              </OwlCarousel>
            </div>
          </div>
        </div>

        <div className="container mb-5">
          <div className="row">
            <div className="col-12">
              <p className="preview-message">
                "Our partnerships with trusted organizations are a testament to our commitment to quality and excellence. These licenses and certifications reflect our dedication to meeting the highest industry standards, ensuring that our products and services adhere to stringent quality control measures. By partnering with renowned entities, we strive to deliver unparalleled value and reliability to our customers, fostering trust and confidence in our brand."
              </p>
              {previewVisible && (
                <div className="image-holder">
                    <Image.PreviewGroup
                      onVisibleChange={handleClosePreview}
                      preview={{
                        onChange: (current, prev) => console.log(`current index: ${current}, prev index: ${prev}`),
                      }}
                    >
                      {licenses.map((license, index) => (
                        <Image key={index} width={150} src={license} />
                      ))}
                    </Image.PreviewGroup>
                
                </div>
               )}
              <div className="text-center mt-3">
                <button className="btn btn-primary" onClick={handlePreview}>
                  {previewVisible ? 'Close Licenses' : 'Preview Licenses'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default License;
