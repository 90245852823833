import React, { useEffect, useState } from 'react';
import './Store.css';
import { BackTop, message } from 'antd';
import Header from '../../Header/Header2';
import Footer from '../../Footer/Footer';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { IoCartOutline } from "react-icons/io5";
import { FaRegEye } from "react-icons/fa";
import { useParams, Link } from 'react-router-dom';
import { useCartStore, useBuyTypeStore } from '../../../../DataTest/cart-store';
import { db, storage } from '../../../../firebase/config';
import { get, child, ref } from 'firebase/database';
import { getDownloadURL, listAll, ref as storageRef } from 'firebase/storage';
import Error404 from '../Error404/Error404';

import image1 from '../../../../Assets/images/products/Package_1.png'
import image2 from '../../../../Assets/images/products/Package_2.png'
import image3 from '../../../../Assets/images/products/Package_3.png'
import image4 from '../../../../Assets/images/products/barley.jpg'

const ViewProd = () => {
    const { productId } = useParams();
    const [products, setProducts] = useState([]);
    const [showScrollButton, setShowScrollButton] = useState(false);
    const [quantity, setQuantity] = useState(1);
    const [totalPrice, setTotalPrice] = useState(0);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [hoveredImage, setHoveredImage] = useState(null);
    const { cart, addToCart, setCart } = useCartStore((state) => ({
        cart: state.cart,
        addToCart: state.addToCart,
        setCart: state.setCart,
    }));
    const handleScrollTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };


    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const snapshot = await get(child(ref(db), 'products'));
                if (snapshot.exists()) {
                    const productsData = snapshot.val();
                    const productsArray = Object.values(productsData);
    
                    const productsWithImages = await Promise.all(productsArray.map(async (product) => {
                        const imagesListRef = storageRef(storage, `products/${product.id}/`);
                        try {
                            const images = await listAll(imagesListRef);
                            const imageUrls = await Promise.all(images.items.map(async (imageRef) => {
                                try {
                                    const imageUrl = await getDownloadURL(imageRef);
                                    return imageUrl;
                                } catch (error) {
                                    console.error('Error fetching image:', error);
                                    return null;
                                }
                            }));
    
                            // Filter out any null values from the image URLs array
                            const filteredImageUrls = imageUrls.filter(url => url !== null);
    
                            return { ...product,image: filteredImageUrls[0] , images: filteredImageUrls };
                        } catch (error) {
                            console.error('Error listing images:', error);
                            return { ...product, images: [] };
                        }
                    }));
                    setProducts(productsWithImages);
                } else {
                    alert('No data available');
                }
            } catch (error) {
                console.error(error);
            }
        };
        fetchProducts();
    }, []);
    

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 300) {
                setShowScrollButton(true);
            } else {
                setShowScrollButton(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        if (products.length > 0) {
            const selected = products.find(product => product.id == productId);
            setSelectedProduct(selected);
            if (selected) {
                const newTotalPrice = selected.price * quantity;
                setTotalPrice(newTotalPrice);
            }
        }
    }, [quantity, productId, products]);

    const handleAddToCart = () => {
        const existingOrderIndex = cart.findIndex((product) => product.id === productId);

        if (existingOrderIndex !== -1) {
            const updatedCart = cart.map((product, index) => {
                if (index === existingOrderIndex) {
                    return { ...product, quantity: product.quantity + quantity };
                } else {
                    return product;
                }
            });
            setCart(updatedCart);
        } else {
            const dataFormat = { ...selectedProduct, quantity: quantity };
            addToCart(dataFormat);
        }
        setQuantity(1);
        message.success(`${selectedProduct.name} is added to your cart.`);
    };

    const handleChangeQuantity = (e) => {
        setQuantity(parseInt(e.target.value));
    };

    const handleDecreaseQuantity = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1);
        }
    };

    const handleIncreaseQuantity = () => {
        setQuantity(quantity + 1);
    };

    if (!selectedProduct) {
        return <Error404 />;
    }

    const relatedProducts = products.filter(
        (product) => product.category === selectedProduct.category && product.id !== selectedProduct.id
    );

    const options = {
        loop: false,
        margin: 10,
        nav: true,
        responsive: {
            0: {
                items: 1,
            },
            600: {
                items: 3,
            },
        },
    };

    return (
        <>
            <Header />
            <section className="section" id='store'>
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-7">
                            <div className="image-content">
                                <div className="left-images">
                                    <img src={hoveredImage || selectedProduct.images[0]} alt="main image" />
                                </div>
                                <div className='other-images'>
                                    { selectedProduct.images.map((image, index) => (
                                       <img
                                            key={index}
                                            src={image}
                                            alt="other image"
                                            onMouseEnter={() => setHoveredImage(image)}
                                            onMouseLeave={() => setHoveredImage(null)}
                                            style={{
                                                opacity: index === 0 ? (hoveredImage && hoveredImage !== image ? '100%' : '60%') : (hoveredImage === image ? '60%' : '100%')
                                            }}
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-5">
                            <div className="right-content">
                                <h4>{selectedProduct.name}</h4>
                                <span className="price">₱ {selectedProduct.price}.00</span>
                                <div className="quote mt-3">
                                    <pre>{selectedProduct.description}</pre>
                                </div>
                                <div className="quantity-content">
                                    <div className="left-content">
                                        <h6 className='text-danger'>Quantity</h6>
                                    </div>
                                    <div className="right-content">
                                        <div className="quantity buttons_added">
                                            <input type="button" value="-" className="minus" onClick={handleDecreaseQuantity} />
                                            <input type="number" step="1" min={quantity} max="100" onChange={handleChangeQuantity} name="quantity" value={quantity} title="Qty" className="input-text qty text" size="4" />
                                            <input type="button" value="+" className="plus" onClick={handleIncreaseQuantity} />
                                        </div>
                                    </div>
                                </div>
                                <div className="total">
                                    <h4 className='text-danger'>Total: ₱ {totalPrice}.00</h4>
                                    <button className="btn btn-success" onClick={handleAddToCart}>Buy Now</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='section' id='related'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="section-heading">
                                <h2>Related products</h2>
                                <span>Details to details is what makes Loyalista Shop different from the other themes.</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <OwlCarousel className="owl-theme" {...options}>
                                {relatedProducts.map((product) => (
                                    <div key={product.id} className="item">
                                        <div className="thumb">
                                            <div className="hover-content">
                                                <ul>
                                                    <li><Link to={`/Products/${product.id}`}><button className='btn btn-light' onClick={handleScrollTop}>
                                                        <FaRegEye style={{ fontSize: '20px' }} />
                                                    </button></Link></li>
                                                    <li><button className='btn btn-light' onClick={() => addToCart(product)}><IoCartOutline style={{ fontSize: '23px' }} /></button></li>
                                                </ul>
                                            </div>
                                            <img src={product.images[0]} alt="" />
                                        </div>
                                        <div className="down-content">
                                            <h4>{product.name}</h4>
                                            <span>₱ {product.price}.00</span>
                                        </div>
                                    </div>
                                ))}
                            </OwlCarousel>
                        </div>
                    </div>
                </div>
            </section>
            {showScrollButton && <BackTop onClick={handleScrollTop} />}
            <Footer />
        </>
    );
};

export default ViewProd;
