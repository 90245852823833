
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage"
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyA9mHZ8alBvydFYfdQg6eDf5oQrOEtvseI",
  authDomain: "loyalista-shop.firebaseapp.com",
  databaseURL: "https://loyalista-shop-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "loyalista-shop",
  storageBucket: "loyalista-shop.appspot.com",
  messagingSenderId: "448511717656",
  appId: "1:448511717656:web:2129fb389da8ffa817ae6d",
  measurementId: "G-HTH00DEWDW"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db = getDatabase(app);
export const storage = getStorage(app);
export const auth = getAuth(app);