import React, { useState, useEffect } from 'react'
import './footer.css';
import loyalista_logo from '../../../Assets/images/loyalista_logo.png'

import { Link, useLocation } from 'react-router-dom';
const Footer = () => {
    const [activeSection, setActiveSection] = useState(false);
    const location = useLocation();

    useEffect(() => {
        const handleScroll = () => {
            const scrollY = window.scrollY;
          
            // Update active section based on scroll position
            const sections = ['home', 'about', 'product', 'contactus', 'login', 'privacy', 'terms', 'license'];
            for (const section of sections) {
                const element = document.getElementById(section);
                if (element && element.offsetTop - 50 <= scrollY && element.offsetTop + element.offsetHeight - 50 >= scrollY) {
                    setActiveSection(section);
                    break;
                }
            }
        };
  
        window.addEventListener('scroll', handleScroll);

        // Cleanup the event listener when the component is unmounted
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
       
    }, []);

    useEffect(() => {
        const path = location.pathname;
        if (path === '/') {
            setActiveSection('home');
        } else if (path === '/About-us') {
            setActiveSection('about');
        } else if (path.startsWith('/Products')) {
            setActiveSection('product');
        } else if (path === '/Contact-Us') {
            setActiveSection('contactus');
        } else if (path === '/Login') {
            setActiveSection('login');
        } else if (path === '/Privacy Policy') {
            setActiveSection('privacy');
        } else if (path === '/Terms and Conditions') {
            setActiveSection('terms');
        } else if (path === '/License') {
            setActiveSection('license');
        } else {
            setActiveSection('');
        }
    }, [location.pathname]);


    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
            setActiveSection(sectionId);
        }
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

  return (
    <footer>
        <div className="container">
            <div className="row">
                {/* <div className="col-lg-4">
                    <div className="logo">
                        <img src={loyalista_logo} alt="LOGO"/>
                    </div>
                    <div className="first-item">
                        <ul>
                            <li><a href="https://www.google.com/maps/search/No.+39,+Commercial+Unit+2nd+Floor+Left+Wing,+39+Sct.+Ybardolaza,+Quezon+City,+Metro+Manila/@14.6311132,121.0242675,14z/data=!3m1!4b1?entry=ttu" target='_blank'>
                                No. 39, Commercial Unit 2nd Floor Left Wing, 39 Sct. Ybardolaza, Quezon City, Metro Manila
                                </a>
                            </li>
                            <li><a href="https://mail.google.com/mail/u/0/#inbox?compose=CllgCJNvvhsPWGwMbzSfcCgxxqkfrDrFGmMRzxLbkPXkMHqgTqNhVtpfXjKTKxSPccbpQFpMSQV" target='_blank'>
                                loyalistaonlineshopcorp@gmail.com
                                </a>
                            </li>
                            <li><a href="#">(02)-8534-7369</a></li>
                        </ul>
                    </div>
                </div> */}
                
                {/* <div className="col-lg-4">
                    <h4>Useful Links</h4>
                    <ul>
                        <li onClick={() => scrollToSection('home')}>
                            <Link to="/" className={`scroll-to-section ${activeSection === 'home' ? 'active' : ''}`}>Home</Link>
                        </li>
                        <li onClick={() => scrollToSection('about')}>
                            <Link to="/About-us" className={`scroll-to-section ${activeSection === 'about' ? 'active' : ''}`}>About Us</Link>
                        </li>
                        <li onClick={() => scrollToSection('product')}>
                            <Link to="/Products" className={`scroll-to-section ${activeSection === 'product' ? 'active' : ''}`}>Products</Link>
                        </li>
                        <li onClick={() => scrollToSection('partner')}>
                            <Link to="/License" className={`scroll-to-section ${activeSection === 'partner' ? 'active' : ''}`}>Our Partners</Link>
                        </li>
                        <li onClick={() => scrollToSection('contactus')}>
                            <Link to="/Contact-Us" className={`scroll-to-section ${activeSection === 'contactus' ? 'active' : ''}`}>Contact Us</Link>
                        </li>
                    </ul>
                </div> */}

                {/* <div className="col-lg-4">
                    <h4>Social Media Accounts</h4>
                    <ul>
                        <li><a href="#">Facebook</a></li>
                        <li><a href="#">Instagram</a></li>
                        <li><a href="#">Twitter</a></li>
                        <li><a href="#">Linkedin</a></li>
                    </ul>
                </div> */}
                {/* <div className="col-lg-3">
                    <h4>Help &amp; Information</h4>
                    <ul>
                        <li><a href="#">Help</a></li>
                        <li><a href="#">FAQ's</a></li>
                        <li><a href="#">Shipping</a></li>
                        <li><a href="#">Tracking ID</a></li>
                    </ul>
                </div> */}
                <div className="col-lg-12">
                    <div className="under-footer">
                        <p>Copyright © 2024 All Rights Reserved by Loyalista Online Shop Corp.</p>
                        <ul>
                            <li onClick={() => scrollToSection('privacy')}>
                                <Link to="/Privacy Policy">Privacy Policy</Link>
                            </li >
                            <div id='text'>|</div>
                            <li onClick={() => scrollToSection('terms')}>
                                <Link to="/Terms and Conditions">Terms and Conditions</Link>
                            </li>
                            {/* <div id='text'>|</div>
                            <li onClick={() => scrollToSection('license')}>
                                <a href="/License">License</a>
                            </li> */}
                        </ul>
                    </div>
                </div>

            </div>
        </div>
    </footer>
  )
}

export default Footer;