import React, { useEffect, useState } from 'react';
import './ProductLink.css';
import { MdOutlineShare } from "react-icons/md";
import { Link } from 'react-router-dom';
import { Pagination, message } from 'antd';
import SampleProduct from '../../../DataTest/SampleProducts' 
import { useAuth } from '../../../contexts/authContext';
import {  Navigate } from 'react-router-dom';
import { auth, db,storage } from '../../../firebase/config';
import { get, child, ref, set,serverTimestamp } from 'firebase/database';
import { getDownloadURL, ref as storageRef ,listAll} from 'firebase/storage';

const ProductLink = () => {
  const {userLoggedIn} = useAuth() 
  const [products, setProducts] = useState([]);
  // Pagination state
  const user =  JSON.parse(localStorage.getItem('user'));
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(4); // Number of items per page
  // Calculate indexes for current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = products.slice(indexOfFirstItem, indexOfLastItem);
  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  
  const handleCopyLink = (productId) => {
    const prodLink = window.location.origin + `/${user.uid}/${productId}`;
    navigator.clipboard.writeText(prodLink)
      .then(() => message.success('Link copied to clipboard'))
      .catch((error) => message.error('Failed to copy link'));
  }
  
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const snapshot = await get(child(ref(db), 'products'));
        if (snapshot.exists()) {
          const productsData = snapshot.val();
          const productsArray = Object.values(productsData);
  
          const productsWithImages = await Promise.all(
            productsArray.map(async (product) => {
              try {
                // List all items (files) in the product's directory
                const listRef = storageRef(storage, `products/${product.id}`);
                const imageList = await listAll(listRef);
                
                // Get the URLs for all images
                const imageUrls = await Promise.all(
                  imageList.items.map(async (itemRef) => {
                    const imageUrl = await getDownloadURL(itemRef);
                    return imageUrl;
                  })
                );
  
                // Return the product with all images
                return { ...product, images: imageUrls };
  
              } catch (error) {
                console.error('Error fetching images:', error);
                return { ...product, images: [] }; // Return product with an empty images array if an error occurs
              }
            })
          );
  
          setProducts(productsWithImages);
          console.log(productsWithImages)
        } else {
          alert('No data available');
        }
      } catch (error) {
        console.error(error);
      }
    };
  
    fetchProducts();
  }, []);
  



  return (
    <main id='productLink'>
                  {!userLoggedIn && (<Navigate to={'/login'} replace={true} />)}
        <div class="head-title">
            <div class="left">
                <h1>Product Link</h1>
            </div>
        </div>
        <div className="product-lists">
            <div className="Product-lists">
              <div className="head">
                <h4>Share our products</h4>
              </div>
              <div className="items">
                <div className="row">
                  {currentItems.map((product) => (
                      <div className="col-lg-3" key={product.id}>
                          <div className="item">
                              <div className="thumb">
                                  <div className="hover-content">
                                      <ul>
                                          <li>
                                            <button className='btn btin-link' onClick={() => handleCopyLink(product.id,)}>
                                              <MdOutlineShare  style={{fontSize: '20px'}}/>
                                             
                                            </button>
                                          </li>
                                      </ul>
                                  </div>
                                  <img src={product.images[0]} alt={product.name} />
                              </div>
                              <div className="down-content">
                                  <h4>{product.name}</h4>
                                  <span>₱ {product.price}.00</span>
                              </div>
                          </div>
                      </div>
                  ))}
                  <div className="col-lg-12" style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                      <Pagination
                          current={currentPage}
                          total={products.length}
                          pageSize={itemsPerPage}
                          onChange={paginate}
                          center
                      />
                  </div>
                </div>
              </div>
            </div>
        </div>
    </main>
  )
}

export default ProductLink;