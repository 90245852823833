import React, { useState, useEffect } from 'react';
import './UserManagement.css';
import { IoSearch, IoPersonAdd } from "react-icons/io5";
import { BsPersonFillAdd } from "react-icons/bs";
import { MdDeleteForever } from "react-icons/md";
import { FaUserCheck, FaUserXmark } from "react-icons/fa6";
import { FaUserEdit } from "react-icons/fa";
import { GrFormNextLink, GrFormPreviousLink } from "react-icons/gr";
import { Rate, Pagination, Tooltip, Modal, Steps, Popconfirm, message, Image, Popover, Select } from 'antd'; 
import { regions, provinces, cities, barangays } from 'select-philippines-address';
import SampleProduct from '../../../DataTest/SampleProducts';
import NoDataFount from '../../../Assets/no-data/NoDataFount';
import DefaultProfile from '../../../Assets/images/instagram-03.jpg';
import { get, ref, update, set, query, orderByChild, equalTo, remove, serverTimestamp } from 'firebase/database';
import { deleteObject, getDownloadURL, listAll, ref as storageRef } from 'firebase/storage';
import { auth, db, storage } from '../../../firebase/config';
import {  ref as sRef } from 'firebase/storage';
import moment from 'moment'
import { createUserWithEmailAndPassword } from 'firebase/auth';
const { Step } = Steps;
const { Option } = Select;

const UserManagement = () => {
  const [selectedMember, setSelectedMember] = useState(null);
  const [openAddMemberForm, setOpenAddMemberForm] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [isIOSMember, setIsIOSMember] = useState(true);
  const [confirmEdit, setConfirmEdit] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [approvedUser, setApprovedUser] =useState(false);
  const [disapprovedUser, setDisapproveUser] =useState(false);
  const [isUpgrade, setIsUpgrade] = useState(false);
  const [username, setUserName] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPass, setConfirmPass] = useState('');
  const [inputRegion, setInputRegion] = useState('');
  const [inputProvince, setInputProvince] = useState('');
  const [inputCity, setInputCity] = useState('');
  const [inputBrgy, setInputBrgy] = useState('');
  const [regionData, setRegionData] = useState([]);
  const [provinceData, setProvinceData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [barangayData, setBarangayData] = useState([]);
  const [daysUntilExpiration, setDaysUntilExpiration] = useState(0);
  const [currentStep, setCurrentStep] = useState(0);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [fileName, setFileName] = useState(''); 
  const [membersData,setMemberData]= useState([])
  const [currentPage, setCurrentPage] = useState(1); // State for current page
  const pageSize = 10; // Number of items per page
  // search functionality
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredMembers, setFilteredMembers] = useState([]);
  const [newFormData, setnewFormData] = useState({});
  const [addForm, setAddForm] = useState({
    status:'Approved',
    rating:10,
  })
  useEffect(() => {
    if (selectedMember && selectedMember.subscription_Expiration) {
      const expirationDate = new Date(selectedMember.subscription_Expiration);
      const currentDate = new Date();
      const daysDifference = Math.ceil((expirationDate - currentDate) / (1000 * 60 * 60 * 24));
      if(daysDifference > 1) {
        setDaysUntilExpiration(daysDifference);
      }else{
        setDaysUntilExpiration(0);
      }
    } else {
      setDaysUntilExpiration(0);
    }
  }, [selectedMember]);

  // Filter the members whenever the search query changes
  useEffect(() => {
    console.log(membersData)
    const filtered = membersData.filter(
      (member) =>
        member.accountID?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        member.firstname?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        member.lastname?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        member.email?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        member.status?.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredMembers(filtered);
    setCurrentPage(1); // Reset current page to 1 when search query changes
  }, [searchQuery, membersData]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // Calculate the current page's items
  const startIndex = (currentPage - 1) * pageSize;
  const currentPageMembers = filteredMembers.slice(startIndex, startIndex + pageSize);

  // useEffect(() => {
  //   const getUserInfo = async () => {
  //     const userInfoRef = ref(db, `users`);
  //     const snapshot = await get(userInfoRef);
  //     if (snapshot.exists()) {
  //       const userData = snapshot.val();
  //       const userArray = Object.values(userData);
  //       const chartDataFromFirebase = [];

  //       const findReferrals = (username, level) => {
  //         userArray.forEach((creds) => {
  //           if (creds.referral === username) {
  //             chartDataFromFirebase.push({
  //               ...creds,
  //               level: `Level-`+level,
  //             });
  //             findReferrals(creds.username, level + 1);
  //           }
  //         });
  //       };

  //       userArray.forEach((creds) => {
  //         if (creds.referral === '') {
  //           chartDataFromFirebase.push({
  //             ...creds,
  //             level:`Level-` + 1,
  //           });
  //           findReferrals(creds.username, 2);
  //         }
  //       });
        
  //       const userWithImages = await Promise.all(
  //         Object.entries(chartDataFromFirebase).map(async ([userID, user]) => {
  //           const profileImageRef = storageRef(storage, `users/${user.userID}/profile`);
  //           try {
  //             const profileListResult = await listAll(profileImageRef);
  //             const latestProfileImage = profileListResult.items[profileListResult.items.length - 1];
  //             const profileImageUrl = await getDownloadURL(latestProfileImage);
  //             const idImageUrl = await getIdURL(user.userID); // Call the function to get the ID image URL
  //             return { ...user, image: profileImageUrl, userID: user.userID, idImage: idImageUrl };
  //           } catch (error) {
  //             const idImageUrl = await getIdURL(user.userID); // Call the function to get the ID image URL
  //             return { ...user, image: DefaultProfile, userID: user.userID , idImage: idImageUrl };
  //           }
  //         })
  //       );
  //       console.log(userWithImages);
  //       setMemberData(userWithImages);
  //       setFilteredMembers(userWithImages);
  //     } else {
  //       console.log('No data available');
  //     }
  //   };
  
  //   getUserInfo();
  // }, [confirmLoading, isUpgrade]);
  
  
  
  useEffect(() => {
    const getUserInfo = async () => {
      const userInfoRef = ref(db, `users`);
      const snapshot = await get(userInfoRef);
      if (snapshot.exists()) {
        const userData = snapshot.val();
        const userArray = Object.values(userData);
        const chartDataFromFirebase = [];
  
        const findReferrals = (username,refEmail, level) => {
          userArray.forEach((creds) => {
            if (creds.referral === username) {
              chartDataFromFirebase.push({
                ...creds,
                level:  level,
                Referralemail: refEmail
              });
              findReferrals(creds.username,creds.email, level + 1);
            }
          });
        };
  
        userArray.forEach((creds) => {
          if (creds.referral === '') {
            chartDataFromFirebase.push({
              ...creds,
              level:  1,

            });
            findReferrals(creds.username,creds.email, 2);
          }
        });
  
        const userWithImages = await Promise.all(
          Object.entries(chartDataFromFirebase).map(async ([userID, user]) => {
            const profileImageRef = storageRef(storage, `users/${user.userID}/profile`);
            try {
              const profileListResult = await listAll(profileImageRef);
              const latestProfileImage = profileListResult.items[profileListResult.items.length - 1];
              const profileImageUrl = await getDownloadURL(latestProfileImage);
              const idImageUrl = await getIdURL(user.userID); // Call the function to get the ID image URL
              return { ...user, image: profileImageUrl, userID: user.userID, idImage: idImageUrl };
            } catch (error) {
              const idImageUrl = await getIdURL(user.userID); // Call the function to get the ID image URL
              return { ...user, image: DefaultProfile, userID: user.userID, idImage: idImageUrl };
            }
          })
        );
  
        // Separate "Status Pending" entries from the rest
        const pendingEntries = userWithImages.filter((user) => user.status === 'Pending');
        const otherEntries = userWithImages.filter((user) => user.status !== 'Pending');
  
        // Sort the non-"Status Pending" entries based on your desired criteria
        const sortedOtherEntries = otherEntries.sort((a, b) => {
          // Sort logic here (e.g., alphabetical order of username)
          return a.username.localeCompare(b.username);
        });
  
        // Concatenate the "Status Pending" entries with the sorted non-"Status Pending" entries
        const sortedUserWithImages = [...pendingEntries, ...sortedOtherEntries];
  
        setMemberData(sortedUserWithImages);
        setFilteredMembers(sortedUserWithImages);
      } else {
        alert('No data available');
      }
    };
  
    getUserInfo();
  }, [confirmLoading, isUpgrade]); //ascended by Status Pending
  
  // Function to handle selecting a member
  const handleMemberSelect = (member) => {
    setnewFormData(member);
    setSelectedMember(member);
  };

  const getIdURL = async (userID) => {
    const idImageRef = storageRef(storage, `users/${userID}/id`);
    try {
      const idListResult = await listAll(idImageRef);
      const latestIdImage = idListResult.items[idListResult.items.length - 1];
      return await getDownloadURL(latestIdImage);
    } catch (error) {
      return NoDataFount;
    }
  };

  const [renewalOption, setRenewalOption] = useState(null);
  const [popoverVisible, setPopoverVisible] = useState(false);

  const handleRenewal = (value) => {
    setRenewalOption(value);
  };

  const handleOk = async(e) => {
    e.preventDefault();
    setConfirmLoading(true);
    if (renewalOption) {
    const nextExpirationDate = calculateNextExpirationDate(renewalOption)
    const updates = {};
    updates['/users/' + selectedMember.userID + '/subscription_Date'] = serverTimestamp();
    updates['/users/' + selectedMember.userID  + '/subscription_Expiration'] = nextExpirationDate;
        setTimeout(() => {
            message.success(`Subscription renewal successful for ${renewalOption} months`);
            setConfirmLoading(false);
            setSelectedMember(null)
            setPopoverVisible(false)
        }, 2000);   
        await update(ref(db), updates).then(async()=>{
          const subject = `Your account has been created successfully`
          const userData = {
            ...selectedMember,
            subscription_Expiration: nextExpirationDate
          }
          
          const email = selectedMember.email
          const response = await fetch('https://loyalista-mailer-aa5b8a82053e.herokuapp.com/subcription', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email, userData , subject }),
          });
          if (response.ok) {      
            console.log('Email sent successfully');

          } else {
            console.error('Failed to send email');
          }
        })

   } else {
    setTimeout(() => {
      message.error('Please select a renewal option');
    }, 2000);
    }

  };

  //Popconfirm
  const OpenEdit = () => {
    setConfirmEdit(true);
  }
  const OpenDelete = () => {
    setConfirmDelete(true);
  }
  const CancelPopconfirm = () => {
    setConfirmDelete(false);
    setApprovedUser(false);
    setDisapproveUser(false);
    setIsUpgrade(false);
  }
  const ApproveUser = () => {
    setApprovedUser(true);
  }
  const DisapproveUser = () => {
    setDisapproveUser(true);
  }

  //Approve User
  const handleApprove = async(selectedMember) => {
    setConfirmLoading(true);
    const nextExpirationDate = calculateNextExpirationDate(1)
    const updates = {};
    updates['/users/' + selectedMember.userID +'/status'] = 'Approved';
    updates['/users/' + selectedMember.userID +'/rating'] = 10;
    updates['/users/' + selectedMember.userID + '/subscription_Date'] = serverTimestamp();
    updates['/users/' + selectedMember.userID + '/subscription_Expiration'] = nextExpirationDate;
    handleGenerateID(selectedMember)

    setTimeout(() => {
      setConfirmLoading(false);
      setApprovedUser(false);
    }, 2000);
    try {
      await update(ref(db), updates).then(async()=>{
        const subject = `Your account has been approved successfully `
            const name = `${selectedMember.firstname} ${selectedMember.lastname}`
            const email = selectedMember.email
            const creds = selectedMember
            const response = await fetch('https://loyalista-mailer-aa5b8a82053e.herokuapp.com/approvedUser', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ email, name , subject }),
            });
            if (response.ok) {
                const subject = `Your new Member has been approved successfully `
                const email = selectedMember.Referralemail
                const response = await fetch('https://loyalista-mailer-aa5b8a82053e.herokuapp.com/bossEmailApproved', {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({ email, creds , subject }),
                });

                if (response.ok) {      
                  console.log('Email sent successfully');

                } else {
                  console.error('Failed to send email');
                }    

              if(creds.Referralemail) {
                const subject = `Your new Member has been approved successfully `
                const email = selectedMember.Referralemail
                const response = await fetch('https://loyalista-mailer-aa5b8a82053e.herokuapp.com/referralApproved', {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({ email, creds , subject }),
                });

                if (response.ok) {      
                  console.log('Email sent successfully');

                } else {
                  console.error('Failed to send email');
                }
              }
              
            } else {
              console.error('Failed to send email');
            }
      })
      message.success(`${selectedMember.firstname + ' ' + selectedMember.lastname} has been approved!`);
    } catch (error) {
      console.error('Update failed: ', error);
      message.error('Update failed. Please try again.');
    } finally {
      setConfirmLoading(false);
      setApprovedUser(false);
    }

  };
  const calculateNextExpirationDate = (value) => {
    const currentDate = moment();
    const nextExpirationDate = currentDate.clone().add(value, 'months').format('MMMM DD, YYYY');
    return nextExpirationDate;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setnewFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  
  const handleDisapprove = async(selectedMember) => {
    setConfirmLoading(true);
    try {
      const userID = selectedMember.userID;
      if (window.confirm(`Are you sure you want to Decline and Block ${selectedMember.email}?`)) {
        const updates = {};
        updates['/users/' + userID +'/status'] = 'Blocked';
        await update(ref(db), updates);
      }
    } catch (error) {
      console.error('Error deleting user and folder:', error.message);
    }
    setTimeout(() => {
      message.error(`${selectedMember.firstname + ' ' + selectedMember.lastname} has been rejected!`);

      // Set loading state back to false after action is completed
      setConfirmLoading(false);
      setDisapproveUser(false);
    }, 2000);
  }
  const handleDeleteUser = async(selectedMember) => {
    setConfirmLoading(true);
    try {
      const userID = selectedMember.userID;
      if (window.confirm(`Are you sure you want to Block ${selectedMember.email}?`)) {
              const updates = {};
              updates['/users/' + userID +'/status'] = 'Blocked';
              await update(ref(db), updates);
      }
    } catch (error) {
      console.error('Error deleting user and folder:', error.message);
    }
    setTimeout(() => {
      message.warning(`${selectedMember.firstname + ' ' + selectedMember.lastname} has been blocked!`);
      setConfirmLoading(false);
      setConfirmDelete(false);
    }, 2000);
  }

  const openConfirmUpgrade =() => {
    setIsUpgrade(true);

  }
  const handleGenerateID = async(selectedMember) => {
    setConfirmLoading(true);
    const nonEmptyAccountIDs = membersData.filter(member => member.accountID !== '' && member.membership !== '' && member.membership !== 'IOS Member').length;
    const newAccountID = `LOS-${String(nonEmptyAccountIDs + 1).padStart(5, '0')}`;
    const updates = {
      [`/users/${selectedMember.userID}/membership`]: 'LOS Member',
      [`/users/${selectedMember.userID}/accountID`]: newAccountID,
    };
    setTimeout(() => {
      
      setConfirmLoading(false);
      setIsUpgrade(false);
      selectedMember.membership='LOS Member'
      selectedMember.accountID=newAccountID
    }, 2000);
    await update(ref(db), updates);
  }
  //function to show the modal form adding member form
  const handleCancel = () => {
    setOpenAddMemberForm(false);
    setConfirmEdit(false);
    setIsUpgrade(false)
  }
  const showAddingForm = () => {
    setOpenAddMemberForm(true);
    setsignupForm({
      firstname: '',
      middlename: '',
      lastname: '',
      contact: '',
      username: '',
      email: '',
      membership: 'IOS Member',
      accountID: '',
      region: '',
      province: '',
      city: '',
      brgy: '',
      street: '',
      postal: '',
      landmark: '',
      subscription_Expiration:'',
      subscription_Date:''
    });
    setCurrentStep(0)
  }

  const [signupForm, setsignupForm] = useState({
    firstname: '',
    middlename: '',
    lastname: '',
    contact: '',
    username: '',
    email: '',
    membership: 'IOS Member',
    accountID: '',
    region: '',
    province: '',
    city: '',
    brgy: '',
    street: '',
    postal: '',
    landmark: '',
    subscription_Expiration:'',
    subscription_Date:''
  });

  const checkUserName = async (username) => {
    try {
      const snapshot = await get(query(ref(db, 'users'), orderByChild('username'), equalTo(username)));
      
      if (snapshot.exists()) {
        return true;
      } else {
        return false; 
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const checkEmail = async (email) => {
    try {
      const snapshot = await get(query(ref(db, 'users'), orderByChild('email'), equalTo(email)));
      
      if (snapshot.exists()) {
        return true;
      } else {
        return false; 
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const handleNext = async() => {
    signupForm.username = username
    signupForm.email = newEmail
    const usernameExist = await checkUserName(username);
    const EmailExist = await checkEmail(newEmail);

    if (currentStep === 0 ) {
      if (!signupForm.firstname || !signupForm.lastname || !signupForm.contact) {
        setErrorMessage('Please fill out all required fields');
        setShowError(true);
        return;
      }
      if (signupForm.contact.length !== 11) {
        setErrorMessage('Phone number must have 11 digits');
        setShowError(true);
        return;
      }
    }

    if (currentStep === 1 ) {
      if (!username || !newEmail || !password || !confirmPass) {
        setErrorMessage('Please fill out all required fields');
        setShowError(true);
        return;
      }
      if (password !== confirmPass) {
        setErrorMessage('Password and confirm password not matched!');
        setShowError(true);
        return;
      }
      if (usernameExist) {
        setErrorMessage("Username is already used. Please choose another one!");
        setShowError(true);
        return;
      }
      if (EmailExist) {
        setErrorMessage(`email address ${newEmail} already in use.`);
        setShowError(true);
        return;
      }
    }
    if (currentStep === 2 ) {
      if (isIOSMember && !signupForm.accountID) {
        setErrorMessage('Please fill out all required fields');
        setShowError(true);
        return;
      }
    }
    if (currentStep === 3 ) {
      if (!inputRegion || !inputProvince || !inputCity || !inputBrgy) {
        setErrorMessage('Please fill out all required fields');
        setShowError(true);
        return;
      }
    }
    setErrorMessage('');
    setCurrentStep(currentStep + 1);
  };
  const handlePrev = () => {
    setCurrentStep(currentStep - 1);
  };
  useEffect(() => {
    fetchRegions();
  }, []);
  const fetchRegions = () => {
    regions()
      .then(response => {
        setRegionData(response);
        setsignupForm(prevData => ({ ...prevData, region: response[null]?.region_code || '' }));
      })
      .catch(error => console.error('Error fetching regions:', error));
  };
  const fetchProvinces = region_code => {
    provinces(region_code).then(response => {
      setProvinceData(response);
    });
  };
  const fetchCities = provinceCode => {
    cities(provinceCode).then(response => {
      setCityData(response);
    });
  };
  const fetchBarangays = cityCode => {
    barangays(cityCode).then(response => {
      setBarangayData(response);
    });
  };
  const onChangeRegion = (e) => {
    const selectedRegionCode = e.target.value;
    const selectedRegion = regionData.find((item) => item.region_code === selectedRegionCode);

    setInputRegion(selectedRegionCode);
    setInputProvince('');
    setInputCity('');
    setInputBrgy('');
    if(confirmEdit){
      setnewFormData({
        ...newFormData,
        region: selectedRegion ? selectedRegion.region_name : '',
        province: '',
        city:'',
        brgy:''
      });
    }else{
    setsignupForm({
      ...signupForm,
      region: selectedRegion ? selectedRegion.region_name : '',
    });
  }
    fetchProvinces(selectedRegionCode);
    setProvinceData([]);
    setCityData([]);
    setBarangayData([]);
  };
  const onChangeProvince = (e) => {
    const selectedProvinceCode = e.target.value;
    const selectedProvince = provinceData.find((item) => item.province_code === selectedProvinceCode);

    setInputProvince(selectedProvinceCode);
    setInputCity('');
    setInputBrgy('');
    if(confirmEdit){
      setnewFormData({
        ...newFormData,
        province: selectedProvince.province_name,
        city:'',
        brgy:''
      });
    }else{
      setsignupForm({
        ...signupForm,
        province: selectedProvince ? selectedProvince.province_name : '',
      });
    }

    fetchCities(selectedProvinceCode);
    setCityData([]); 
    setBarangayData([]);
  };
  const onChangeCity = (e) => {
    const selectedCityCode = e.target.value;
    const selectedCity = cityData.find((item) => item.city_code === selectedCityCode);

    setInputCity(selectedCityCode);
    setInputBrgy('');
    if(confirmEdit){
      setnewFormData({
        ...newFormData,
        city: selectedCity ? selectedCity.city_name : '',
        brgy:''
      });
    }else{
    setsignupForm({
      ...signupForm,
      city: selectedCity ? selectedCity.city_name : '',
    });
  }
    fetchBarangays(selectedCityCode);
    setBarangayData([]); 
  };
  const onChangeBarangay = (e) => {
    const selectedBarangayCode = e.target.value;
    const selectedBarangay = barangayData.find((item) => item.brgy_code === selectedBarangayCode);

    setInputBrgy(selectedBarangayCode);
    if(confirmEdit){
      setnewFormData({
        ...newFormData,
        brgy: selectedBarangay ? selectedBarangay.brgy_name : '',
      });
    }else{
    setsignupForm({
      ...signupForm,
      brgy: selectedBarangay ? selectedBarangay.brgy_name : '',
    });
  }
  };
  const handleSubmitEditForm = async(e) => {
    e.preventDefault();
    try {
      const updates = {};
      updates['/users/' + selectedMember.userID  + '/email'] = newFormData.email;
      updates['/users/' + selectedMember.userID  + '/firstname'] = newFormData.firstname;
      updates['/users/' + selectedMember.userID  + '/middlename'] = newFormData.middlename;
      updates['/users/' + selectedMember.userID  + '/lastname'] = newFormData.lastname;
      updates['/users/' + selectedMember.userID + '/contact'] = newFormData.contact;
      updates['/users/' + selectedMember.userID  + '/region'] = newFormData.region;
      updates['/users/' + selectedMember.userID  + '/province'] = newFormData.province;
      updates['/users/' + selectedMember.userID  + '/city'] = newFormData.city;
      updates['/users/' + selectedMember.userID  + '/brgy'] = newFormData.brgy;
      updates['/users/' + selectedMember.userID  + '/street'] = newFormData.street;
      updates['/users/' + selectedMember.userID  + '/landmark'] = newFormData.landmark;
      updates['/users/' + selectedMember.userID  + '/postal'] = newFormData.postal;
      if(isUpgrade) {
        updates['/users/' + selectedMember.userID  + '/membership'] = 'IOS Member';
        updates['/users/' + selectedMember.userID  + '/accountID'] = newFormData.accountID;
        message.success(`${selectedMember.firstname + ' ' + selectedMember.lastname} has been upgraded to IOS Member!`);
      }

      await new Promise(resolve => setTimeout(resolve, 1500));

      await update(ref(db), updates);

    } catch (error) {
        alert(error);
    }
    setConfirmLoading(true);

    setTimeout(() => {
      setIsUpgrade(false)
      setConfirmEdit(false);
      setConfirmLoading(false);
      message.success('Changes has been saved!');
    }, 2000);
  }
  const handleSumbitAddingForm = async(e) => {
    e.preventDefault()
    setConfirmLoading(true);
    const usernameExist = await checkUserName(signupForm.username);
    if (!usernameExist) {
      try {
        createUserWithEmailAndPassword(auth, signupForm.email, password).then(userCredential => {   
          const nextExpirationDate = calculateNextExpirationDate(1)
          const newuser = userCredential.user;
          signupForm.userID = newuser.uid
          signupForm.referral = ''
          signupForm.status = 'Approved'
          signupForm.rating = 10
          signupForm.subscription_Date = serverTimestamp();
          signupForm.subscription_Expiration = nextExpirationDate;
          const nonEmptyAccountIDs = membersData.filter(member => member.accountID !== '' && member.membership !== '' && member.membership !== 'IOS Member').length;
          const newAccountID = `LOS-${String(nonEmptyAccountIDs + 1).padStart(5, '0')}`;
          signupForm.accountID = newAccountID;
          set(ref(db, `users/${newuser.uid}`),signupForm).then(async()=>{
            const subject = `Your account has been created successfully `
            const name = `${selectedMember.firstname} ${selectedMember.lastname}`
            const email = selectedMember.email
            const creds = signupForm;
            const response = await fetch('https://loyalista-mailer-aa5b8a82053e.herokuapp.com/approvedUser', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ email, name , subject }),
            });
            if (response.ok) {      
              const subject = `New Member has been registered.`
              const email = 'vincesahagun@yahoo.com'
              const bossresponse = await fetch('https://loyalista-mailer-aa5b8a82053e.herokuapp.com/newMember', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, creds , subject }),
              });
              if (bossresponse.ok) {
                console.log('Email sent successfully');
              } else {
                console.error('Failed to send email');
              }

            } else {
              console.error('Failed to send email');
            }
          })
          setTimeout(() => {
            setOpenAddMemberForm(false);
            setConfirmLoading(false);
            message.success('New member is successfully added!');
          }, 2000);
      }).catch(error => {
        switch (error.code) {
           case 'auth/email-already-in-use':
            alert(`email address ${signupForm.email} already in use.`);
             break;
           case 'auth/invalid-email':
            alert(`email address ${signupForm.email} is invalid.`);
             break;
           case 'auth/operation-not-allowed':
            alert(`error during sign up.`);
             break;
           case 'auth/weak-password':
            alert('password is not strong enough. add additional characters including special characters and numbers.');
             break;
           default:
            alert(error.message);
             break;
         }
      })
    }catch (error) {
        alert('Error occurred during registration');
    }

  }
}

  return (
    <main id='usermanage'>
      <div className="head-title">
        <div className="left">
            <h1>User Management</h1>
        </div>
      </div>
      {selectedMember && (
      <div className="table-data">
        <div className="user-info">
          
            <div className="left">
              <div className="img-holder">
                <Image
                    width={250}
                    src={selectedMember.image}
                    
                />
              </div>
            </div>
            <div className="right">
              <div className="info">
                <h3>{selectedMember.firstname} {selectedMember.middlename} {selectedMember.lastname}</h3>
                <Rate disabled allowHalf value={selectedMember.rating/2}/> <span className='ml-2'>({selectedMember.rating/2 || 0}/5)</span>
                <div className="other-info">
                  <p><b>Member ID: </b> {selectedMember.accountID || 'N/A'}</p>
                  <p><b>Email: </b> {selectedMember.email}</p>
                  <p><b>Contact No.: </b> {selectedMember.contact}</p>
                  {selectedMember.region &&(
                  <p><b>Address: </b> {selectedMember.street}, {selectedMember.municipal}, {selectedMember.province} {selectedMember.region}</p>
                  )}
                  <p><b>Level: </b> {selectedMember.level}</p>
                  <p><b>Subscription: </b>
                      {daysUntilExpiration > 1 ? (
                        ` ${daysUntilExpiration} days left`
                      ) : (
                        <>
                          <span className="text-danger"><b> Expired</b></span>
                          <Tooltip placement="bottom" title='Renew subscription?'>
                            <Popover
                              content={
                                <>
                                  <Select defaultValue="select" style={{ width: 120 }} onChange={handleRenewal}>
                                    <Option value="select" disabled>Select here...</Option>
                                    <Option value="1">1 month</Option>
                                    <Option value="2">2 months</Option>
                                    <Option value="4">4 months</Option>
                                    <Option value="8">8 months</Option>
                                    <Option value="12">1 year</Option>
                                  </Select>
                                  <button className='btn btn-success ml-2' style={{fontSize: '12px'}}
                                          onClick={handleOk} 
                                          disabled={confirmLoading || !renewalOption || renewalOption === 'select'}>
                                    {confirmLoading && (
                                      <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                                    )}
                                      {confirmLoading ? '' : 'OK'}
                                  </button>
                                </>
                              }
                              title="Select Renewal Period"
                              trigger="click"
                              open={popoverVisible}
                              onOpenChange={(visible) => setPopoverVisible(visible)}
                            >
                              <button className="btn btn-link p-0 ml-2" style={{ textDecoration: 'underline', fontSize: '16px' }}> (Renew)</button>
                            </Popover>
                          </Tooltip>
                        </>
                      )}
                    </p>
                </div>
              </div>
              {/* {selectedMember.status === 'Pending' && (
                <div className="uploadedID">
                  <Image
                    width={250}
                    src={selectedMember.idImage}
                  />
                </div>
              )} */}
              {selectedMember.status === "Pending" ? (
                <div className="buttons">
                  <Tooltip placement="topRight" title={'Approve this user?'}>
                    <Popconfirm
                          title="Are you want to approve this user?"
                          onConfirm={() => handleApprove(selectedMember)}
                          okButtonProps={{ loading: confirmLoading }}
                          open={approvedUser}
                          onCancel={CancelPopconfirm}>
                      <button className='btn approve-btn' onClick={ApproveUser}><FaUserCheck /></button>
                    </Popconfirm>
                    
                  </Tooltip>

                  <Tooltip placement="topRight" title={'Cancel this user?'}>
                  
                    <Popconfirm
                          title="Are you sure to disapprove this user?"
                          onConfirm={() => handleDisapprove(selectedMember)}
                          okButtonProps={{ loading: confirmLoading }}
                          open={disapprovedUser}
                          onCancel={CancelPopconfirm}
                      >
                        <button className='btn disapprove-btn' onClick={DisapproveUser}><FaUserXmark /></button>
                      </Popconfirm>
                  </Tooltip>
                </div>
              ) : (
              <div className="buttons">
                <Tooltip placement="topRight" title={'Edit This Member'}>
                  <button className='btn edituser-btn' onClick={OpenEdit}><FaUserEdit /></button>
                </Tooltip>
                <Tooltip placement="topRight" title={'Delete This Member'}>
                  <Popconfirm
                        title="Are you sure to DELETE this user?"
                        onConfirm={() => handleDeleteUser(selectedMember)}
                        okButtonProps={{ loading: confirmLoading }}
                        open={confirmDelete}
                        onCancel={CancelPopconfirm}
                    >
                      <button className='btn deleteuser-btn' onClick={OpenDelete}><MdDeleteForever /></button>
                    </Popconfirm>
                </Tooltip>
              </div>
              )}
            </div>
            <Modal
              title="Edit Member Form"
              centered
              open={confirmEdit}
              onCancel={handleCancel}
              footer={false}
              width={800}
              confirmLoading={confirmLoading}
            >
              <div className="center-form">
                <form action="" onSubmit={handleSubmitEditForm}>
                  {showError && (
                    <div className="text-danger m-auto mt-3 mb-3" style={{textAlign: 'center', fontWeight: '600', textWrap: 'wrap', width: '85%'}}>
                      {errorMessage}
                    </div>
                  )}
                  <div className="input-group gap-2">
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        className="form-control"
                        name="firstname"
                        id="firstname"
                        placeholder=''
                        value={newFormData.firstname}
                        onChange={handleInputChange}
                        required
                      />
                      <label htmlFor="firstname">First Name</label>
                    </div>
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        className="form-control"
                        name="middlename"
                        id="middlename"
                        placeholder=''
                        value={newFormData.middlename}
                        onChange={handleInputChange}
                      />
                      <label htmlFor="middlename">Middle Name</label>
                    </div>
                    <div className="form-floating mb-3">
                    <input
                      type="text"
                      className="form-control"
                      name="lastname"
                      id="lastname"
                      placeholder=''
                      value={newFormData.lastname}
                      onChange={handleInputChange}
                      required
                    />
                    <label htmlFor="lastname">Last Name</label>
                    </div>
                  </div>
                  <div className="input-group gap-2">
                    {selectedMember.membership === "LOS Member" && (
                      isUpgrade ? (
                      <div className="form-floating mb-3">
                        <input
                          type="text"
                          className="form-control"
                          name="accountID"
                          id="accountID"
                          value={newFormData.accountID}
                          placeholder=''
                          onChange={handleInputChange}
                          required
                        />
                        <label htmlFor="accountID">IOS Account ID</label>
                      </div>
                      ) : (
                        <button className='btn btn-primary mb-3' onClick={openConfirmUpgrade}>
                          You want to upgrade this member?
                        </button>
                      )
                    )}
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        className="form-control"
                        name="contact"
                        id="contact"
                        placeholder=''
                        value={newFormData.contact}
                        onChange={handleInputChange}
                        required
                      />
                      <label htmlFor="contact">Contact Number</label>
                    </div>
                  </div>
                  <div className="input-group gap-2">
                    <div className="form-floating mb-3">
                      <select className="form-select" 
                          name="region"
                          id="region"
                          value={newFormData.region}
                          onChange={onChangeRegion}
                          required>
                        <option value={newFormData.region} disabled selected>
                        {newFormData.region}
                        </option>
                        {regionData.map(item => (
                          <option
                            key={item.region_code}
                            value={item.region_code}
                          >
                            {item.region_name}
                          </option>
                        ))}
                      </select>
                      <label htmlFor="region">Region</label>
                    </div>
                    <div className="form-floating mb-3">
                      <select className="form-select" 
                          name="province"
                          id="province"
                          value={newFormData.province}
                          onChange={onChangeProvince}
                          required>
                        <option value={newFormData.province} disabled selected>
                        {newFormData.province}
                        </option>
                        {provinceData.map(item => (
                          <option
                            key={item.province_code}
                            value={item.province_code}
                          >
                            {item.province_name}
                          </option>
                        ))}
                      </select>
                      <label htmlFor="province">Province</label>
                    </div>
                  </div>

                  <div className="input-group gap-2">
                    <div className="form-floating mb-3">
                      <select className="form-select" 
                          name="city"
                          id="city"
                          value={newFormData.city}
                          onChange={onChangeCity}
                          required>
                        <option value={newFormData.city} disabled selected>
                        {newFormData.city}
                        </option>
                        {cityData.map(item => (
                          <option 
                            key={item.city_code} 
                            value={item.city_code}
                          >
                            {item.city_name}
                          </option>
                        ))}
                      </select>
                      <label htmlFor="city">City / Municipal</label>
                    </div>
                    <div className="form-floating mb-3">
                      <select className="form-select" 
                          name='brgy'
                          id="brgy"
                          value={newFormData.brgy}
                          onChange={onChangeBarangay}
                          required>
                        <option value={newFormData.brgy} disabled selected>
                        {newFormData.brgy}
                        </option>
                        {barangayData.map(item => (
                          <option 
                            key={item.brgy_code} 
                            value={item.brgy_code}
                          >
                            {item.brgy_name}
                          </option>
                        ))}
                      </select>
                      <label htmlFor="brgy">Barangay</label>
                    </div>
                  </div>

                  <div className="input-group gap-2">
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        className="form-control"
                        name="street"
                        id="street"
                        placeholder=""
                        value={newFormData.street}
                        onChange={handleInputChange}
                        required
                      />
                      <label htmlFor="street">Street</label>
                    </div>
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        className="form-control"
                        name="postal"
                        id="postal"
                        placeholder=""
                        value={newFormData.postal}
                        onChange={handleInputChange}
                        required
                      />
                      <label htmlFor="postal">Postal Code</label>
                    </div>
                  </div>
                  
                  <div className="form-floating mb-3">
                    <textarea
                      style={{height: '132px'}}
                      className="form-control"
                      name="landmark"
                      id="landmark"
                      value={newFormData.landmark}
                      onChange={handleInputChange}
                      placeholder=""                      
                    />
                    <label htmlFor="landmark">Landmark (Optional)</label>
                  </div>

                  <div className="d-flex justify-content-end align--items-center">
                      <button type="submit" className='btn btn-success' style={{ minWidth: '110px' }} 
                        disabled={confirmLoading}>
                      {confirmLoading && (
                        <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                      )}
                      {confirmLoading ? 'Editing...' : 'Save'}
                    </button>
                  </div>
                </form>
              </div>
            </Modal>
        </div>
      </div>
       )}
      <div className="user-data">
        <div className="head">
          <h4>Members List</h4>
          <div className="search-bar">
            <IoSearch className='bx'/>
            <input type="search" 
                   placeholder='Search here...' 
                   className='form-control' 
                   onChange={handleSearchChange}/>
            <Tooltip placement="topRight" title={'Add Member'}>
              <button className='btn adduser-btn' onClick={showAddingForm}><BsPersonFillAdd /></button>
            </Tooltip>
          </div>
          <Modal
            title="Add Member Form"
            centered
            open={openAddMemberForm}
            onCancel={handleCancel}
            footer={false}
            width={800}
            confirmLoading={confirmLoading}
          >
            <div className="center-form">
              <Steps current={currentStep} size="small" style={{ marginBottom: '20px' }} >
                <Step title="Personal" />
                <Step title="Account" />
                <Step title="Member"/>
                <Step title="Address" />
                <Step title="Others" />
              </Steps>
                <form action="" onSubmit={handleSumbitAddingForm}>
                {showError && (
                <div className="text-danger m-auto mt-3 mb-3" style={{textAlign: 'center', fontWeight: '600', textWrap: 'wrap', width: '85%'}}>
                  {errorMessage}
                </div>
               )}
                {currentStep === 0 && (
                <>
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      className="form-control"
                      name="firstname"
                      id="firstname"
                      placeholder=''
                      value={signupForm.firstname}
                      onChange={(e) => setsignupForm({ ...signupForm, firstname: e.target.value })}
                      required
                    />
                    <label htmlFor="firstname">First Name</label>
                  </div>
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      className="form-control"
                      name="middlename"
                      id="middlename"
                      placeholder=''
                      value={signupForm.middlename}
                      onChange={(e) => setsignupForm({ ...signupForm, middlename: e.target.value })}
                      required
                    />
                    <label htmlFor="middlename">Middle Name</label>
                  </div>
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      className="form-control"
                      name="lastname"
                      id="lastname"
                      placeholder=''
                      value={signupForm.lastname}
                      onChange={(e) => setsignupForm({ ...signupForm, lastname: e.target.value })}
                      required
                    />
                    <label htmlFor="lastname">Last Name</label>
                  </div>
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      className="form-control"
                      name="contact"
                      id="contact"
                      placeholder=''
                      value={signupForm.contact}
                      onChange={(e) => {
                        const input = e.target.value;
                        if (input.length <= 11) {
                          setsignupForm({ ...signupForm, contact: input });
                        }
                      }}
                      required
                    />
                    <label htmlFor="contact">Contact Number</label>
                  </div>
                </>
                )}
                {currentStep === 1 && (
                <>
                <div className="form-floating mb-3">
                    <input
                      type="text"
                      className="form-control"
                      name="username"
                      id="username"
                      placeholder=""
                      value={username}
                      onChange={(e) => setUserName(e.target.value)}
                      required
                    />
                    <label htmlFor="username">Username</label>
                </div>
                  <div className="form-floating mb-3">
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      id="email"
                      placeholder=""
                      value={newEmail}
                      onChange={(e) => {
                        setNewEmail(e.target.value)
                      }}
                      required
                    />
                    <label htmlFor="email">Email</label>
                </div>
                <div className="form-floating mb-3">
                  <input
                    type="password"
                    className="form-control"
                    name="password"
                    id="password"
                    placeholder=""
                    required
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                      setErrorMessage('');
                    }}
                  />
                  <label htmlFor="password">Password</label>
                </div>
                <div className="form-floating mb-3">
                  <input
                    type="password"
                    className="form-control"
                    name="confirmPass"
                    id="confirmPass"
                    placeholder=""
                    required
                    value={confirmPass}
                    onChange={(e) => {
                      setConfirmPass(e.target.value);
                      setErrorMessage('');
                    }}
                  />
                  <label htmlFor="confirmPass">Confirm Password</label>
                </div>
                </>
                )}
                {currentStep === 2 && (
                    <>
                    <div className="membership mb-3">
                      <span>Are You a Member of Infinite Online Shop?</span>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="membership"
                          id="IOSMember"
                          value="IOS Member"
                          checked={signupForm.membership === 'IOS Member'}
                          onChange={(e) => {
                            setIsIOSMember(true);
                            setsignupForm({ ...signupForm, membership: e.target.value });
                          }}
                          selected
                        />
                        <label className="form-check-label" htmlFor="IOSMember">
                          Yes
                        </label>
                      </div>

                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="membership"
                          id="LOSMember"
                          value="LOS Member"
                          checked={signupForm.membership === 'LOS Member'}
                          onChange={(e) => {
                            setIsIOSMember(false);
                            setsignupForm({ ...signupForm, membership: e.target.value });
                          }}
                        />
                        <label className="form-check-label" htmlFor="LOSMember">
                          No
                        </label>
                      </div>
                    </div>
                    {isIOSMember && (
                      <div className="form-floating mb-3">
                        <input
                          type="text"
                          className="form-control"
                          name="accountID"
                          id="accountID"
                          placeholder=""
                          value={signupForm.accountID}
                          onChange={(e) => {
                            const input = e.target.value;
                            setsignupForm({ ...signupForm, accountID: input });
                          }}
                          required
                        />
                        <label htmlFor="accountID">Account ID</label>
                      </div>
                    )}
                    </>
                )}
                {currentStep === 3 && (
                <>
                  <div className="form-floating mb-3">
                    <select className="form-select" 
                        name="region"
                        id="region"
                        value={inputRegion}
                        onChange={onChangeRegion}
                        required>
                      <option value='' disabled selected>
                        Select Region
                      </option>
                      {regionData.map(item => (
                        <option
                          key={item.region_code}
                          value={item.region_code}
                        >
                          {item.region_name}
                        </option>
                      ))}
                    </select>
                    <label htmlFor="region">Region</label>
                  </div>
                  <div className="form-floating mb-3">
                    <select className="form-select" 
                        name="province"
                        id="province"
                        value={inputProvince}
                        onChange={onChangeProvince}
                        required>
                      <option value='' disabled selected>
                        Select Province
                      </option>
                      {provinceData.map(item => (
                        <option
                          key={item.province_code}
                          value={item.province_code}
                        >
                          {item.province_name}
                        </option>
                      ))}
                    </select>
                    <label htmlFor="province">Province</label>
                  </div>
                  <div className="form-floating mb-3">
                    <select className="form-select" 
                        name="city"
                        id="city"
                        value={inputCity}
                        onChange={onChangeCity}
                        required>
                      <option value='' disabled selected>
                        Select City / Municipality
                      </option>
                      {cityData.map(item => (
                        <option 
                          key={item.city_code} 
                          value={item.city_code}
                        >
                          {item.city_name}
                        </option>
                      ))}
                    </select>
                    <label htmlFor="city">City / Municipal</label>
                  </div>
                  <div className="form-floating mb-3">
                    <select className="form-select" 
                        name='brgy'
                        id="brgy"
                        value={inputBrgy}
                        onChange={onChangeBarangay}
                        required>
                      <option value='' disabled selected>
                        Select Barangay
                      </option>
                      {barangayData.map(item => (
                        <option 
                          key={item.brgy_code} 
                          value={item.brgy_code}
                        >
                          {item.brgy_name}
                        </option>
                      ))}
                    </select>
                    <label htmlFor="brgy">Barangay</label>
                  </div>
                </>
                )}
                {currentStep === 4 && (
                <>
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      className="form-control"
                      name="street"
                      id="street"
                      placeholder=""
                      value={signupForm.street}
                      onChange={(e) => setsignupForm({ ...signupForm, street: e.target.value })}
                      required
                    />
                    <label htmlFor="street">Street</label>
                  </div>
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      className="form-control"
                      name="postal"
                      id="postal"
                      placeholder=""
                      value={signupForm.postal}
                      onChange={(e) => setsignupForm({ ...signupForm, postal: e.target.value })}
                      required
                    />
                    <label htmlFor="postal">Postal Code</label>
                  </div>
                  <div className="form-floating mb-3">
                    <textarea
                      style={{height: '132px'}}
                      className="form-control"
                      name="landmark"
                      id="landmark"
                      value={signupForm.landmark}
                      onChange={(e) => setsignupForm({ ...signupForm, landmark: e.target.value })}
                      placeholder=""                      
                    />
                    <label htmlFor="landmark">Landmark (Optional)</label>
                  </div>
                </>
                )}
                <div className="d-flex justify-content-between align--items-center">
                  {currentStep > 0 && (
                    <button type='button' className='btn btn-primary' style={{ minWidth: '110px' }} 
                      onClick={handlePrev}>
                        <GrFormPreviousLink/> Previous
                    </button>
                  )}
                  {currentStep < 4 && (
                    <button type='button' className='btn btn-primary' style={{ minWidth: '110px' }} 
                      onClick={handleNext}>
                        Next <GrFormNextLink/>
                    </button>
                  )}
                  {currentStep === 4 && (
                    <button type="submit" className='btn btn-success' style={{ minWidth: '110px' }} 
                      disabled={confirmLoading}>
                    {confirmLoading && (
                      <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                    )}
                    {confirmLoading ? 'Creating...' : 'Submit Form'}
                  </button>
                  )}
                </div>
                
                </form>
              </div>
          </Modal>
          
        </div>
        <div className="table-data">
          {filteredMembers.length === 0 ? (
            <NoDataFount/>
          ) : (
          <div className="member-list">
            <table>
              <thead>
                <tr>
                  <th>Member ID</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Contact</th>
                  <th>Address</th>
                  <th>Unilevel</th>
                </tr>
              </thead>
              <tbody>
                {currentPageMembers.map((member) => (
                  <tr key={member.userID} 
                      onClick={() => handleMemberSelect(member)}
                      className={selectedMember && selectedMember.userID === member.userID ? 'active' : ''}>
                    <td width={'10%'}>{member.accountID || 'N/A'}</td>
                    <td width={'15%'}>{member.firstname} {member.middlename} {member.lastname}</td>
                    <td width={'23%'}><p style={{margin: '0px', wordWrap: 'break-word', maxWidth: '240px'}}>{member.email}</p></td>
                    <td width={'12%'}>{member.contact}</td>
                    <td width={'30%'}><p style={{margin: '0px', wordWrap: 'break-word', maxWidth: '300px'}}>{member.street},{member.brgy} {member.municipal}, {member.province}</p> </td>
                    {member.status === 'Pending' || member.status === 'Blocked' ? (
                      <td width={'5%'}><span class={`status ${member.status}`}>{member.status}</span></td>
                    ) : (
                      <td width={'5%'}>
                        <span class={`status ${`Level-`+member.level}`}>{`Level-`+ member.level}</span>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="pagination-container">
              <Pagination
                  current={currentPage}
                  total={filteredMembers.length}
                  onChange={handlePageChange}
                  pageSize={pageSize}
                  showSizeChanger={false}
              />
            </div>
          </div>
          )}
        </div>
      </div>
    </main>
  )
}

export default UserManagement;
