import React, { useEffect, useState } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import AdminPage from '../Admin_Page/Layout/AdminPage';
const ProtectUser = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  
  useEffect(() => {
    if (!token) {
      navigate('/');
    }
  }, [navigate]);
  return localStorage.getItem('token') ? <AdminPage/> : <Navigate to="/Admin" />;
};

export default ProtectUser;
