import React, { useEffect, useRef, useState } from 'react';
import './AdminPage.css'
import logo from '../../Assets/images/loyalista_logo(original).png'
import { Link, Outlet, useNavigate } from 'react-router-dom'
import { FiMenu } from "react-icons/fi";
import { AiFillDashboard } from "react-icons/ai";
import { FaUsers } from "react-icons/fa";
import { FaCashRegister } from "react-icons/fa6";
import { MdAssignment , MdLocalMall, MdRequestQuote  } from "react-icons/md";
import { IoMdLogOut, IoMdSettings  } from "react-icons/io";
import { GiProgression } from "react-icons/gi";
import { TbHistory } from "react-icons/tb";
import { GiOrganigram } from "react-icons/gi";
import profile from '../../Assets/images/instagram-01.jpg'
import { getAuth, signOut } from 'firebase/auth';

const AdminPage = () => {
    const [hidesidebar, setHideSidebar] = useState(false);
    const [activeMenu, setActiveMenu] = useState(0);
    const navigate = useNavigate();
    const handleMenu = () => {
        setHideSidebar((prevState) => !prevState);
    }
    const handleModeChange = () => {
        const switchMode = document.getElementById('switch-mode');
        if (switchMode.checked) {
            document.body.classList.add('dark');
        } else {
            document.body.classList.remove('dark');
        }
    };

    // Update handleActiveMenu to set activeMenu to the clicked menu item's index
    const handleActiveMenu = (index) => {
        setActiveMenu(index);
        localStorage.setItem('active', index);
    };
    useEffect(() => {
        const storedActiveMenu = localStorage.getItem('active'); // Retrieve the active menu index from localStorage
        if (storedActiveMenu !== null) {
            setActiveMenu(parseInt(storedActiveMenu));
        }
    }, []);

    const handleLogout = () => {
        localStorage.removeItem('active');
        localStorage.removeItem('admin');
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        localStorage.removeItem('profileImage');
        setActiveMenu(null);
        const auth = getAuth();

        signOut(auth).then(() => {
            navigate('/admin');
        }).catch((error) => {
            console.log(error);
        });
    };
    
  return (
    <div id='admin'>
        <section id="sidebar" className={hidesidebar ? 'hide' : ' ' }>
            <Link to="/MyAdmin" className="brand" onClick={() => handleActiveMenu(0)}>
                <img src={logo} alt='Company Logo'/>
            </Link>
            <ul className="side-menu top">
                <li className={activeMenu === 0 ? 'active' : ''}>
                    <Link to="/MyAdmin" onClick={() => handleActiveMenu(0)}>
                        <AiFillDashboard className='bx' style={{fontSize: '21px'}}/>
                        <span className="text">Dashboard</span>
                    </Link>
                </li>
                <li className={activeMenu === 1 ? 'active' : ''}>
                    <Link to="/MyAdmin/SalesReport" onClick={() => handleActiveMenu(1)}>
                        <GiProgression className='bx'/>
                        <span className="text">Sales Report</span>
                    </Link>
                </li>
                <li className={activeMenu === 2 ? 'active' : ''}>
                    <a href="/MyAdmin/OrdersList" onClick={() => handleActiveMenu(2)}>
                        <MdAssignment className='bx'/>
                        <span className="text">Orders List</span>
                    </a>
                </li>
                <li className={activeMenu === 3 ? 'active' : ''}>
                    <a href="/MyAdmin/OrderHistory" onClick={() => handleActiveMenu(3)}>
                        <TbHistory className='bx'/>
                        <span className="text">Orders History</span>
                    </a>
                </li>
                <li className={activeMenu === 4 ? 'active' : ''}>
                    <a href="/MyAdmin/OurProducts" onClick={() => handleActiveMenu(4)}>
                        <MdLocalMall className='bx'/>
                        <span className="text">Products</span>
                    </a>
                </li>
                <li className={activeMenu === 5 ? 'active' : ''}>
                    <a href="/MyAdmin/CashoutRequest" onClick={() => handleActiveMenu(5)}>
                        <MdRequestQuote className='bx' />
                        <span className="text">Cashout Requests</span>
                    </a>
                </li>
                <li className={activeMenu === 6 ? 'active' : ''}>
                    <a href="/MyAdmin/CashbondRequest" onClick={() => handleActiveMenu(6)}>
                        <FaCashRegister className='bx' />
                        <span className="text">RTS Load Requests</span>
                    </a>
                </li>
                <li className={activeMenu === 7 ? 'active' : ''}>
                    <a href="/MyAdmin/UserManagement" onClick={() => handleActiveMenu(7)}>
                        <FaUsers className='bx'/>
                        <span className="text">User Management</span>
                    </a>
                </li>
                <li className={activeMenu === 8 ? 'active' : ''}>
                    <a href="/MyAdmin/GenealogyChart" onClick={() => handleActiveMenu(8)}>
                        <GiOrganigram className='bx' style={{fontSize: '25px'}}/>
                        <span className="text">Network</span>
                    </a>
                </li>
            </ul>
            <ul className="side-menu">
                {/* <li className={activeMenu === 7 ? 'active' : ''}>
                    <a href="#" onClick={() => handleActiveMenu(7)}>
                        <IoMdSettings className='bx'/>
                        <span className="text">Settings</span>
                    </a>
                </li> */}
                <li>
                    <Link to="/Admin" className="logout" onClick={handleLogout}>
                        <IoMdLogOut className='bx' style={{fontSize: '19px'}}/>
                        <span className="text">Logout</span>
                    </Link>
                </li>
            </ul>
        </section>
        <section id='content'>
            <nav>
                <FiMenu className='bx bx-menu' onClick={handleMenu}/>
                <div className='float-right'>
                    <input type="checkbox" id="switch-mode" hidden onClick={handleModeChange}/>
                    <label for="switch-mode" class="switch-mode"></label> 
                </div>
            </nav>
            <Outlet/>
            <div className='main-footer'>
                
                <div className='text-footer'>
                    <strong>© Loyalista Online Shop Corp. 2024  </strong>
                    | All rights reserved.
                </div>
                
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version </b>1.0.0
                </div>
            </div>
        </section>
    </div>
  )
}

export default AdminPage