import React, { useState } from 'react';
import './PrivacyPolicy.css';
import Header2 from '../../Header/Header2';
import Footer from '../../Footer/Footer';
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import License from '../License/License';


const PrivacyPolicy = () => {
  const [activeSection, setActiveSection] = useState(null);

  const sections = [
    { title: "1. INTRODUCTION",
      content: [
      <p><b>A.</b>
        Welcome to the Loyalista Online Shop platform run by 
        Loyalista Online Shop Corp. Loyalista Online Shop takes its responsibilities under applicable 
        privacy laws and regulations ("Privacy Laws") seriously and is committed to respecting the 
        privacy rights and concerns of all Users of our Loyalista Online Shop website and mobile application 
        (the "Platform") (we refer to the Platform and the services we provide as described on our 
        Platform collectively as the "Services"). Users refers to a user who 
        registers for an account with us for use of the Services, including Distributors (individually 
        and collectively, "Users", "you" or "your"). We recognize the importance of the personal data you 
        have entrusted to us and believe that it is our responsibility to properly manage, protect and 
        process your personal data. This Privacy Policy ("Privacy Policy" or "Policy") is designed to 
        assist you in understanding how we collect, use, disclose and/or process the personal data you 
        have provided to us and/or we possess about you, whether now or in the future, as well as to 
        assist you in making an informed decision before providing us with any of your personal data. 
      </p>,
      <p><b>B.</b>
        "Personal Data" or "personal data" means data, whether true or not, about an individual who 
        can be identified from that data, or from that data and other information to which an organization 
        has or is likely to have access. Common examples of personal data could include name, identification 
        number, and contact information.
      </p>,
      <p><b>C.</b>
        By using the Services, registering for an account with us, visiting our Platform, or accessing 
        the Services, you acknowledge and agree that you accept the practices, requirements, and/or policies 
        outlined in this Privacy Policy, and you hereby consent to us collecting, using, disclosing and/or 
        processing your personal data as described herein. IF YOU DO NOT CONSENT TO THE PROCESSING OF YOUR 
        PERSONAL DATA AS DESCRIBED IN THIS PRIVACY POLICY, PLEASE DO NOT USE OUR SERVICES OR ACCESS OUR 
        PLATFORM. If we change our Privacy Policy, we will notify you, including by posting those changes 
        or the amended Privacy Policy on our Platform. We reserve the right to amend this Privacy Policy 
        at any time. To the fullest extent permissible under applicable law, your continued use of 
        the Services or Platform, including placing of any orders, shall constitute your acknowledgment 
        and acceptance of the changes made to this Privacy Policy. 
      </p>,
      <p><b>D.</b>
        This Policy applies in conjunction with other notices, 
        contractual clauses, consent clauses that apply in relation to the collection, storage, use, 
        disclosure and/or processing of your personal data by us and is not intended to override those 
        notices or clauses unless we state expressly otherwise.  
      </p>,
      <p><b>E.</b>
        This Policy applies to both buyers and sellers who
        use the Services except where expressly stated otherwise.   
      </p>,
       ]
    },
    { title: "2. WHEN WILL LOYALISTA ONLINE SHOP COLLECT PERSONAL DATA?",
      content: [
        <p ><b>A.</b>
          We will/may collect personal data about you:  
        </p>,
        <p><b>B.</b>  
          When you register and/or use our Services or Platform, or open an account with us; 
        </p>,
        <p><b>C.</b>
          When you submit any form, including, but not limited to, application forms or other forms 
          relating to any of our products and services, whether online or by way of a physical form; 
        </p>,
        <p><b>D.</b>
          When you enter into any agreement or provide other documentation or information in respect 
          of your interactions with us, or when you use our products and services;    
        </p>,
        <p><b>E.</b>
          When you interact with us, such as via telephone calls (which may be recorded), letters, 
          fax, face-to-face meetings, social media platforms and emails, including when you interact
          with our customer service agents;   
        </p>,
        <p><b>F.</b>
          When you use our electronic services, or interact with us via our application or use 
          services on our Platform. This includes, without limitation, through cookies which we 
          may deploy when you interact with our application or website;    
        </p>,
        <p><b>G.</b>
          When you grant permissions on your device to share information with our application or Platform;    
        </p>,
        <p><b>H.</b>
          When you provide us with feedback or complaints;    
        </p>,
          <p><b>I.</b>
          When you register for a contest; or when you submit your personal data to us for any reason.
        </p>,
      ]
    },
    { title: "3. WHAT PERSONAL DATA WILL LOYALISTA ONLINE SHOP COLLECT?",
      content: [
        <p><b>A.</b>
          The personal data that LOYALISTA ONLINE SHOP may collect includes but is not limited to:
          <ul>
            <li>Name</li>
            <li>Email Address</li>
            <li>Bank Account and payment information</li>
            <li>Telephone Number</li>
            <li>Information sent by or associated with the device(s) used to access our Services or Platform; </li>
            <li>Information about your network and the people and accounts you interact with</li>
            <li>Government issued identification or other information required for our due diligence, 
                know your customer, identity verification, or fraud prevention purposes; </li>
            <li>Any other information about the User when the User signs up to use our Services
                or Platform, and when the User uses the Services or Platform, as well as information 
                related to how the User uses our Services or Platform; </li>

          </ul> 
        </p>,
        <p><b>B.</b>
          You agree not to submit any information to us which is inaccurate or misleading, 
          and you agree to inform us of any inaccuracies or changes to such information. 
          We reserve the right at our sole discretion to require further documentation to 
          verify the information provided by you.
        </p>,
        <p><b>C.</b>
          If you do not want us to collect the aforementioned information/personal data, 
          you may opt out at any time by notifying our Data Protection Officer in writing. 
          Further information on opting out can be found in the section below entitled 
          "How can you withdraw consent, remove, request access to or modify information you have 
          provided to us?"  Note, however, that opting out or withdrawing your consent for us to 
          collect, use or process your personal data may affect your use of the Services and the Platform.
          For example, opting out of the collection of location information will cause its location-based 
          features to be disabled.  
        </p>,
      ]
    },
    { title: "4.	HOW DO WE USE THE INFORMATION YOU PROVIDE US?",
      content: [
        <p><b>A.</b>
          We may collect, use, disclose and/or process your personal data for one or more of the following purposes:
        </p>,
        <p><b>B.</b>
          To consider and/or process your application/transaction with us or your transactions or communications. 
        </p>,
        <p><b>C.</b>
          To manage, operate, provide and/or administer your use of and/or access to our Services and our 
          Platform (including, without limitation, remembering your preference), as well as your relationship 
          and user account with us; 
        </p>,
        <p><b>D.</b>
          To respond to, process, deal with or complete a transaction and/or to fulfil your requests for 
          certain products and services and notify you of service issues and unusual account actions; 
        </p>,
        <p><b>E.</b>
          To enforce our Terms of Service or any applicable end user license agreements; 
        </p>,
        <p><b>F.</b>
          To protect personal safety and the rights, property or safety of others; 
        </p>,
        <p><b>G.</b>
          For identification, verification, due diligence, or know your customer purposes; 
        </p>,
        <p><b>H.</b>
          To maintain and administer any software updates and/or other updates and support that may be 
          required from time to time to ensure the smooth running of our Services; 
        </p>,
        <p><b>I.</b>
          To deal with or facilitate customer service, carry out your instructions, deal with or respond
          to any enquiries given by (or purported to be given by) you or on your behalf; 
        </p>,
      ]
    },
    { title: "5.	Limitations on Your Right to Correction",
      content: [
        <p >
        We reserve the right to refuse to correct your personal data in accordance with the provisions 
        as set out in Privacy Laws, where they require and/or entitle an organization to refuse to 
        correct personal data in stated circumstances. 
        </p>,
        ]
    },
    { title: "6.  QUESTIONS, CONCERNS OR COMPLAINTS? CONTACT US ",
      content: [
        <p >
          If you have any questions or concerns about our privacy practices, we welcome you to contact us through e-mail at
          <a href="https://mail.google.com/mail/u/0/#inbox?compose=CllgCJNvvhsPWGwMbzSfcCgxxqkfrDrFGmMRzxLbkPXkMHqgTqNhVtpfXjKTKxSPccbpQFpMSQV" target='_blank'> loyalistaonlineshopcorp@gmail.com.</a>
        </p>,
        ]
    },
  ];

  const toggleSection = (index) => {
    setActiveSection(index === activeSection ? null : index);
  };

  return (
    <>
    <Header2/>
      <section id='policy'>
        <div className='container'>
          <div className="row" id='private'>
            <div className="col-lg-4 left-section">
              <div className="header">
                <h2>Private Policy</h2>
              </div>
            </div>
            <div className="col-lg-8 right-section">
              <div className="content">

                {sections.map((section, index) => (
                  <div key={index} className={`context ${activeSection === index ? 'active' : ''}`} onClick={() => toggleSection(index)}>
                    <div className='title'>
                      <strong>{section.title}</strong>
                      <span>
                        {activeSection === index ? <IoIosArrowUp /> : <IoIosArrowDown />}
                      </span>
                    </div>
                    {activeSection === index && (
                      <div className='text'>
                        {section.content}
                      </div>
                    )}
                  </div>
                ))}
   
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer/>
    </>
  );
}

export default PrivacyPolicy;