import React from 'react'
import './AboutUs.css'
import Header2 from '../../Header/Header2'
import Footer from '../../Footer/Footer'
import left_banner from '../../../../Assets/images/loyalista_logo(original).png'
import about_banner from '../../../../Assets/images/about_banner.jpg'
import right_banner from '../../../../Assets/images/loyalista_logo_only.png'
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn } from "react-icons/fa6";
const AboutUs = () => {
  return (
    <>
    <Header2/>
    <section id="about">
        <div className="container about-section">
            <div className="row" id='about-us'>
                <div className="col-lg-6">
                    <div className="left-image">
                        <img src={about_banner} alt="left banner" />
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="right-content">
                        <h4>Who We Are</h4>
                        <div className="quote">
                            <i className="fa fa-quote-left"></i>
                            <p>
                                <b>Loyalista Online Shop</b> is a dynamic dropshipping platform that enables distributors to effortlessly promote and sell products without the need for maintaining inventory. 
                                Our platform bridges the gap between suppliers and end consumers, ensuring a seamless and cost-effective process for all parties involved.
                            </p>
                        </div>
                        
                        <div className="quote">
                            <p style={{fontSize: '21px'}}>How Does Loyalista Online Shop Work?</p>
                            
                            <p>
                                <b>Dropshipping Model:</b> As a distributor on Loyalista, you can select from a wide array of products available on our platform. 
                                Once you make a sale, the product is shipped directly from the supplier to the customer, eliminating the need for you to handle physical stock.
                            </p>
                            <p>
                                <b>Link Sharing:</b> To promote products, distributors can generate and share unique referral links. 
                                These links can be distributed across various digital channels, including social media, email, blogs, and websites, 
                                maximizing your reach and sales potential.
                            </p>
                          
                            <p>
                                <b>Compensation:</b> Loyalista rewards distributors generously. For every successful transaction made through your referral link.
                                This compensation structure is designed to incentivize and reward your promotional efforts, 
                                ensuring that your hard work directly translates into significant earnings.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row" id='why-us'>
                <div className="col-lg-6">
                    <div className="left-content">
                        <h4>Why Choose Loyalista Online Shop?</h4>
                        <div className="quote">
                            <p>
                                <b>Ease of Use:</b> Our user-friendly interface makes it easy for anyone to start dropshipping. 
                                No prior experience or technical knowledge is required.
                            </p>
                            <p>
                                <b>Diverse Product Range:</b> Access a vast selection of high-quality products from reputable suppliers.
                                High Commission: Earn a competitive commission per successful transaction, setting us apart from other platforms.
                            </p>
                            <p>
                                <b>Support and Resources:</b> Benefit from our comprehensive support and resources, including marketing tips and tools to enhance 
                                your promotional strategies.
                            </p>
                        </div>
                        <div className="quote">
                            <p style={{fontSize: '19px', marginBottom: '10px'}}>Join the Loyalista Community</p>
                            <i className="fa fa-quote-left"></i>
                            <p>
                                By joining the Loyalista, you become part of a thriving community of forward-thinking entrepreneurs. 
                                Our platform empowers you to build a successful online business with minimal risk and maximum reward. Start your journey with Loyalista Online Shop today and experience the future of e-commerce.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6  d-flex justify-content-center align-items-start">
                    <div className="right-image">
                        <img src={right_banner} alt="" />
                    </div>
                </div>
            </div>
        </div>
    </section>
    <Footer/>
    </>
  )
}

export default AboutUs
