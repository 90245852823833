import React, { useState, useEffect } from 'react';
import Loyalista_logo from '../../../Assets/images/loyalista_logo.png';
import bida_logo from '../../../Assets/images/BiDA.png'
import '../Header/header.css';
import { Link, useLocation } from 'react-router-dom';
import { FaCartShopping } from "react-icons/fa6";
import { Badge, Drawer } from 'antd';
import { CartContent, CartFooter } from '../../../HookComponents/CartContainer/CartContent';
import { useCartStore } from '../../../DataTest/cart-store';

const Header2 = () => {
    const [isMenuOpen, setMenuOpen] = useState(false);
    const [isFixed, setFixed] = useState(false);
    const [activeSection, setActiveSection] = useState('');
    const [cartContainer, setCartContainer] = useState(false);
    const location = useLocation();

    const { cart } = useCartStore((state) => ({
        cart: state.cart
    }));

    useEffect(() => {
        const handleScroll = () => {
            const scrollY = window.scrollY;
            if (scrollY > 50) {
                setFixed(true);
            } else {
                setFixed(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
      const path = location.pathname;
      if (path === '/') {
        setActiveSection('home');
      } else if (path === '/About-us') {
        setActiveSection('About-us');
      } else if (path.startsWith('/Products')) {
        setActiveSection('Products');
      } else if (path === '/License') {
        setActiveSection('Partner');
      } else if (path === '/Contact-Us') {
        setActiveSection('Contact-Us');
      } else if (path === '/Login') {
          setActiveSection('Login');
      } else {
          setActiveSection('');
      }
  }, [location.pathname]);

    const handleActiveMenu = (section) => {
        setActiveSection(section);
        setMenuOpen(false);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const toggleMenu = () => {
        setMenuOpen((prevState) => !prevState);
    };

    const openCart = () => {
        setCartContainer(true);
    };

    const closeCart = () => {
        setCartContainer(false);
    };

    return (
        <header className={`header-area ${isFixed ? 'header-sticky' : ''}`}>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-div">
                        <nav className="main-nav">
                            <Link to="/" className="logo" onClick={() => handleActiveMenu('home')}>
                                <img src={Loyalista_logo} alt='BiDA Logo' />
                                {/* <span className='text-logo'>
                                    <p id='bida'>BiDA</p>
                                    <p>Project</p>
                                </span> */}
                            </Link>
                            <ul className="nav">
                                <li onClick={() => handleActiveMenu('home')}>
                                    <Link to="/" className={`scroll-to-section ${activeSection === 'home' ? 'active' : ''}`} onClick={() => handleActiveMenu('home')}>Home</Link>
                                </li>
                                <li onClick={() => handleActiveMenu('About-us')}>
                                    <Link to="/About-us" className={`scroll-to-section ${activeSection === 'About-us' ? 'active' : ''}`} onClick={() => handleActiveMenu('About-us')}>About Us</Link>
                                </li>
                                <li onClick={() => handleActiveMenu('Products')}>
                                    <Link to="/Products" className={`scroll-to-section ${activeSection === 'Products' ? 'active' : ''}`} onClick={() => handleActiveMenu('Products')}>Products</Link>
                                </li>
                                <li onClick={() => handleActiveMenu('Partner')}>
                                    <Link to="/License" className={`scroll-to-section ${activeSection === 'Partner' ? 'active' : ''}`} onClick={() => handleActiveMenu('Partner')}>Merchant</Link>
                                </li>
                                <li>
                                    <a href="https://webizen.ph/" target="_blank" rel="noopener noreferrer">Services</a>
                                </li>
                                <li onClick={() => handleActiveMenu('Contact-Us')}>
                                    <Link to='/Contact-Us' className={`scroll-to-section ${activeSection === 'Contact-Us' ? 'active' : ''}`} onClick={() => handleActiveMenu('Contact-Us')}>Contact Us</Link>
                                </li>
                                <li onClick={() => handleActiveMenu('Login')}>
                                    <Link to='/Login' className={`scroll-to-section ${activeSection === 'Login' ? 'active' : ''}`} onClick={() => handleActiveMenu('Login')}>Log in</Link>
                                </li>
                                <li onClick={openCart}>
                                    <a hrefLang="#cart">
                                        <Badge count={cart.length} overflowCount={99}>
                                            <FaCartShopping style={{ fontSize: '24px' }} />
                                        </Badge>
                                    </a>
                                </li>
                            </ul>
                            <a className='addToCart' onClick={openCart}>
                                <Badge count={cart.length} overflowCount={99}>
                                    <FaCartShopping style={{ fontSize: '24px' }} />
                                </Badge>
                            </a>
                            <a className={`menu-trigger ${isMenuOpen ? 'active' : ''}`} onClick={toggleMenu}>
                                <span>Menu</span>
                            </a>
                            {isMenuOpen && (
                                <ul className="menu-nav">
                                    <li onClick={() => handleActiveMenu('home')}>
                                        <Link to="/" className={`scroll-to-section ${activeSection === 'home' ? 'active' : ''}`}>Home</Link>
                                    </li>
                                    <li onClick={() => handleActiveMenu('About-us')}>
                                        <Link to="/About-us" className={`scroll-to-section ${activeSection === 'About-us' ? 'active' : ''}`}>About Us</Link>
                                    </li>
                                    <li onClick={() => handleActiveMenu('Products')}>
                                        <Link to="/Products" className={`scroll-to-section ${activeSection === 'Products' ? 'active' : ''}`}>Products</Link>
                                    </li>
                                    <li onClick={() => handleActiveMenu('Partner')}>
                                        <Link to="/License" className={`scroll-to-section ${activeSection === 'Partner' ? 'active' : ''}`}>Merchant</Link>
                                    </li>
                                    <li>
                                        <a href="https://webizen.ph/" target="_blank" rel="noopener noreferrer">Services</a>
                                    </li>
                                    <li onClick={() => handleActiveMenu('Contact-Us')}>
                                        <Link to='/Contact-Us' className={`scroll-to-section ${activeSection === 'Contact-Us' ? 'active' : ''}`}>Contact Us</Link>
                                    </li>
                                    <li onClick={() => handleActiveMenu('Login')}>
                                        <Link to='/Login' className={`scroll-to-section ${activeSection === 'Login' ? 'active' : ''}`}>Log in</Link>
                                    </li>
                                </ul>
                            )}
                            <Drawer 
                                title={<h4 style={{fontSize: '23px', margin: '0'}}>MY CART</h4>}
                                placement='right'
                                width={500}
                                onClose={closeCart}
                                open={cartContainer}
                                footer={<CartFooter closeCart={closeCart} />}
                            >
                                <CartContent closeCart={closeCart} />
                            </Drawer>
                        </nav>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header2;
